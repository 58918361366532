<template>
    <div class="container-fluid">
        <h4 style="background-color: rgb(155, 155, 155); color: white;">Etapas y Actividades de Presupuesto "{{ this.projectDescripcion }}"</h4>
        <div class="row">
            <!-- the grid -->
            <wj-flex-grid :itemsSource="gridData" :initialized="initializeFlexSheet" :formatItem="formatItem">
                <wj-flex-grid-filter />
                <wj-flex-grid-column binding="fr" header="Fr" :width="50" :isReadOnly="true" />
                <wj-flex-grid-column binding="frente" header="Frente" :width="150" :isReadOnly="true" :isRequired=true  style="text-align: left;"/>
                <wj-flex-grid-column binding="ni" header="N" :width="50" :isReadOnly="true" :isRequired=true  />
                <wj-flex-grid-column binding="dpgen" header="DPGen" :width="90" :isReadOnly="true" :isRequired=true style="text-align: left;"/>
                <wj-flex-grid-column binding="partida" header="Partida" :width="150" :isReadOnly="true" :isRequired=true style="text-align: left;"/>
                <wj-flex-grid-column binding="importe" header="Importe" :width="100" :isReadOnly="true" :isRequired=true />
                <wj-flex-grid-column binding="etapa" header="Etapa" :width="70" :isReadOnly="false" />
                <wj-flex-grid-column binding="secprog" header="SecProg" :width="70" :isReadOnly="false" />
                <wj-flex-grid-column binding="nombreprog" header="NombreProg" :width="240" :isReadOnly="false" style="text-align: left;"/>
                <wj-flex-grid-column binding="actividad" header="Actividad" :width="240" :isReadOnly="true" style="text-align: left;"/>
                <wj-flex-grid-column binding="concat1" header="Concat1" :width="240" :isReadOnly="true" :visible="false" style="text-align: left;"/>
            </wj-flex-grid>
        </div>
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="form-inline well well-lg">
                    <input
                        ref="importFile" 
                        type="file"
                        class="form-control"
                        style="width: 250px;"
                        id="importFile"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12"
                    >
                    <button @click="load()" class="btn btn-default">Importar</button>
                    <div class="checkbox">
                        <label>
                            <input v-model="includeColumnHeader" type="checkbox"> Include Column Header
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xs-12">
                <div class="form-inline well well-lg">
                    <button @click="save({})" class="btn btn-default">Exportar</button>
                    <div class="checkbox">
                        <label>
                            <input v-model="includeColumnHeader" type="checkbox"> Include Column Header
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input v-model="customContent" @click="customContentClick($event)" type="checkbox"> Custom Cell Content
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'bootstrap/dist/css/bootstrap.css';
    import '@grapecity/wijmo.styles/wijmo.css';
    import * as XLSX from 'xlsx';

    import Vue from 'vue';
    //import { getActividadesProyecto } from '../../components/presupuesto/data';
    import '@grapecity/wijmo.vue2.grid';
    //import * as wjcCore from '@grapecity/wijmo';
    import * as wjcGrid from '@grapecity/wijmo.grid';
    import * as wjcCore from '@grapecity/wijmo';
    import * as wjcXlsx from '@grapecity/wijmo.xlsx';
    import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
    import "@grapecity/wijmo.vue2.grid.filter";
    import "@grapecity/wijmo.vue2.grid.search";
    import * as gridPdf from '@grapecity/wijmo.grid.pdf';
    import { CollectionView } from '@grapecity/wijmo';
    import { FlexGrid } from '@grapecity/wijmo.grid';

    export default {
    data() {
      return {
            selectedProject: null,
            gridData: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            includeColumnHeader: true,
            customContent: false,
        
        };
    },

    computed: {
      projectId() {
        return this.$store.getters.getProjectId;
      },
      projectDescripcion() {
        return this.$store.getters.getProjectDescription;
      }
    },

    watch: {
        selectedProject(newValue, oldValue) {
          // Tu lógica aquí
        },
    },      
    async mounted() {
        try {
            //console.log("selectedProject: " + this.selectedProject);
            //fetch(`http://157.245.220.201:3002/api/actividades/?proyecto=${this.selectedProject}`)
            //.then(response => response.json())
            //.then(data => {
            //    this.data = data;
            //})
            //.catch(error => {
            //    console.error('Error:', error);
            //});
                        const customStyle = document.createElement('style');
            customStyle.innerHTML = `
                .wj-cell.wj-alt {
                height: auto !important;
                /* Otros estilos si es necesario */
                }
            `;
            document.head.appendChild(customStyle);
            try {
                //await this.fetchProjects(); // Llama a la función para obtener la lista de proyectos
                await this.fetchData();
            } catch (error) {
                console.error('Error al obtener los proyectos: ', error);
            }

            //let theSearch = Control.getControl(this.$refs.theSearch.$el);
            //theSearch.grid = theGrid;
        } catch (error) {
            console.error('Error al obtener los datos de la tabla: ', error);
        }   
        //console.log(this.data);                 
    },
    methods: {

        async created() {
            try {
                if (this.projectId) {
                    this.selectedProject = this.projectId
                }
            } catch (error) {
                console.error('Error al obtener los datos de la tabla: ', error);
            }                    
        },           
        initializeFlexSheet: function(flex) {
                this.flex = flex;

                this.flex.cellEditEnding.addHandler((s, e) => {
                    let col = s.columns[e.col];
                    if (col.binding == 'etapa' || col.binding == 'secprog' || col.binding == 'nombreprog' || col.binding == 'actividad') {
                        let item = s.rows[e.row].dataItem;
                        this.updateActividad(item);
                        console.log("actualizar el registro");
                    }
                });
                /*flex.cellEditEnding.addHandler((s, e) => {
                    this.editMsg = '&nbsp;';
                    let col = s.columns[e.col];
                    if (col.binding == 'secprog' || col.binding == 'etapa') {
                        let value = wjcCore.changeType(s.activeEditor.value, wjcCore.DataType.Number, col.format);
                        if (!wjcCore.isNumber(value) || value < 0) { // prevent negative sales/expenses
                        e.cancel = true;
                        this.editMsg = 'Por favor ingresa un número valido';
                        } else {
                        if (col.binding == 'secprog') {
                            s.rows[e.row].dataItem.actividad = value + ' ' +  s.rows[e.row].dataItem.nombreprog;              
                        }
                        }
                    } else if (col.binding == 'actividad' || col.binding == 'nombreprog') {
                        let value = wjcCore.changeType(s.activeEditor.value, wjcCore.DataType.String, col.format);
                        if (!wjcCore.isString(value) || value < 0) { // prevent negative sales/expenses
                        e.cancel = true;
                        this.editMsg = 'Por favor ingresa una descripción valida';
                        } else {
                        if (col.binding == 'nombreprog') {
                            s.rows[e.row].dataItem.actividad = s.rows[e.row].dataItem.secprog + ' ' + value;              
                        }
                        }
                    }

                    });*/
        },

        async fetchData() {
            try {
                this.selectedProject = this.$store.getters.getProjectId;
                if (!this.selectedProject) {
                    return;
                }

                /*const cacheKey = `projectDataCache_${this.selectedProject}`;
                if (apiCache[cacheKey]) {
                    this.initializePivotGrid(apiCache[cacheKey]);
                    console.log('Se cargo desde cache');
                } else {*/

                    try {
                        console.log("selectedProject: " + this.selectedProject);
                    //this.selectedProject = 18;
                    // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                    const response = await fetch(`http://157.245.220.201:3002/api/actividades/?proyecto=${this.selectedProject}`);
                    //const response = await fetch(`http://157.245.220.201:3002/api/actividades/?proyecto=1`);

                    this.tempgridData = await response.json();
                    this.gridData = this.tempgridData.body;
                    console.log(this.tempgridData);
                    console.log(this.gridData);
                    } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                    }
                //}
            } catch (error) {
            console.error('Error en loadProjectDataWithCache:', error);
            }        
        },

        filter: function(e) {
            let filter = e.target.value.toLowerCase();
            this.flex.collectionView.filter = item => {
                return (
                    filter.length == 0 ||
                    item.frente.toLowerCase().indexOf(filter) > -1
                );
            };
        },            
        
        load: function() {
            let fileInput = document.getElementById('importFile');
            console.log(fileInput);
            if (fileInput.files[0]) {
                this.customContent = false;

                // Create a FileReader to read the file
                let reader = new FileReader();

                reader.onload = (e) => {
                    // Once the file is loaded, this function is executed
                    let data = new Uint8Array(e.target.result);
                    let workbook = XLSX.read(data, {type: 'array'});

                    // Get the first worksheet
                    let worksheet = workbook.Sheets[workbook.SheetNames[0]];

                    // Convert the worksheet to JSON
                    let tempList = XLSX.utils.sheet_to_json(worksheet, {header: 1});

                    console.log("datos cargados");
                    console.log(tempList);

                    // Iterate over the items in tempList
                    for (let i = 0; i < tempList.length; i++) {
                        let item = tempList[i];
                        console.log(item[4]);
                        // Find the corresponding items in this.flex
                        let existingItems = this.flex.itemsSource.filter(x => x.partida === item[4]);
                        console.log(existingItems);
                        existingItems.forEach(existingItem => {                                
                            // If the item exists, update it etapa, secprog, nombreprog
                            if (isNaN(item[6])) {
                            } else {
                                existingItem.etapa = item[6];
                            }
                            //validar que secprog sea numérico	
                            if (isNaN(item[7])) {
                            } else {
                                existingItem.secprog = item[7];
                                existingItem.actividad = item[7] + ' ' +  existingItem.nombreprog;
                            }
                            existingItem.nombreprog = item[8];
                            existingItem.actividad = existingItem.secprog + ' ' + item[8];
                            //existingItem.concat11 = existingItem.actividad + ' ' + existingItem.ni + ' ' + existingItem.etapa;
                            //existingItem.concat2 = existingItem.etapa + ' ' + existingItem.actividad + ' ' + existingItem.ni + ' ' + existingItem.dpgen + ' ' + existingItem.partida + ' ' + existingItem.ut + ' ' + existingItem.dt;
                            console.log("actualizar el registro" + existingItem.actividad);

                            //enviar el registro actualizado a la api
                            this.updateActividad(existingItem);
                        });
                    }

                    // Refresh the FlexGrid to reflect the changes
                    this.flex.refresh();

                };

                // Start reading the file
                reader.readAsArrayBuffer(fileInput.files[0]);
            }
        },

        updateActividad: function(item) {
            console.log("actualizar el registro");
            //this.selectedProject = this.$store.getters.getProjectId;
            console.log("selectedProject: " + this.selectedProject);
            console.log(item);
            fetch(`http://157.245.220.201:3002/api/actividades/actualiza_actividadesbycontact/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ idcliente: 3, idproyecto: this.selectedProject, concat1: item.concat1, etapa: item.etapa, secprog: item.secprog, nombreprog: item.nombreprog, actividad: item.actividad })
            });
        },

        customContentClick: function(e) {
            this.flex.invalidate();
        },

        formatItem: function(grid, e) {
            let panel = e.panel,
                r = e.row,
                c = e.col,
                cell = e.cell;

            if (panel.cellType === wjcGrid.CellType.Cell) {
                let binding = grid.columns[c].binding,
                    row = grid.rows[r];

                if (row instanceof wjcGrid.GroupRow) {
                    if (row.level <= 2) {
                        if (binding === 'active') {
                            cell.innerHTML = this.customContent
                                ? 'Amount/Pending: ' + Math.round(grid.getCellData(r, 'amount', false)/ grid.getCellData(r, 'amount2', false) * 100)/ 100
                                : '';
                        }
                    }
                } else {
                    if (binding === 'color') {
                        cell.style.color = this.customContent ? grid.getCellData(r, c, true) : '';
                    //} else {
                        //       cell.style.backgroundColor = this.customContent ? 'orange' : '';
                    }
                }
            }
        },

        save() {
            wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
                this.flex,
                {
                    includeColumnHeaders: this.includeColumnHeader,
                    includeStyles: false,
                    formatItem: this.customContent
                        ? this.exportFormatItem
                        : null
                },
                'EtapasyActividades.xlsx'
            );
        },

        exportFormatItem: function(args) {
            var p = args.panel,
                row = args.row,
                col = args.col,
                xlsxCell = args.xlsxCell;

            if (p.cellType === wjcGrid.CellType.Cell) {
                if (p.columns[col].binding === 'color') {
                    if (xlsxCell.value) {
                        if (!xlsxCell.style.font) {
                            xlsxCell.style.font = {};
                        }
                        xlsxCell.style.font.color = xlsxCell.value.toLowerCase();
                    }
                } else if (p.columns[col].binding === 'active' && p.rows[row] instanceof wjcGrid.GroupRow) {
                    let cell = args.getFormattedCell();
                    xlsxCell.value = cell.textContent.trim();
                    xlsxCell.style.hAlign = wjcXlsx.HAlign.Left;
                }
            }
        }
    }
}
</script>

<style>
    .wj-flexgrid {
        height: 400px;
        margin: 6px 0;
        font-family: Arial, sans-serif; 
        font-size: 12px;
    }

    .checkbox label input[type=checkbox] {
        margin-right: 5px;
    }

    .checkbox label {
        margin-right: 10px;
    }

    .btn {
        margin-right: 5px;
    }
</style>