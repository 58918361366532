<template>
  <div style="background-color: rgb(180, 200, 230);">
    <h1 style="background-color: rgb(155, 155, 155); color: white;">Consulta CTR de {{ this.projectDescripcion }}
    </h1>
    <!-- Contenedor principal  style="width: 100%; height: 100vh;"-->
    <div class="container-fluid">
      <!-- Botón de colapso -->
      <div class="text-right mb-2"> <!-- Alinea el botón a la derecha y agrega espacio inferior -->
        <button @click="toggleCollapse">{{ collapsed ? 'Mostrar campos' : 'Ocultar campos' }}</button>
        <button @click="handleRefreshing(pivotGrid, null)">Contraer filas</button>
      </div>
      <div class="col-sm-3">
        <!-- Contenedor del PivotPanel (solo se renderiza si selectedProject tiene un valor) -->
        <div id="mi-panel" :class="{ 'collapsed': collapsed }" ref="pivotPanel" v-show="!collapsed" style="height: 72vh; overflow: auto;">
          <!-- Contenido del PivotPanel -->
        </div>
      </div>

      <div class="row">
        <!-- Contenedor del PivotGrid style="width: 100%; height: 100%;"-->
        <div ref="pivotGrid" style="width: 100%; height: 72vh; overflow: auto; font-family: Arial, sans-serif; font-size: 12px;" @click="handlePivotGridClick"></div>
      </div>
    </div>
  </div>
</template>

<script>

  import '@grapecity/wijmo.styles/wijmo.css';
  import 'bootstrap/dist/css/bootstrap.css';
  import Vue from 'vue';
  import '@grapecity/wijmo.touch'; // support drag/drop on touch devices
  import '@grapecity/wijmo.vue2.olap';
  import * as wjcOlap from '@grapecity/wijmo.olap';
  import { Tooltip } from '@grapecity/wijmo';
  // Definir la constante customTemplate aquí
  const apiCache = {};
  /*const customTemplate = `
  <div class="root">  
    <div class="field-list-label">  
      <label wj-part="g-flds">Elija los campos para agregar al informe:</label>  
    </div>  
    <div class="field-list pad">  
      <div wj-part="d-fields"></div>  
    </div>  
    <div class="drag-areas-label">  
      <label wj-part="g-drag"></label>  
    </div>  
    <div class="filter-list pad">  
      <label>  
        <span class="wj-glyph wj-glyph-filter"></span>   
        <span wj-part="g-flt"></span>  
      </label>  
      <div wj-part="d-filters"></div>  
    </div>  
    <div class="column-list pad bdr-left">  
      <label>  
        <span class="wj-glyph">⫴</span>   
        <span wj-part="g-cols"></span>  
      </label>  
      <div wj-part="d-cols"></div>  
    </div>  
    <div class="row-list pad bdr-top">  
      <label>  
        <span class="wj-glyph">≡</span>   
        <span wj-part="g-rows"></span>  
      </label>  
      <div wj-part="d-rows"></div>  
    </div>  
    <div class="values-list pad bdr-left bdr-top">  
      <label>  
        <span class="wj-glyph">Σ</span>   
        <span wj-part="g-vals"></span>  
      </label>  
      <div wj-part="d-vals"></div>  
    </div>  
    <div wj-part="d-prog" class="progress-bar"></div>  
    <div class="control-area">  
      <label>  
        <input wj-part="chk-defer" type="checkbox">   
        <span wj-part="g-defer">Detener actualizaciones</span>  
      </label>  
      <button wj-part="btn-update" class="wj-btn wj-state-disabled" type="button" disabled>
        Actualizar
      </button>  
    </div>  
  </div>`;*/
  const customTemplate = `<div class="root">  
<div class="field-list-label">  
  <label wj-part="g-flds">Elija los campos para agregar al informe:</label>  
</div>  
<div class="field-list pad">  
  <div wj-part="d-fields"></div>  
</div>  
<div class="drag-areas-label">  
  <label wj-part="g-drag"></label>  
</div>  
<div class="filter-list pad">  
  <label>  
    <span class="wj-glyph wj-glyph-filter"></span>   
    <span wj-part="g-flt"></span>  
  </label>  
  <div wj-part="d-filters"></div>  
</div>  
<div class="column-list pad bdr-left">  
  <label>  
    <span class="wj-glyph">⫴</span>   
    <span wj-part="g-cols"></span>  
  </label>  
  <div wj-part="d-cols"></div>  
</div>  
<div class="row-list pad bdr-top">  
  <label>  
    <span class="wj-glyph">≡</span>   
    <span wj-part="g-rows"></span>  
  </label>  
  <div wj-part="d-rows"></div>  
</div>  
<div class="values-list pad bdr-left bdr-top">  
  <label>  
    <span class="wj-glyph">Σ</span>   
    <span wj-part="g-vals"></span>  
  </label>  
  <div wj-part="d-vals"></div>  
</div>  
<div wj-part="d-prog" class="progress-bar"></div>  
<div class="control-area">  
  <label>  
    <input wj-part="chk-defer" type="checkbox">   
    <span wj-part="g-defer">Defer Updates</span>  
  </label>  
  <button wj-part="btn-update" class="wj-btn wj-state-disabled" type="button" disabled>
    Update  
  </button>  
</div>  
</div>`;

  export default {
    data() {
      return {
        selectedProject: null,
        projects: [],
        ngPanel: null,
        collapsed: true, // Inicialmente, el contenido está colapsado
        tt: null,
        rowsExpanded: true,
      };
    },
    async created() {
      if (this.projectId) {
        this.selectedProject = this.projectId
        //wjcOlap.PivotPanel.controlTemplate = customTemplate;
        //this.pivotPanel.controlTemplate = customTemplate;
        await this.loadProjectDataWithCache();
      }
      else {
        //wjcOlap.PivotPanel.controlTemplate = customTemplate;
        //this.pivotPanel.controlTemplate = customTemplate;
        await this.fetchProjectsWithCache();        
      }

      // Configurar el evento formatItem después de cargar los datos
      console.log("9. pasa por aqui...");
      if (this.pivotGrid) {
      console.log("9.1. pasa por aqui...");
      this.pivotGrid.formatItem.addHandler((s, e) => {
        if (e.panel == s.columnHeaders) {
          const columnName = s.columns[e.col].binding; // Obtener el nombre de la columna
          console.log("9.2. pasa por aqui...", columnName);
          console.log("9.3. pasa por aqui... row: ", s.columns[e.col]);
          /*if (columnName === 'Frente:0;') {
            //
          } else if (columnName === 'CantUnitIns:0;' || columnName === 'N:0;') {
            console.log("9.2.1. pasa por aqui... valor: ", columnName);
            //e.cell.style.backgroundColor = 'red'; // Color naranja tenue
            //e.cell.style.color = 'black'; // Color de texto negro
          }*/
        }

        // we are interested in the cells panel
        if (e.panel == s.cells) {
          console.log("10.2.1. pasa por aqui... valor: ", e.panel);
          // remove color by default
          //let color = '';
          // format diff columns if custom formatting is on
          if (e.row % 2 == 1) {
            let value = s.getCellData(e.row, e.col, false);
            console.log("10.2.1. pasa por aqui... valor: ", value);
            //if (value != 'Subtotal'){
              //e.cell.style.backgroundColor = 'graylight';
            } else {
              //e.cell.style.backgroundColor = 'rgb(238, 238, 238)';
          //  }
          }
        }

        //e.cell.style.backgroundColor = 'white';
        
        console.log("9.2.2.1. pasa por aqui... valor: ", s.cells);
        console.log("9.2.2.2. pasa por aqui... valor: ", e.panel.cellType);

        if (e.col == 0 || e.col == 1 || e.col == 2 || e.col == 3 || e.col == 4 ) {
          //const columnName2 = s.columns[e.col].binding; // Obtener el nombre de la columna
          /*if (columnName2 !== 'CantUnitIns:0;' && columnName2 !== 'PI:0;' && columnName2 !== 'P.U.T.:0;' && columnName2 !== 'P.U.P.:0;') {
            //e.cell.style.backgroundColor = 'orange';
            console.log("9.2.2.4. pasa por aqui... valor: ", e.col);
          }*/

        } else {
          //e.cell.style.backgroundColor = 'brown';
        }
        let cell2 = e.panel.getCellData(e.row, e.col, true);
        if (cell2 == 'Subtotal') {
          const groupCellValue = e.panel.getCellData(e.row, e.col - 1, true);
          e.cell.innerHTML = 'Subtotal' + ' ' + groupCellValue;
        }
      });

      //Dentro del evento cellRendered, identifica la celda actual usando la propiedad cell del argumento del evento.
      
      //Extrae el valor de la celda con cell.data y la información de la jerarquía con cell.row.dataItem.

      //Aplica lógica condicional para personalizar el contenido y estilo de la celda en base al valor, la jerarquía o cualquier otro criterio deseado. Puedes utilizar las propiedades cell.textContent, cell.style, cell.className para modificar el texto, estilos y clases CSS de la celda.

      } else {
        console.error("this.ngPanel is undefined");
      }
      // Agregar el manejador para el evento de actualización
      console.log("10. pasa por aqui...");
      this.pivotGrid.updatingLayout.addHandler(this.handleRefreshing);

      console.log("12. pasa por aqui...");
      this.pivotGrid.rendered.addHandler(this.rendered);

      // 
      console.log("11. pasa por aqui...");

      //this.pivotGrid.format-Item.addHandler(this.formatItem);
      //this.pivotGrid.customAggregate.addHandler(this.customAggregateHandler);
      //this.pivotGrid.cellRendered.addHandler(this.customCellRendered);
      this.pivotGrid.cellClicked.addHandler(this.onCellClick);

      /*this.pivotGrid.updatedView.addHandler((s, e) => {
        s.formatItem.addHandler((s, e) => {
          if (e.panel == s.cells) {
            if (e.row % 2 == 0) {
              e.cell.style.backgroundColor = 'graylight'; // Color for even rows
            } else {
              e.cell.style.backgroundColor = 'rgb(238, 238, 238)'; // Color for odd rows
            }
          }
        });
      });*/
    },
    computed: {
      projectId() {
        return this.$store.getters.getProjectId;
      },
      projectDescripcion() {
        return this.$store.getters.getProjectDescription;
      }
    },
    watch: {
      selectedProject(newValue, oldValue) {
        // Tu lógica aquí
      },
    },
    methods: {
      /*toggleRows() {
        const pivotGridInstance = this.$refs.pivotGrid.instance;
        if (this.rowsExpanded) {
          //this.pivotGrid.collapseRowGroup(0); // collapse all row groups
          this.pivotGrid.collapseAll(0); // collapse all row groups
        } else {
          //this.pivotGrid.expandRowGroup(0); // expand all row groups
          this.pivotGrid.expandAll(0); // expand all row groups
        }
        this.rowsExpanded = !this.rowsExpanded;
        this.pivotGrid.refresh();
      },*/

      toggleCollapse() {
        this.collapsed = !this.collapsed; // Cambiar el estado de colapso
      },

      async fetchProjectsWithCache() {
        try {        
          const cacheKey = 'projectsCache';
          if (apiCache[cacheKey]) {
            this.projects = apiCache[cacheKey];
            console.log('Se cargo desde cache');
          } else {
            try {
              const response = await fetch('http://157.245.220.201:3002/api/proyectos');
              console.log("2. fetchProjects: pasa por aquí");
              if (response.ok) {
                const data = await response.json();
                this.projects = data.body;
                apiCache[cacheKey] = data.body; // Almacenar en caché
                console.log('Se cargo desde api.');
              } else {
                console.log('No se pudo cargar la lista de proyectos');
              }
            } catch (error) {
              console.error('Error al cargar la lista de proyectos: ', error);
            }
          }
        } catch (error) {
          console.error('Error al obtener el proyecto:', error);
        }
      },

      async loadProjectDataWithCache() {
        try {
          if (!this.selectedProject) {
            return;
          }

          const cacheKey = `projectDataCache_${this.selectedProject}`;
          if (apiCache[cacheKey]) {
            this.initializePivotGrid(apiCache[cacheKey]);
            console.log('Se cargo desde cache');
          } else {
            try {
              const response = await fetch(`http://157.245.220.201:3002/api/presupuestos/getctr?cliente=3&proyecto=${this.selectedProject}`);
              console.log("3. loadProjectData: pasa por aquí");
              if (response.ok) {
                console.log("3.0. loadProjectData: pasa por aquí");
                const projectData = await response.json();
                console.log("3.1. loadProjectData: pasa por aquí");
                this.initializePivotGrid(projectData.body);
                apiCache[cacheKey] = projectData.body; // Almacenar en caché
                console.log('Se cargo desde api.');
              } else {
                console.log('No se pudo cargar el proyecto. Estado: ', response.status);
              }
            } catch (error) {
              console.error('Error al cargar el proyecto: ', error);
            }
          }
        } catch (error) {
          console.error('Error en loadProjectDataWithCache:', error);
        }        
      },

      initializePivotGrid(data) {
        // Crear un PivotEngine con los datos obtenidos de la API
        const ngPanel = new wjcOlap.PivotEngine({
          itemsSource: data,
          autoGenerateFields: false,
          fields: [
            { binding: 'presupuesto', header: 'Presupuesto', width: 70 },
            { binding: 'fr', header: 'Fr', width: 60 },
            { binding: 'frente', header: 'Frente', width: 100 },
            { binding: 'ni', header: 'N', width: 55 },
            { binding: 'dpgen', header: 'DPGen', width: 80 },
            { binding: 'partida', header: 'Partida', width: 120 },
            { binding: 'tarjeta', header: 'Tarjeta', width: 120 },
            { binding: 'fi', header: 'FI', width: 120 },
            { binding: 'di', header: 'Insumo', width: 120 },
            { binding: 'ci', header: 'CI', format: 'n3' },
            { binding: 'pi', header: 'PI', format: 'c2', aggregate: 'Avg' },
            { binding: 'put', header: 'P.U.T.', format: 'c2' },
            { binding: 'pup', header: 'P.U.P.', format: 'c2' },
            { binding: 'importe', header: 'Importe', format: 'c2' },
            { binding: 'cictr', header: 'CI CTR', format: 'c2' },
            { binding: 'ictr', header: '$I CTR', format: 'c2' },
          ],
          filterFields: ['Presupuesto'],
          valueFields: ['CI', 'Importe', 'CI CTR', '$I CTR'],
          rowFields: ['FI', 'Insumo'],
          showRowTotals: 'Subtotals',
          showColumnTotals: 'Subtotals', 
          totalsBeforeData: true,
        });

        console.log("4. initializePivotGrid: pasa por aquí");
        // Configurar la propiedad collapsedHeaders para que las columnas se carguen colapsadas por defecto

        // Obtener el contenedor del PivotGrid por su ref
        console.log("4.1. initializePivotGrid: pasa por aquí");

        // Obtener el contenedor del PivotGrid por su ref
        console.log("4.2. initializePivotGrid: pasa por aquí");

        // Asegurarse de que el contenedor exista antes de inicializar el PivotGrid
        console.log("4.3. initializePivotGrid: pasa por aquí");
        if (this.$refs.pivotGrid) {
          // Limpiar cualquier contenido anterior en el contenedor
          this.$refs.pivotGrid.innerHTML = '';

          // Crear y mostrar el PivotGrid en el contenedor
          console.log("4.3.1. initializePivotGrid: pasa por aquí");
          this.pivotGrid = new wjcOlap.PivotGrid(this.$refs.pivotGrid, {
            itemsSource: ngPanel.pivotView,
            autoScroll: true,// Puedes usar 'Vertical' o 'Both' según tus necesidades
            errorTip: true,
            showErrors: true,            
            //customStyle: true,    
          });        
        }

        // Crear un PivotPanel y conectarlo al mismo PivotEngine
        console.log("4.4. initializePivotGrid: pasa por aquí");
        new wjcOlap.PivotPanel(this.$refs.pivotPanel, {
          engine: ngPanel,          
        });

        console.log("4.5. initializePivotGrid: pasa por aquí");
        this.ngPanel = ngPanel;
        this.pivotPanel.controlTemplate = customTemplate;
        //this.ngPanel.controlTemplate = customTemplate;
        this.pivotGrid.scrollPositionX = 2; // Ajusta la posición horizontal del scroll hacia la derecha
        //this.pivotGrid.headersFootersVisibility = wjcOlap.HeadersVisibility.All;
        //this.pivotGrid.engine.totalsBeforeData = true;
        // Después de inicializar el PivotGrid
        this.pivotGrid.resizingColumn.addHandler(() => {
          this.pivotGrid.autoSizeColumns();
        });

        // Espera a que el PivotGrid se haya renderizado completamente
        this.$nextTick(() => {
          if (this.pivotGrid) {
            // Colapsa los renglones al nivel deseado (por ejemplo, nivel 0)
            console.log("4.6. initializePivotGrid: pasa por aquí");
          }
        });
        
      },

      customAggregateHandler({ field, items, data, type }) {
        if (type === 'subtotal') {
          const descripcionGrupo = items[0].data.frente; // Suponiendo que tienes el campo 'descripcionGrupo' en tus datos
          const subtotalRow = {
            [field]: `Subtotal - ${descripcionGrupo}`,
            // Agrega otros campos según sea necesario
          };
          return subtotalRow;
        }
        // Devuelve el valor predeterminado si no es una fila de subtotal
        return null;
      },

      handleRefreshing: function (sender, args) {
        if (sender.engine) {
            // Collapse rows to level 1.
            sender.collapseRowsToLevel(1);
            console.log('pasa a handleRefreshing');
            // Remove the handler to avoid recursion.
            sender.updatingLayout.removeHandler(this.handleRefreshing);
        }
      },

      //Utiliza el evento cellRendered del panelGrid para acceder a cada celda y personalizar su contenido y estilo.
      cellRendered: function (sender, args) {
        console.log('pasa a cellRendered');
      },

      onCellClick(e) {
        const columnIndex = e.cell.columnIndex;
        console.log('diste un clic');
        this.$nextTick(() => {
          const tableElement = e.component._$element.find('.dx-datagrid-rowsview table').first();
          const columnCells = tableElement.find(`td:nth-child(${columnIndex + 1})`);
          columnCells.css('background-color', 'blue');
          
        });
      },      

      rendered: function (s, e) {
        // Verifica si la celda pertenece a la sección de celdas
        if (e.panel == s.cells) {
          console.log("10.2.1. pasa por aqui... valor: ", e.panel);

          // Remueve el color por defecto
          let color = '';

          // Formatea celdas diferentes si el formato personalizado está activado
          if (e.row % 2 == 1) {
            let value = s.getCellData(e.row, e.col, false);
            console.log("10.2.1. pasa por aqui... valor: ", value);

            if (value != 'Subtotal') {
              e.cell.style.backgroundColor = 'graylight';
            } else {
              e.cell.style.backgroundColor = 'rgb(238, 238, 238)';
            }
          }
        }
      },

      toggleTooltip() {
        const element = this.$refs.tooltipElement;

        if (this.tt.isVisible) {
          this.tt.hide();
        } else {
          this.tt.show(element, '¡Este es un elemento importante!');
        }
      },

      handlePivotGridClick(event) {
        console.log("PivotGrid clicked event!", event);
        let cell2 = event.target.innerText;
        console.log("PivotGrid clicked cell!", cell2);
        //const columnIndex = event.cell.columnIndex;
        //console.log('diste un clic' + columnIndex) ;
        //if (columnIndex === 2) {
          //event.cell.style.backgroundColor = 'lightblue';
        //}
        //let cell = event.target.closest("td");
        //let value2 = cell2.textContent;
        // Aquí puedes manejar el evento onclick del pivotgrid                
        //console.log("PivotGrid clicked valor!" + value2);
          
        // Aquí puedes manejar el evento onclick dentro del PivotGrid
        console.log('Clic en el PivotGrid:', this.pivotGrid);

        // Ejemplo: Obtener información sobre la celda clicada
        const row = this.pivotGrid.hitTest(event).row;
        const col = this.pivotGrid.hitTest(event).col;
        //const value = this.pivotGrid.getCellData(row, col);
        if (cell2 == 'Subtotal') {
          console.log("PivotGrid clicked subtotal!");
          //event.cell.style.backgroundColor = 'blue'; // Change the color to blue
        }

        //console.log('Información de la celda clic:', this.pivotGrid.hitTest(event));
        console.log('Información de la celda clicada:', { row, col, cell2 });
        
        // Verifica si la celda pertenece a la sección de filas
        /*if (this.pivotGrid.hitTest(event).panel == this.pivotGrid.rowHeaders) {
          const hitTestResult = this.pivotGrid.hitTest(event);
          if (hitTestResult && typeof this.pivotGrid.hitTest(event).row === 'number') {
            const row = this.pivotGrid.hitTest(event).row;
            const rowData = this.pivotGrid.getDetail(row);

            const cellValue = rowData.values[hitTestResult.column];

            console.log('Clic en una columna de la sección de filas:', { row, cellValue });
          }
        }*/

        // we are interested in the cells panel
        console.log("clic pasa por aqui... valor: ", event.panel);
        // remove color by default
        let color = '';
        // format diff columns if custom formatting is on
        if (event.row % 2 == 1) {
          let value = s.getCellData(event.row, event.col, false);
          console.log("clic pasa por aqui... valor: ", value);
            event.cell.style.backgroundColor = 'grayligth';
        } else {
          event.cell.style.backgroundColor = 'rgb(238, 238, 238)';
        }

        //event.cell.style.color = 'blue'; // Change the color to red
        //if (event.panel.cellType === wijmo.grid.CellType.Cell && s.columns[event.col].binding === 'frente') {
         // console.log("clic pasa por aqui... valor: ", value);
          //event.cell.style.backgroundColor = 'red'; // Change the color to red
        //}

        let ht = this.pivotGrid.hitTest(event);
        if (ht.panel == this.pivotGrid.cells) {
          let cell3 = ht.panel.getCellData(ht.row, ht.col, true);
          if (cell3 == 'Subtotal') {
            const groupCellValue = ht.panel.getCellData(ht.row, ht.col - 1, true);
            ht.cell.innerHTML = 'Subtotal' + ' ' + groupCellValue;
            ht.cell.style.backgroundColor = 'blue'; // Change the background color of the cell
          }
        }   
        
        let visibleRows = this.pivotGrid.cells.rows.filter(row => row.isVisible);
        console.log('filas visibles' + visibleRows); // Print the visible rows in the console
        visibleRows.forEach((row, rowIndex) => {
          let rowData = [];
          for (let colIndex = 0; colIndex < this.pivotGrid.cells.columns.length; colIndex++) {
            let cellData = this.pivotGrid.cells.getCellData(rowIndex, colIndex, true);
            rowData.push(cellData);
            if (e.panel == s.cells) {
              if (row % 2 == 0) {
                e.cell.style.backgroundColor = 'graylight'; // Color for even rows
              } else {
                e.cell.style.backgroundColor = 'rgb(238, 238, 238)'; // Color for odd rows
              }
            }
          }
          console.log('Row ' + rowIndex + ': ' + rowData.join(', '));
        });
      },
      
    },

    mounted() {
      // Accede a la instancia de PivotGrid después de que ha sido montada
      //const pivotGridInstance = this.$refs.pivotGrid;

      // Verifica que la instancia exista
      console.log("22. pasa por aqui...");
      // Inicializar el Tooltip al montar el componente
      this.tt = new Tooltip();
      //if (this.pivotGrid) {
        // Agrega el manejador customAggregateHandler al evento customAggregate
        //pivotGridInstance.$on('customAggregate', this.customAggregateHandler);
      //}
      this.$nextTick(() => {
        //let pivotGrid = this.$refs.pivotGrid;
        console.log("24. pasa por aqui...");
        /*if (pivotGrid) {
          pivotGrid.updatedView.addHandler((s, e) => {
            s.formatItem.addHandler((s, e) => {
              if (e.panel == s.cells && e.cell) { // Check if e.cell is available
                if (e.cell) { // Check if e.cell is not undefined
                  if (e.row % 2 == 0) {
                    e.cell.style.backgroundColor = '#f2f2f2'; // Color for even rows
                  } else {
                    e.cell.style.backgroundColor = 'white'; // Color for odd rows
                  }
                }
              }
            });
          });
        } else {
          console.log("PivotGrid instance is not available...");
        } */       
        
        /*
        // Access the PivotGrid instance after it has been mounted
        let pivotGrid = this.pivotGrid; 

        // Check if the instance exists
        if (pivotGrid) {
          console.log("PivotGrid instance is available...");

          let rows = pivotGrid.rows;
          console.log(rows); // Print the rows in the console

          let rowData = rows.map(row => row.dataItem);
          console.log(rowData); // Print the row data in the console
        } else {
          console.log("PivotGrid instance is not available...");
        }*/
      });

      /*this.pivotGrid.updatedView.addHandler((s, e) => {
        s.formatItem.addHandler((s, e) => {
          if (e.panel == this.pivotGrid.cells) {
            if (e.row % 2 == 0) {
              e.cell.style.backgroundColor = 'gray'; // Color for even rows
            } else {
              e.cell.style.backgroundColor = 'white'; // Color for odd rows
            }
          }
        });
      })*/;
    },
  };
</script>

<style scoped>
#pivotGrid .wj-flexgrid .wj-row:nth-child(odd) {
    background-color: #963c3c !important;
}
</style>