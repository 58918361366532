<template>
  <div class="login-window">
    <div class="login-card">
      <h2>Iniciar sesión</h2>
      <form @submit.prevent="login">
        <div class="input-group">
          <label for="username">Nombre de usuario:</label>
          <input type="text" id="username" v-model="username" required />
        </div>
        <div class="input-group">
          <label for="password">Contraseña:</label>
          <input type="password" id="password" v-model="password" required />
        </div>
        <button type="submit">Iniciar sesión</button>
      </form>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async login() {
      try {
        //http://157.245.220.201:3002
        const response = await fetch('http://157.245.220.201:3002/api/usuarios/login/', {
  //const response = await fetch('http://157.245.220.201:3002/api/usuarios/login/', {          
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            correo: this.username,
            contrasenia: this.password,
          }),
        });
        console.log(response);

        if (response.ok) {
          // Guardar el nombre de usuario en el almacenamiento local
          sessionStorage.setItem('username', this.username);          
          //console.log('Se inició sesión con', this.username, this.password);
          // Redirigir a la página principal después de iniciar sesión
          //this.$router.push('/Home');
          this.$router.push('/proyecto');
          // El inicio de sesión fue exitoso, puedes manejarlo aquí
          this.$emit('loggedIn'); // Emitir evento de inicio de sesión exitoso
        } else {
          // El inicio de sesión falló, puedes manejarlo aquí
          console.log('No se pudo iniciar sesión con', this.username);
        }
      } catch (error) {
        // Manejar errores de red u otros errores aquí
        console.error('Error de inicio de sesión:', error);
      }
    },
  },
};
</script>
  
<style scoped>
.login-window {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgb(180, 200, 230);
}

.login-card {
  background-color: rgb(155, 155, 155);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.input-group {
  margin-bottom: 16px;
}

label {
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
  