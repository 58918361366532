<template>
    <div class="container-fluid">
        <h4 style="background-color: rgb(155, 155, 155); color: white;">Detalle de Actividades de "{{ this.projectDescripcion }}"</h4>
        <div class="row"></div>
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="form-inline well well-lg">
                    <button @click="save({})" >Exportar</button>
                </div>
            </div>
        </div>        
        <div class="form-group">
            <!-- Botón Guardar -->
            <button @click="guardarregistro">Guardar registro</button>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>Total: </label>
                <span>{{ subtotal }}</span>
            </div>
        </div>
        <div class="row">
            <!-- the grid -->
            <wj-flex-grid :itemsSource="gridDataEstBase" :initialized="initializeFlexSheet"  :beginningEdit="beginningEdit" :cellEditEnded="cellEditEnded" :frozenColumns=21 :selectionMode="'RowRange'" :allowAddNew="true" :allowDelete="false">                
                <wj-flex-grid-filter class="custom-filter" :filterApplied="filterApplied" />
                <wj-flex-grid-column binding="actividad" header="Actividad" :width="100" :isReadOnly="false" :visible="true" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="fecha" header="Fecha" :width="100" :isReadOnly="false" :visible="true" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="importe" header="Importe" format="c0" :width="100" :isReadOnly="false" :isRequired=true :headerStyles="{ height: '50px', backgroundColor: 'blue' }" />
                <wj-flex-grid-column binding="descripcion" header="Descripción" :width="800" :visible="true" :isReadOnly="false" :isRequired=true  cssClass="left-align" :headerStyles="{ height: '50px' }" />
            </wj-flex-grid>
        </div>
    </div>
</template>

<script>
 
    import 'bootstrap/dist/css/bootstrap.css';
    import '@grapecity/wijmo.styles/wijmo.css';
    import * as XLSX from 'xlsx';

    import Vue from 'vue';
    //import { getActividadesProyecto } from '../../components/presupuesto/data';
    import '@grapecity/wijmo.vue2.grid';
    //import * as wjcCore from '@grapecity/wijmo';
    import * as wjcGrid from '@grapecity/wijmo.grid';
    import * as wjcCore from '@grapecity/wijmo';
    import * as wjcXlsx from '@grapecity/wijmo.xlsx';
    import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
    import "@grapecity/wijmo.vue2.grid.filter";
    import "@grapecity/wijmo.vue2.grid.search";
    import * as gridPdf from '@grapecity/wijmo.grid.pdf';
    import { CollectionView } from '@grapecity/wijmo';
    import { FlexGrid } from '@grapecity/wijmo.grid';
    import '@grapecity/wijmo.vue2.input';

    export default {
    data() {
      return {
            selectedProject: null,
            tempgridDataBase: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            gridDataEstBase: [],//null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            gridDataEst: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            gridDataEstDet: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            estimaciones: [],
            checkedEstimaciones: [], // inicialmente no hay items seleccionados
            uncheckedEstimaciones: [], // inicialmente no hay items no seleccionados
            etapas: [],
            checkedEtapas: [], // inicialmente no hay items seleccionados
            uncheckedEtapas: [], // inicialmente no hay items no seleccionados            
            includeColumnHeader: true,
            customContent: false,
            columns: [],
            countEstimaciones: 0,
            customColumnFooter: true,
            showInput: false,
            selectedDate: null,
            total: null,
            subtotal: null,
            showFilterInput: true,
            showSelectAllCheckbox: true,
            filterEtapa: '',
            filterDPGen: '',
            newRowAtTop: false,
            currentRowChanges: {},
            //ch: null,
        };
    },

    computed: {
      projectId() {
        return this.$store.getters.getProjectId;
      },
      projectDescripcion() {
        return this.$store.getters.getProjectDescription;
      },
      allOptions() {
        return ['Seleccionar todas', 'Deseleccionar todas', ...this.estimaciones];
      },
      getUnselectedItems() {        
        return this.estimaciones.filter(item => !this.checkedEstimaciones.includes(item));
      },      
      getUnselectedEtapas() {        
        return this.etapas.filter(item => !this.checkedEtapas.includes(item));
      },
      formattedTotal() {
      // Asumiendo que `total` es un número, lo formateamos como moneda.
      // Puedes ajustar la localidad ('es-MX' para México) y las opciones según necesites.
      return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.total);
      }            
    },

    watch: {
        selectedProject(newValue, oldValue) {
          // Tu lógica aquí
        },
    },      
        async mounted() {
            try {
                const customStyle = document.createElement('style');
                customStyle.innerHTML = `
                    .wj-cell.wj-alt {
                    height: auto !important;
                    /* Otros estilos si es necesario */
                    }
                `;
                document.head.appendChild(customStyle);
                try {
                    //this.initializeFlexSheet();
                    //await this.fetchProjects(); // Llama a la función para obtener la lista de proyectos
                    await this.fetchDataEstBase();
                } catch (error) {
                    console.error('Error al obtener los proyectos: ', error);
                }

                //let theSearch = Control.getControl(this.$refs.theSearch.$el);
                //theSearch.grid = theGrid;
            } catch (error) {
                console.error('Error al obtener los datos de la tabla: ', error);
            }   
            //console.log(this.data);                 
        },

        methods: {

            async created() {
                try {
                    if (this.projectId) {
                        this.selectedProject = this.projectId
                    }
                    /*else {
                        await this.fetchProjectsWithCache();        
                    }*/
                } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                }                    
            },   
                    
            initializeFlexSheet: function(flex) {
                this.flex = flex;

                this.flex.cellEditEnding.addHandler((s, e) => {
                    let col = s.columns[e.col];
                });

                this.flex.rowHeaders.columns.splice(0, 1); // no extra columns
            },

            async fetchDataEstBase() {
              try {
                    this.selectedProject = this.$store.getters.getProjectId;
                    if (!this.selectedProject) {
                        return;
                    }

                    try {
                        //this.selectedProject = 17;
                        // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                        const idcliente = 3; // Establece el ID del cliente
                        console.log("selectedProject: " + this.selectedProject);
                        const idproyecto = this.selectedProject; // Utiliza el proyecto seleccionado
                        const url = `http://157.245.220.201:3002/api/estimacionesdetdet/getall/?cliente=${idcliente}&proyecto=${idproyecto}`;

                        const response = await fetch(url, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });

                        const responseData = await response.json();
                        this.tempgridDataBase = responseData.body;
                        
                        console.log(this.tempgridDataBase);
                        //console.log(this.gridDataEstBase);

                        this.gridDataEstBase = this.tempgridDataBase;
                        console.log(this.gridDataEstBase);
                                                
                    } catch (error) {
                        console.error('Error al obtener los datos de la tabla: ', error);
                    }
                } catch (error) {
                    console.error('Error en fetchDataEstBase:', error);
                }        
            },

            filter: function(e) {
                let filter = e.target.value.toLowerCase();
                this.flex.collectionView.filter = item => {
                    return (
                        filter.length == 0 ||
                        item.frente.toLowerCase().indexOf(filter) > -1
                    );
                };
            },            
            
            load: function() {
                let fileInput = document.getElementById('importFile');
                console.log(fileInput);
                if (fileInput.files[0]) {
                    this.customContent = false;

                    // Create a FileReader to read the file
                    let reader = new FileReader();

                    reader.onload = (e) => {
                        // Once the file is loaded, this function is executed
                        let data = new Uint8Array(e.target.result);
                        let workbook = XLSX.read(data, {type: 'array'});

                        // Get the first worksheet
                        let worksheet = workbook.Sheets[workbook.SheetNames[0]];

                        // Convert the worksheet to JSON
                        let tempList = XLSX.utils.sheet_to_json(worksheet, {header: 1});

                        console.log("datos cargados");
                        console.log(tempList);

                        // Iterate over the items in tempList
                        for (let i = 0; i < tempList.length; i++) {
                            let item = tempList[i];
                            console.log(item[4]);
                            // Find the corresponding items in this.flex
                            let existingItems = this.flex.itemsSource.filter(x => x.partida === item[4]);
                            console.log(existingItems);
                            existingItems.forEach(existingItem => {                                
                                // If the item exists, update it etapa, secprog, nombreprog
                                if (isNaN(item[6])) {
                                } else {
                                    existingItem.etapa = item[6];
                                }
                                //validar que secprog sea numérico	
                                if (isNaN(item[7])) {
                                } else {
                                    existingItem.secprog = item[7];
                                    existingItem.actividad = item[7] + ' ' +  existingItem.nombreprog;
                                }
                                existingItem.nombreprog = item[8];
                                existingItem.actividad = existingItem.secprog + ' ' + item[8];
                                console.log("actualizar el registro" + existingItem.actividad);

                                //enviar el registro actualizado a la api
                                this.updateActividad(existingItem);
                            });
                        }

                        // Refresh the FlexGrid to reflect the changes
                        this.flex.refresh();

                    };

                    // Start reading the file
                    reader.readAsArrayBuffer(fileInput.files[0]);
                }
            },

            customContentClick: function(e) {
                this.flex.invalidate();
            },

            formatItem: function(grid, e) {
                let panel = e.panel,
                    r = e.row,
                    c = e.col,
                    cell = e.cell;

                    console.log("1.1. formatItem, entra if");
                if (e.panel == this.flex.cells) {
                    let binding = this.flex.columns[c].binding;
                }
                if (e.panel == this.flex.columnHeaders) {
                    console.log("1.2. formatItem, entra if");
                    let binding = this.flex.columns[c].binding;
                    // Obtener el encabezado de la columna
                    let header = this.flex.columns[c].header;
                    console.log(header);
                    if (binding === 'importe') {
                        //cambiar el color de fondo de la celda
                        e.cell.style.backgroundColor = "#f8de7e";
                    }
                }

                if (panel.cellType === wjcGrid.CellType.Cell) {
                    let binding = this.flex.columns[c].binding,
                        row = this.flex.rows[r];

                    if (row instanceof wjcGrid.GroupRow) {
                        if (row.level <= 2) {
                            if (binding === 'active') {
                                cell.innerHTML = this.customContent
                                    ? 'Amount/Pending: ' + Math.round(grid.getCellData(r, 'amount', false)/ grid.getCellData(r, 'amount2', false) * 100)/ 100
                                    : '';
                            }
                        }
                    } else {
                        if (binding === 'color') {
                            cell.style.color = this.customContent ? grid.getCellData(r, c, true) : '';
                        }
                    }

                }


            },

            save() {
                wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
                    this.flex,
                    {
                        includeColumnHeaders: this.includeColumnHeader,
                        includeStyles: false,
                        formatItem: this.customContent
                            ? this.exportFormatItem
                            : null
                    },
                    'DetalleEstimacionesporActividad.xlsx'
                );
            },

            exportFormatItem: function(args) {
                var p = args.panel,
                    row = args.row,
                    col = args.col,
                    xlsxCell = args.xlsxCell;

                if (p.cellType === wjcGrid.CellType.Cell) {
                    if (p.columns[col].binding === 'color') {
                        if (xlsxCell.value) {
                            if (!xlsxCell.style.font) {
                                xlsxCell.style.font = {};
                            }
                            xlsxCell.style.font.color = xlsxCell.value.toLowerCase();
                        }
                    } else if (p.columns[col].binding === 'active' && p.rows[row] instanceof wjcGrid.GroupRow) {
                        let cell = args.getFormattedCell();
                        xlsxCell.value = cell.textContent.trim();
                        xlsxCell.style.hAlign = wjcXlsx.HAlign.Left;
                    }
                }
            },


            agregaTotalesSubtotalesFijos() {
                try {

                            //obtener el indice de la variable col
                            let indicecol = this.flex.columns.findIndex(column => column.binding === 'put');
                            console.log(indicecol);
                            let hdrSubt = `Subtotal`;
                            console.log(hdrSubt);
                            this.flex.columnHeaders.setCellData(1, indicecol, hdrSubt);
                            let hdrTot = `Total`;
                            console.log(hdrTot);
                            this.flex.columnHeaders.setCellData(0, indicecol, hdrTot);
                                                   
                            this.calculateColumnTotal('importe');
                    
                } catch (error) {
                    console.error('Error al generar totales y subtotales iniciales: ', error);
                }
            },

            filterApplied(s, e) {
                console.log('Filtro aplicado');
                //this.agregaTotalesSubtotalesFijos();
                //agregar valor cero para todas las filas actuales de flexgrid en las columnas agregadas                
                    this.flex.columns.forEach(column => {
                        if (column.binding === "importe") {
                            console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                            this.calculateColumnTotal(column.binding);
                        }
                    });
            },

            beginningEdit(s, e) {
                // Obtiene la columna y fila actual.
                const column = s.columns[e.col].binding;
                const row = s.rows[e.row].dataItem;

                console.log("1. beginningEdit, entra if: " + column);
                
                if (column.startsWith('subc-e') && parseFloat(row['msubc']) <= 0) {
                    // Verifica si la columna comienza con 'subce-e' y si 'msubc' es un decimal mayor a cero.
                    // Cancela la edición si la validación falla.Number(row.dataItem[column.binding])
                    //console.log("entro a if: "+ column + " " + parseFloat(row['msubc']) + " " + parseFloat(row['mmahtaeq'])+ " " + parseFloat(row['mmo']));
                    e.cancel = true;

                    // Opcional: muestra un mensaje de error.
                    alert('No es posible editar esta celda debido, no hay presupuesto para esta actividad en $ Subc' );
                
                } else if (column.startsWith('mhtae-e') && parseFloat(row['mmahtaeq']) <= 0) {
                    // Verifica si la columna comienza con 'subce-e' y si 'msubc' es un decimal mayor a cero.
                    // Cancela la edición si la validación falla.Number(row.dataItem[column.binding])
                    //console.log("entro a if: "+ column + " " + parseFloat(row['msubc']) + " " + parseFloat(row['mmahtaeq'])+ " " + parseFloat(row['mmo']));
                    e.cancel = true;

                    // Opcional: muestra un mensaje de error.
                    alert('No es posible editar esta celda debido, no hay presupuesto para esta actividad en $ MHtaE' );
                
                } else if (column.startsWith('mmo-e') && parseFloat(row['mmo']) <= 0) {
                    // Verifica si la columna comienza con 'subce-e' y si 'msubc' es un decimal mayor a cero.
                    // Cancela la edición si la validación falla.Number(row.dataItem[column.binding])
                    //console.log("entro a if: "+ column + " " + parseFloat(row['msubc']) + " " + parseFloat(row['mmahtaeq'])+ " " + parseFloat(row['mmo']));
                    e.cancel = true;

                    // Opcional: muestra un mensaje de error.
                    alert('No es posible editar esta celda debido, no hay presupuesto para esta actividad en $ MO' );
                
                } 

            },
            cellEditEnded(s, e) {
                // Verifica si args.row y args.row.dataItem están definidos
                /*if (!args.row || !args.row.dataItem) {
                    console.error('La fila o el dataItem están indefinidos.');
                    return;
                }*/

                //const grid = args.grid;
                //const item = args.row.dataItem;
                //const binding = args.col.binding;
                //const newValue = grid.getCellData(args.row.index, args.col.index, false);

                // Verifica si el valor ha cambiado antes de hacer la solicitud
                //if (args.cancel || newValue === args.oldValue) {
                //    return;
               // }
               console.log(s);
               
               let row = s.rows[e.row];
               //const grid = args.grid;
                //const item = d.row.dataItem;
                //const binding = args.col.binding;
                // Aquí puedes llamar a tu API para actualizar el registro
                console.log(row);
                console.log(this.currentRowChanges['idestimaciondetdet']);
                if ((row.dataItem['idestimaciondetdet']) && (row.dataItem['idestimaciondetdet'] > 0)) {
                    console.log("actualizar registro");
                    
                    // Acumular cambios en currentRowChanges en lugar de llamar a saveData directamente                   
                    console.log('Datos a actualizar: ', row.dataItem['idestimaciondetdet']);
                    console.log('Datos a actualizar: ',  row.dataItem['fecha']);
                    console.log('Datos a actualizar: ',  row.dataItem['importe']);
                    console.log('Datos a actualizar: ', row.dataItem['descripcion']);                     

                    this.updateEstimacionDetDet(row.dataItem);
                } else {
                    console.log("preguardar para nuevo registro");
                    //console.log('Datos a guardar: ', data);
                    this.currentRowChanges['idestimaciondetdet'] = row.dataItem['idestimaciondetdet'];
                    this.currentRowChanges['fecha'] = row.dataItem['fecha'];
                    this.currentRowChanges['descripcion'] = row.dataItem['descripcion'];
                    this.currentRowChanges['importe'] = row.dataItem['importe'];

                    //console.log('Datos a guardar: ', data);
                    console.log('Datos a guardar: ', this.currentRowChanges['idestimaciondetdet']);
                    console.log('Datos a guardar: ', this.currentRowChanges['fecha']);
                    console.log('Datos a guardar: ', this.currentRowChanges['importe']);                     
                    console.log('Datos a guardar: ', this.currentRowChanges['descripcion']);

                }
            },
            guardarregistro(){
                // Llamar a saveData con los cambios acumulados solo cuando no exista idestimaciondetdet con valor mayor a cero
                //validar si this.currentRowChanges['idestimaciondetdet'] es indefinida
                if (this.currentRowChanges['idestimaciondetdet'] === undefined) {
                    console.log("guardar registro");
                    this.saveData(this.currentRowChanges);
                    
                    // Limpiar currentRowChanges después de guardar
                    this.currentRowChanges = {};     
                }
            },

            saveData(data) {

                //let nuevafecha = new Date(data.fecha);
                //nuevafecha = data.fecha.getFullYear() + (data.fecha.getMonth() + 1) + data.fecha.getDate();
                //console.log(nuevafecha);
                //quitarle los guiones a la fecha
                let nuevafecha = data.fecha.replace(/-/g, "");
                console.log("nueva fecha: ",nuevafecha);
                const payload = {
                    //idestimaciondetdet: data.idestimaciondetdet || 0, // Si es nuevo, se envía 0
                    idestimaciondet: 190577,
                    idestimacion: 8,
                    idestimacionbase: 249085,
                    fecha: nuevafecha, //"20240716", //nuevafecha,
                    descripcion: data.descripcion,
                    importe: data.importe,
                    idcliente: 3,
                    idproyecto: this.selectedProject,
                };
            
                fetch(`http://157.245.220.201:3002/api/estimacionesdetdet/insertar`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(payload)
                        })
                    .then(response => {
                    console.log('Datos guardados con éxito', response);
                    // Aquí puedes manejar la respuesta exitosa, como mostrar un mensaje al usuario
                    })
                    .catch(error => {
                    console.error('Error al guardar los datos', error);
                    // Manejo de errores, como mostrar un mensaje de error al usuario
                    });
                //renderizar el grid para que se actualicen los datos
                //this.flex.refresh();

            },

            updateEstimacionDetDet(data) {

                let nuevafecha = data.fecha.replace(/-/g, "");
                console.log("nueva fecha: ",nuevafecha);
            const payload = {
                idestimaciondetdet: data.idestimaciondetdet,
                idestimaciondet: 190577,
                idestimacion: 8,
                idestimacionbase: 249085,
                fecha: nuevafecha,//"20240716", //nuevafecha,
                descripcion: data.descripcion,
                importe: data.importe,
                idcliente: 3,
                idproyecto: this.selectedProject,
            };

            fetch(`http://157.245.220.201:3002/api/estimacionesdetdet/update`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(payload)
                    })
            /*axios.post('https://tuapi.com/api/data', data)*/
                .then(response => {
                console.log('Datos actualizados con éxito', response);
                // Aquí puedes manejar la respuesta exitosa, como mostrar un mensaje al usuario
                })
                .catch(error => {
                console.error('Error al actualizar los datos', error);
                // Manejo de errores, como mostrar un mensaje de error al usuario
                });
            },

            /*cellEditEnded(s, e) {
                //this.showInput = !this.showInput;
                console.log("1. inicia celleditended");
                //if (e.panel.cellType === wijmo.grid.CellType.Cell) {
                    console.log("1.1. celleditended, entra if");
                    let col = s.columns[e.col];
                    
                    console.log(col.binding);
                    //col.binding debe comenzar con % Subc-E
                    if (col.binding.trim().startsWith('subc-e')) {
                        let numfolio = col.binding.slice('subc-e'.length);
                        console.log("1.2. celleditended, entra if");
                        //obtener la fila actual
                        let row = s.rows[e.row];
                        //s.rows.forEach(row => {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            console.log("1.3. celleditended, entra a for");
                            
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('subc-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log("row.dataItem: ");
                            console.log(row);
                            row.dataItem['msubce'] = sum;

                            sumest = Number(((Number(row.dataItem['subc-e' + numfolio]) || Number(0)) * Number(row.dataItem['msubc'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mhtae-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmahtaeq'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mmo-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmo'])/100).toFixed(2));                            
                            row.dataItem['est-e' + numfolio] = sumest * 100;

                            this.calculateColumnTotal('est-e' + numfolio);
                            //obtener el indice de la variable col
                            /*let indicecol = this.flex.columns.findIndex(column => column.binding === ('est-e' + numfolio));
                            console.log(indicecol);
                            let hdr = `${sumest}`;
                            console.log(hdr);
                            this.flex.columnHeaders.setCellData(1, indicecol, hdr);*/

                           /* console.log("1.5. celleditended, suma estimaciones " +  sumest);

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log("1.6. celleditended, suma estimaciones " +  sumestima);
                            row.dataItem['estacum'] = sumestima;
                            //row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                            this.calculateColumnTotal('estacum');
                            this.calculateColumnTotalEstacume('estacume');
                        //});
                        this.updateEstimacionDet(3, this.selectedProject, numfolio, row.dataItem['idestimacionbase'], 3, row.dataItem['subc-e' + numfolio], row.dataItem['mhtae-e' + numfolio], row.dataItem['mmo-e' + numfolio], row.dataItem['est-e' + numfolio] );
                    }
                    if (col.binding.trim().startsWith('mhtae-e')) {
                        let numfolio = col.binding.slice('mhtae-e'.length);
                        console.log("1.2. celleditended, entra if");
                        let row = s.rows[e.row];
                        //s.rows.forEach(row => {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            console.log("1.3. celleditended, entra a for");
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mhtae-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            row.dataItem['mmahtaeqe'] = sum;

                            sumest = Number(((Number(row.dataItem['subc-e' + numfolio]) || Number(0)) * Number(row.dataItem['msubc'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mhtae-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmahtaeq'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mmo-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmo'])/100).toFixed(2));                            
                            row.dataItem['est-e' + numfolio] = sumest * 100;

                            this.calculateColumnTotal('est-e' + numfolio);

                            console.log("1.5. celleditended, suma estimaciones " +  sumest);

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log("1.6. celleditended, suma estimaciones " +  sumestima);
                            row.dataItem['estacum'] = sumestima;
                            //row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                            this.calculateColumnTotal('estacum');
                            this.calculateColumnTotalEstacume('estacume');
                        //});
                        this.updateEstimacionDet(3, this.selectedProject, numfolio, row.dataItem['idestimacionbase'], 3, row.dataItem['subc-e' + numfolio], row.dataItem['mhtae-e' + numfolio], row.dataItem['mmo-e' + numfolio], row.dataItem['est-e' + numfolio] );
                    }
                    if (col.binding.trim().startsWith('mmo-e')) {
                        let numfolio = col.binding.slice('mmo-e'.length);
                        console.log("1.2. celleditended, entra if");
                        let row = s.rows[e.row];
                        //s.rows.forEach(row => {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);
                            
                            console.log("1.3. celleditended, entra a for");
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mmo-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            row.dataItem['mmoe'] = sum;

                            sumest = Number(((Number(row.dataItem['subc-e' + numfolio]) || Number(0)) * Number(row.dataItem['msubc'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mhtae-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmahtaeq'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mmo-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmo'])/100).toFixed(2));                            
                            row.dataItem['est-e' + numfolio] = sumest * 100;

                            this.calculateColumnTotal('est-e' + numfolio);

                            console.log("1.5. celleditended, suma estimaciones " +  sumest);

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log("1.6. celleditended, suma estimaciones " +  sumestima);
                            row.dataItem['estacum'] = sumestima;
                            //row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                            this.calculateColumnTotal('estacum');
                            this.calculateColumnTotalEstacume('estacume');
                        //});
                        this.updateEstimacionDet(3, this.selectedProject, numfolio, row.dataItem['idestimacionbase'], 3, row.dataItem['subc-e' + numfolio], row.dataItem['mhtae-e' + numfolio], row.dataItem['mmo-e' + numfolio], row.dataItem['est-e' + numfolio] );
                    }

                // Al final de la función, actualiza los datos en la base de datos
                //this.updateEstimacionDet(s.rows[e.row].dataItem);
               // }
            },*/

            //this.updateEstimacionDet(3, 15, numfolio, row.dataItem['idestimacionbase'], 3, row.dataItem['subc-e' + numfolio], row.dataItem['mhtae-e' + numfolio], row.dataItem['mmo-e' + numfolio], row.dataItem['importe'] );
            updateEstimacionDet: async function(idcliente, idproyecto, folio, idestimacionbase, usuario, msubce, mmahtaeqe, mmoe, importe) {
                console.log("actualizar el registro via api");
                console.log(idcliente + " " + idproyecto + " " + folio + " " + idestimacionbase + " " + usuario + " " + msubce + " " + mmahtaeqe + " " + mmoe + " " + importe);                
                try {
                    //Validación de datos
                    //validar que idcliente no sea nulo, si no es nulo convertir a número y continuar
                    if (idcliente === null) {
                        console.log("cliente es nulo");
                        return;
                    } else {
                        idcliente = Number(idcliente);
                        if (idproyecto === null) {
                            console.log("proyecto es nulo");
                            return;
                        } else {
                            idproyecto = Number(idproyecto);
                            if (folio === null) {
                                console.log("folio es nulo");
                                return;
                            } else {
                                folio = Number(folio);
                                if (idestimacionbase === null) {
                                    console.log("estimacionbase es nulo");
                                    return;
                                } else {
                                    idestimacionbase = Number(idestimacionbase);
                                    if (usuario === null) {
                                        console.log("usuario es nulo");
                                        return;
                                    } else {
                                        usuario = Number(usuario);
                                        if (msubce === null) {
                                            console.log("msubce es nulo");
                                            return;
                                        } else {
                                            msubce = Number((msubce ).toFixed(4));
                                            if (mmahtaeqe === null) {
                                                console.log("mmahtaeqe es nulo");
                                                return;
                                            } else {
                                                mmahtaeqe = Number((mmahtaeqe ).toFixed(4));
                                                if (mmoe === null) {
                                                    console.log("mmoe es nulo");
                                                    return;
                                                } else {
                                                    mmoe = Number((mmoe ).toFixed(4));
                                                    if (importe === null) {
                                                        console.log("importe es nulo");
                                                        return;
                                                    } else {
                                                        importe = Number(importe.toFixed(4));
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    console.log(idcliente + " " + idproyecto + " " + folio + " " + idestimacionbase + " " + usuario + " " + msubce + " " + mmahtaeqe + " " + mmoe + " " + importe);                
                    /*idcliente = checkAndConvertNumbers(idcliente, 'cliente');
                    idproyecto = checkAndConvertNumbers(idproyecto, 'proyecto');
                    folio = checkAndConvertNumbers(folio, 'folio');
                    idestimacionbase = checkAndConvertNumbers(idestimacionbase, 'estimacionbase');
                    usuario = checkAndConvertNumbers(usuario, 'usuario');
                    msubce = checkAndConvertNumbers(msubce, 'msubce');
                    mmahtaeqe = checkAndConvertNumbers(mmahtaeqe, 'mmahtaeqe');
                    mmoe = checkAndConvertNumbers(mmoe, 'mmoe');
                    importe = checkAndConvertNumbers(importe, 'importe');*/

                    fetch(`http://157.245.220.201:3002/api/estimacionesdet/updatebyid/`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ cliente: idcliente, proyecto: idproyecto, folio: folio, estimacionbase: idestimacionbase, usuario: 3, msubce: msubce, mmahtaeqe: mmahtaeqe, mmoe: mmoe, importe: importe })
                    });

                 } catch (error) {
                    console.error('Error al actualizar el registro: ', error);
                }
            },

            calculateColumnTotal(columnName) {
                let subtotal = 0;
                let total = 0;
                console.log(`Total para ${columnName}`);
                // Verificar que la columna existe en el grid
                if (this.flex.columns.some(column => column.binding === columnName)) {
                    // Iterate over all the rows in the FlexGrid
                    for (let i = 0; i < this.flex.rows.length; i++) {
                        // Get the value of the cell in the specified column
                        let value = this.flex.getCellData(i, columnName, false);

                        // Convert value to number using parseFloat and consider zero if NaN
                        value = parseFloat(value);
                        if (isNaN(value)) {
                            value = 0; // Consider zero if value is NaN
                        }
                        //console.log(`Total for ${columnName}: ${value}`);
                        // Add the cell's value to the total
                        subtotal += Number(value.toFixed(2));
                        //console.log(`Total for ${columnName}: ${total}`);
                    }

                    console.log(`${this.flex.itemsSource.length}`);
                    // Iterate over all the rows in the FlexGrid
                    for (let i = 0; i < this.flex.itemsSource.length; i++) {
                        // Get the value of the cell in the specified column
                        let value = this.flex.itemsSource[i][columnName];

                        // Convert value to number using parseFloat and consider zero if NaN
                        value = parseFloat(value);
                        if (isNaN(value)) {
                            value = 0; // Consider zero if value is NaN
                        }

                        // Add the cell's value to the total
                        total += Number(value.toFixed(2));
                    }                
                    // Round the subtotal to 2 decimal places
                    subtotal = Math.round(subtotal * 100) / 100;
                    // Round the total to 2 decimal places
                    total = Math.round(total * 100) / 100;

                    // Format the total
                    this.subtotal = subtotal.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
                    // Format the total
                    this.total = total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

                    //obtener el indice de la variable col
                    let indicecol = this.flex.columns.findIndex(column => column.binding === columnName);
                    console.log(indicecol);
                    //let hdrSubt = `${this.subtotal}`;
                    //console.log(hdrSubt);
                    //this.flex.columnHeaders.setCellData(1, indicecol, hdrSubt);
                    //let hdrTot = `${this.total}`;
                    //console.log(hdrTot);
                    //this.flex.columnHeaders.setCellData(0, indicecol, hdrTot);
                }
            },

            calculateColumnTotalEstacume(columnName) {
                let subtotal = 0;
                let total = 0;
                console.log(`Total para ${columnName}`);

                // Format the total
                this.subtotal = subtotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                // Format the total
                this.total = total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                //obtener el indice de la variable col
                let indicecolimporte = this.flex.columns.findIndex(column => column.binding === 'importe');
                let indicecolestacum = this.flex.columns.findIndex(column => column.binding === 'estacum');
                let indicecolestacume = this.flex.columns.findIndex(column => column.binding === columnName);

                console.log(indicecolimporte);
                console.log(indicecolestacum);
                console.log(indicecolestacume);

                let valueImporteSubt = parseFloat(this.flex.columnHeaders.getCellData(1, indicecolimporte, false));                
                let valueImporteTot = parseFloat(this.flex.columnHeaders.getCellData(0, indicecolimporte, false));
                let valueEstacumSubt = parseFloat(this.flex.columnHeaders.getCellData(1, indicecolestacum, false));
                let valueEstacumTot = parseFloat(this.flex.columnHeaders.getCellData(0, indicecolestacum, false));
                
                subtotal = valueEstacumSubt / valueImporteSubt * 100;
                //redondear subtotal a 2 decimales
                subtotal = Math.round(subtotal * 100) / 100;

                total = valueEstacumTot / valueImporteTot * 100;
                //redondear total a 2 decimales
                total = Math.round(total * 100) / 100;

                let indicecol = this.flex.columns.findIndex(column => column.binding === columnName);
                console.log(indicecol);

                let hdrSubt = `${subtotal}%`;
                console.log(hdrSubt);
                this.flex.columnHeaders.setCellData(1, indicecol, hdrSubt);

                let hdrTot = `${total}%`;
                console.log(hdrTot);
                this.flex.columnHeaders.setCellData(0, indicecol, hdrTot);
            },

            updateSubtotals() {
                // Remove any existing footer rows
                this.flex.columnFooters.rows.clear();

                // Create a new footer row
                let footerRow = new wijmo.grid.GroupRow();

                // Calculate the subtotal for each column and add it to the footer row
                this.flex.columns.forEach((column) => {
                    let subtotal = this.calculateColumnTotal(column.binding);
                    footerRow.data[column.binding] = subtotal;
                });

                // Add the footer row to the columnFooters panel
                this.flex.columnFooters.rows.push(footerRow);
            },    
            
            checkAndConvertNumbers(value, name) {
                if (value === null) {
                    console.log(`${name} es nulo`);
                    return null;
                } else {
                    if (name === 'msubce' || name === 'mmahtaeqe' || name === 'mmoe') {
                        return Number((value / 100).toFixed(4));
                    } else if (name === 'importe') {
                        return Number(value.toFixed(4));
                    } else {
                        return Number(value);
                    }
                }
            },
            calculaAcumuladosInicial(columnname) {
                try {
                    console.log("La columna calculada inicial es: " + columnname);
                    //col.binding debe comenzar con % Subc-E
                    if (columnname.trim().startsWith('subc-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (columnname.trim().startsWith('subc-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(row);
                            this.flex.row.dataItem['msubce'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }

                    if (col.binding.trim().startsWith('mhtae-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mhtae-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            this.flex.row.dataItem['mmahtaeqe'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }

                    if (col.binding.trim().startsWith('mmo-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);
                            
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mmo-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            this.flex.row.dataItem['mmoe'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }
                } catch (error) {
                    console.error('Error al calcular los acumulados iniciales: ', error);
                }
            },

            selectAll() {
                this.checkedEstimaciones = [...this.estimaciones];
                console.log(this.checkedEstimaciones);
            },

            deselectAll() {
                this.checkedEstimaciones = [];
                console.log(this.checkedEstimaciones);
            },

            filterColumns() {

                console.log("1. filterColumns, entra a la función");
                //const grid = this.flex.control;
                //this.uncheckedEstimaciones = this.getUnselectedItems();

                //recorrer el arreglo de la lista uncheckedEstimaciones para validar si se encuentra en el arreglo de columnas de flex. Si hay alguna cuyo binding comience con subce-e más el valor de uncheckedEstimaciones, entonces mostrarla. marcar la columna como no visible
                if (this.flex && this.flex.columns) {
                    console.log("1.1. filterColumns, entra a la función");
                    let countColor = 0;
                    this.flex.columns.forEach(column => {
                        console.log(column.binding);
                        // Si la columna comienza con uno de los prefijos y el sufijo está en uncheckedEstimaciones, ocultarla
                        if (this.uncheckedEstimaciones.some(estimacion => column.binding === ("subc-e" + estimacion) || column.binding === ("mhtae-e" + estimacion) || column.binding === ("mmo-e" + estimacion) || column.binding === ("est-e" + estimacion))) {
                            console.log("1.2. filterColumns, coincide encabezado no seleccionado");
                            //console.log(estimacion);
                            column.visible = false;
                        }
                        // Si la columna comienza con uno de los prefijos y el sufijo está en checkedEstimaciones, mostrarla
                        else if (this.checkedEstimaciones.some(estimacion => column.binding === ("subc-e" + estimacion) || column.binding === ("mhtae-e" + estimacion) || column.binding === ("mmo-e" + estimacion) || column.binding === ("est-e" + estimacion))) {
                            console.log("1.2. filterColumns, coincide encabezado seleccionado");
                            //console.log(estimacion);
                            column.visible = true;
                            countColor++;
                            column.backgroundColor = countColor % 2 === 0 ? "#cee2fb" : "#e6f0fd";
                        }

                        // Forzar a la grilla a repintarse
                        this.flex.invalidate();
                        console.log("1.3. filterColumns, termina función");
                    });                    
                }
            },
            filterEtapas: function(e) {
                let filter = e.target.value.toLowerCase();
                this.flex.collectionView.filter = item => {
                    return (
                        filter.length == 0 ||
                        item.etapa.toLowerCase().indexOf(filter) > -1
                    );
                };
                this.flex.columns.forEach(column => {
                    if (column.binding === "msubc" || column.binding === "mmahtaeq" || column.binding === "mmo" || column.binding === "importe" || column.binding === "estacum" || column.binding === "estacume" || column.binding.startsWith('est-e')) {
                        console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                        this.calculateColumnTotal(column.binding);
                    }
                });
            },
            /*filterDPGen: function(e) {
                let filter = e.target.value.toLowerCase();
                this.flex.collectionView.filter = item => {
                    return (
                        filter.length == 0 ||
                        item.dpgen.toLowerCase().indexOf(filter) > -1
                    );
                };
                this.flex.columns.forEach(column => {
                    if (column.binding === "msubc" || column.binding === "mmahtaeq" || column.binding === "mmo" || column.binding === "importe" || column.binding === "estacum" || column.binding === "estacume" || column.binding.startsWith('est-e')) {
                        console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                        this.calculateColumnTotal(column.binding);
                    }
                });
            },*/
            onCheckedEstimacionesChanged: function(sender){
                this.checkedEstimaciones = sender.checkedItems;
                console.log("Cambiaron los seleccionados");
                console.log(this.checkedEstimaciones);

                // Obtener los elementos no seleccionados
                this.uncheckedEstimaciones = this.estimaciones.filter(item => !this.checkedEstimaciones.includes(item));
                console.log("Elementos no seleccionados");
                console.log(this.uncheckedEstimaciones);                
            },
        }
    }
</script>

<style>
    .wj-flexgrid {
        /*height: auto;*/
        width: 100;/*width: 96vw; /*calc(100vw - 56px);*/
        height: 72vh; /*calc(100vh - 276px);        */
        margin: 6px 0;
        font-family: Arial, sans-serif; 
        font-size: 12px;
        overflow: auto;
    }

    .checkbox label input[type=checkbox] {
        margin-right: 5px;
    }

    .checkbox label {
        margin-right: 10px;
    }

    .btn {
        margin-right: 5px;
    }

    #flex-grid {
        display: flex;
    }
    .column {
        flex: 1;
        border: 1px solid black;
        height: 50px;
    }
    .left-align {
    text-align: left;
    }

    .custom-filter .wj-elem-filter {
        position: absolute;
        bottom: 0;
    }

    .wj-flexgrid .wj-cells .wj-row .wj-cell:hover {
        /* permite visualizar el valor de la celda completa */
        overflow: visible;
        z-index: 6 !important;
        color: black;
        text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px #eee,
            0 0 2px #eee,
            0 0 3px #eee,
            0 0 4px #eee,
            0 0 5px #eee,
            0 0 6px #eee,
            0 0 7px #eee,
            0 0 8px #eee,
            0 0 9px #eee,
            0 0 10px #eee
    }

    .high-value {
        font-weight: bold;
        color: red;
    }
    .low-value {
        font-style: italic;
        color: green;        
    }
    .cero-value {
        font-weight: bold;
        color: black;
    }
    .entre-value {
        color: blue;
    }
    .uno-value {
        color: green;
    }
    .mayor-value {
        color: red;
    }

    .align-left {
        text-align: left;
    }
</style>