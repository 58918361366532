<template>
  <div style="background-color: rgb(180, 200, 230);">
    <h1 style="background-color: rgb(155, 155, 155); color: white;">Resumen de estimaciones de {{ this.projectDescripcion }}
    </h1>
    <!-- Contenedor principal  style="width: 100%; height: 100vh;"-->
    <div class="container-fluid">
      <!-- Botón de colapso -->
      <div class="text-right mb-2"> <!-- Alinea el botón a la derecha y agrega espacio inferior -->
        <button @click="toggleCollapse">{{ collapsed ? 'Mostrar campos' : 'Ocultar campos' }}</button>
        <button @click="handleRefreshing(pivotGrid, null)">Contraer filas</button>
        <button @click="save({})" >Exportar</button>
      </div>
      <div class="form-group">
         <!-- <label  for="estimaciones" title="MultiSelect">Selecciona los folios a consultar: </label>
           Botón Filtrar <wj-multi-select id="estimaciones" :itemsSource="estimaciones" :checkedItems="estimaciones" />
         <wj-multi-select ref="estimaciones"
              :placeholder="'Estimaciones'"
              :headerFormat="'{count:n0} estimaciones'"
              
              :showSelectAllCheckbox="true"
              :showFilterInput="true"
              :itemsSource="estimaciones"                
              :checkedItemsChanged='onCheckedEstimacionesChanged'></wj-multi-select>
          <!-- Botón Filtrar 
          <button @click="reloadData">Filtrar</button>/>-->
      </div>
      <div class="col-sm-3">
        <!-- Contenedor del PivotPanel (solo se renderiza si selectedProject tiene un valor) -->
        <div id="mi-panel" :class="{ 'collapsed': collapsed }" ref="pivotPanel" v-show="!collapsed">
          <!-- Contenido del PivotPanel -->
        </div>
      </div>

      <div class="row">
        <!-- Contenedor del PivotGrid style="width: 100%; height: 100%;"-->
        <div ref="pivotGrid" style="width: 100%; height: 72vh; overflow: auto; font-family: Arial, sans-serif; font-size: 12px;" @click="handlePivotGridClick"></div>
      </div>
    </div>
  </div>
</template>

<script>

  //import * as XLSX from 'xlsx';
  import '@grapecity/wijmo.styles/wijmo.css';
  import 'bootstrap/dist/css/bootstrap.css';
  import Vue from 'vue';
  import '@grapecity/wijmo.touch'; // support drag/drop on touch devices
  import '@grapecity/wijmo.vue2.olap';
  import * as wjcOlap from '@grapecity/wijmo.olap';
  import * as wjcXlsx from '@grapecity/wijmo.xlsx';
  import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
  import { Tooltip } from '@grapecity/wijmo';
  //import { ObservableArray } from 'wijmo/wijmo';
  // Definir la constante customTemplate aquí
  const apiCache = {};

  const customTemplate = `<div class="root">  
<div class="field-list-label">  
  <label wj-part="g-flds"></label>  
</div>  
<div class="field-list pad">  
  <div wj-part="d-fields"></div>  
</div>  
<div class="drag-areas-label">  
  <label wj-part="g-drag"></label>  
</div>  
<div class="filter-list pad">  
  <label>  
    <span class="wj-glyph wj-glyph-filter"></span>   
    <span wj-part="g-flt"></span>  
  </label>  
  <div wj-part="d-filters"></div>  
</div>  
<div class="column-list pad bdr-left">  
  <label>  
    <span class="wj-glyph">⫴</span>   
    <span wj-part="g-cols"></span>  
  </label>  
  <div wj-part="d-cols"></div>  
</div>  
<div class="row-list pad bdr-top">  
  <label>  
    <span class="wj-glyph">≡</span>   
    <span wj-part="g-rows"></span>  
  </label>  
  <div wj-part="d-rows"></div>  
</div>  
<div class="values-list pad bdr-left bdr-top">  
  <label>  
    <span class="wj-glyph">Σ</span>   
    <span wj-part="g-vals"></span>  
  </label>  
  <div wj-part="d-vals"></div>  
</div>  
<div wj-part="d-prog" class="progress-bar"></div>  
<div class="control-area">  
  <label>  
    <input wj-part="chk-defer" type="checkbox">   
    <span wj-part="g-defer">Defer Updates</span>  
  </label>  
  <button wj-part="btn-update" class="wj-btn wj-state-disabled" type="button" disabled>
    Update  
  </button>  
</div>  
</div>`;

  export default {
    data() {
      return {
        selectedProject: null,
        projects: [],
        ngPanel: null,
        collapsed: true, // Inicialmente, el contenido está colapsado
        tt: null,
        rowsExpanded: true,
        selectedDate: null,
        selectedDateStr: null,
        gridDataEst: null,
        estimaciones: [],
        checkedEstimaciones: [], // inicialmente no hay items seleccionados
        uncheckedEstimaciones: [], // inicialmente no hay items no seleccionados
        countEstimaciones: 0,
        showInput: false,
        showFilterInput: true,
        showSelectAllCheckbox: true,
        flex: null,
        includeColumnHeader: true,
        customContent: false
      };
    },
    async created() {
      if (this.projectId) {
        this.selectedProject = this.projectId
        await this.loadProjectDataWithCache();
      }
      else {
        await this.fetchProjectsWithCache();        
      }

      // Configurar el evento formatItem después de cargar los datos
      console.log("9. pasa por aqui...");
      if (this.pivotGrid) {
      console.log("9.1. pasa por aqui...");
      this.pivotGrid.formatItem.addHandler((s, e) => {
        let panel = e.panel,
        r = e.row,
        c = e.col,
        cell = e.cell;

        // Verificar si e.xlsxCell y e.xlsxCell.style existen antes de acceder a ellos
        if (e.xlsxCell && e.xlsxCell.style) {
          if (panel.cellType === wjcGrid.CellType.Cell) {
            if (panel.columns[c].binding === 'color') {
              if (e.xlsxCell.value) {
                if (!e.xlsxCell.style.font) {
                  e.xlsxCell.style.font = {};
                }
                e.xlsxCell.style.font.color = e.xlsxCell.value.toLowerCase();
              }
            } else if (panel.columns[c].binding === 'active' && panel.rows[r] instanceof wjcGrid.GroupRow) {
              let cell = e.getFormattedCell();
              e.xlsxCell.value = cell.textContent.trim();
              e.xlsxCell.style.hAlign = wjcXlsx.HAlign.Left;
            }
          }
        }
        if (e.panel == s.columnHeaders) {
          const columnName = s.columns[e.col].binding; // Obtener el nombre de la columna
          console.log("9.2. pasa por aqui...", columnName);
          console.log("9.3. pasa por aqui... row: ", s.columns[e.col]);
        }

        // we are interested in the cells panel
        if (e.panel == s.cells) {
          console.log("10.2.1. pasa por aqui... valor: ", e.panel);
          if (e.row % 2 == 1) {
            let value = s.getCellData(e.row, e.col, false);
            console.log("10.2.1. pasa por aqui... valor: ", value);
          }
               /* let binding = this.flex.columns[c].binding;
                if (binding === 'subc' || binding === 'mhtae' || binding === 'mo' || binding === 'total') {
                    let value = e.panel.getCellData(e.row, e.col, false);
                    wjcCore.toggleClass(e.cell, 'cero-value', wjcCore.isNumber(value) && value === 0);
                    wjcCore.toggleClass(e.cell, 'entre-value', wjcCore.isNumber(value) && value > 0);
                    //wjcCore.toggleClass(e.cell, 'uno-value', wjcCore.isNumber(value) && value === 1);
                    wjcCore.toggleClass(e.cell, 'mayor-value', wjcCore.isNumber(value) && value < 0);
                    //wjcCore.toggleClass(e.cell, 'low-value', wjcCore.isNumber(value) && value < 00);
                }*/
              // Aplica el color de fondo basado en el número de columna
              switch (c) {
                case 0: // Primera columna roja
                  cell.style.backgroundColor = '#ffdddd'; // Rojo claro
                  break;
                case 1: // Segunda columna roja
                  cell.style.backgroundColor = '#ffcccc'; // Verde claro
                  break;
                case 2: // Tercera columna roja
                  cell.style.backgroundColor = '#ffc1c1'; // Azul claro
                  break;
                case 3: // Segunda columna roja
                  cell.style.backgroundColor = '#fcb9b2'; // Verde claro
                  break;
                case 4: // Segunda columna verde 
                  cell.style.backgroundColor = '#d6ffd6 '; // Verde claro
                  break;
                case 5: // Segunda columna verde
                  cell.style.backgroundColor = '#ccffcc'; // Verde claro
                  break;
                case 6: // Segunda columna verde 
                  cell.style.backgroundColor = '#b3f0b3'; // Verde claro
                  break;
                case 7: // Segunda columna verde
                  cell.style.backgroundColor = '#c4e6c3'; // Verde claro
                  break;
                case 8: // Segunda columna morado 
                  cell.style.backgroundColor = '#e0e0ff'; // Verde claro
                  break;
                case 9: // Segunda columna
                  cell.style.backgroundColor = '#d1d1ff'; // Verde claro
                  break;
                case 10: // Segunda columna
                  cell.style.backgroundColor = '#ccccff'; // Verde claro
                  break;
                case 11: // Segunda columna
                  cell.style.backgroundColor = '#b8b8ff'; // Verde claro
                  break;
                // Agrega más casos según sea necesario
                default:
                  cell.style.backgroundColor = ''; // Sin color de fondo para otras columnas
                  break;
              }                   
        }

        // Aplica estilos de borde a todas las celdas
        console.log("1. formatItem, entra");
        cell.style.borderRight = '3px solid rgba(28, 1, 1, 0.57)';
        cell.style.borderBottom = '1px solid rgba(139, 132, 138, 0.14)';
        //e.cell.style.backgroundColor = 'white';
        
        console.log("9.2.2.1. pasa por aqui... valor: ", s.cells);
        console.log("9.2.2.2. pasa por aqui... valor: ", e.panel.cellType);

        let cell2 = e.panel.getCellData(e.row, e.col, true);
        if (cell2 == 'Subtotal') {
          const groupCellValue = e.panel.getCellData(e.row, e.col - 1, true);
          e.cell.innerHTML = 'Subtotal' + ' ' + groupCellValue;
          e.cell.style.fontWeight = 'bold';
          e.cell.style.backgroundColor = '#d9edf7';
          e.cell.style.fontSize = 'smaller';
        }

        // remove color by default
        let color = '';

        if (e.col >= 2) {
          let value = s.getCellData(e.row, e.col, false),
            //glyph = 'circle',
            span = ' <span style="font-size:120%" class="wj-glyph-{glyph}"></span>';
            //color = '#d8b400';
          if (value != null) {
            if (value < 0) { // negative variation
                color = '#9f0000';
                //glyph = 'down';
            } else if (value >= 0) { // positive variation
                //color = '#4c8f00';
                //glyph = 'up';
            }
            // apply cell color
            e.cell.style.color = color;

          }
        }
      });

      } else {
        console.error("this.ngPanel is undefined");
      }
      // Agregar el manejador para el evento de actualización
      console.log("10. pasa por aqui...");
      this.pivotGrid.updatingLayout.addHandler(this.handleRefreshing);

      console.log("12. pasa por aqui...");
      this.pivotGrid.rendered.addHandler(this.rendered);

      // 
      console.log("11. pasa por aqui...");

      //wjcOlap.PivotPanel.controlTemplate = customTemplate;
      this.pivotGrid.cellClicked.addHandler(this.onCellClick);

    },
    computed: {
      projectId() {
        return this.$store.getters.getProjectId;
      },
      projectDescripcion() {
        return this.$store.getters.getProjectDescription;
      },
      allOptions() {
        return ['Seleccionar todas', 'Deseleccionar todas', ...this.estimaciones];
      },
      getUnselectedItems() {        
        return this.estimaciones.filter(item => !this.checkedEstimaciones.includes(item));
      },      

    },
    watch: {
      selectedProject(newValue, oldValue) {
        // Tu lógica aquí
      },      
    },
    methods: {

      formatItem: function(sender, event) {
                let panel = event.panel,
                    r = event.row,
                    c = event.col,
                    cell = event.cell;

                    // Aplica estilos de borde a todas las celdas
                  console.log("1. formatItem, entra");
                  cell.style.borderRight = '3px solid rgba(28, 1, 1, 0.57)';
                  cell.style.borderBottom = '1px solid rgba(139, 132, 138, 0.14)';
                    console.log("1.1. formatItem, entra if");
                if (event.panel == sender.cells) {
                    let binding = this.flex.columns[c].binding;
                    if (binding === 'subc' || binding === 'mhtae' || binding === 'mo' || binding === 'total') {
                        let value = e.panel.getCellData(e.row, e.col, false);
                        wjcCore.toggleClass(e.cell, 'cero-value', wjcCore.isNumber(value) && value === 0);
                        wjcCore.toggleClass(e.cell, 'entre-value', wjcCore.isNumber(value) && value > 0);
                        //wjcCore.toggleClass(e.cell, 'uno-value', wjcCore.isNumber(value) && value === 1);
                        wjcCore.toggleClass(e.cell, 'mayor-value', wjcCore.isNumber(value) && value < 0);
                        //wjcCore.toggleClass(e.cell, 'low-value', wjcCore.isNumber(value) && value < 00);
                    }
                  // Aplica el color de fondo basado en el número de columna
                  switch (c) {
                    case 3: // Primera columna
                      cell.style.backgroundColor = '#ffcccc'; // Rojo claro
                      break;
                    case 4: // Segunda columna
                      cell.style.backgroundColor = '#ffcccc'; // Verde claro
                      break;
                    case 5: // Tercera columna
                      cell.style.backgroundColor = '#ffcccc'; // Azul claro
                      break;
                    case 6: // Segunda columna
                      cell.style.backgroundColor = '#ccffcc'; // Verde claro
                      break;
                    case 7: // Segunda columna
                      cell.style.backgroundColor = '#ccffcc'; // Verde claro
                      break;
                    case 8: // Segunda columna
                      cell.style.backgroundColor = '#ccccff'; // Verde claro
                      break;
                    case 9: // Segunda columna
                      cell.style.backgroundColor = '#ccccff'; // Verde claro
                      break;
                    // Agrega más casos según sea necesario
                    default:
                      cell.style.backgroundColor = ''; // Sin color de fondo para otras columnas
                      break;
                  }                   
                }
            },

      /*save() {
          wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
            this.pivotGrid,
              {
                  includeColumnHeaders: this.includeColumnHeader,
                  includeStyles: false,
                  formatItem: this.customContent
                      ? this.exportFormatItem
                      : null
              },
              'ResumenEstimacionesporActividad.xlsx'
          );
      },*/
      save() {
// create book with current view
        let book = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
                this.pivotGrid,
          {
              includeColumnHeaders: true,
              includeRowHeaders: true
          });
          book.sheets[0].name = 'ResumenEstimacionesporActividad';

          // save the book
          book.saveAsync('ResumenEstimacionesporActividad.xlsx');
      },

      exportFormatItem: function(args) {
          var p = args.panel,
              row = args.row,
              col = args.col,
              xlsxCell = args.xlsxCell;

          if (p.cellType === wjcGrid.CellType.Cell) {
              if (p.columns[col].binding === 'color') {
                  if (xlsxCell.value) {
                      if (!xlsxCell.style.font) {
                          xlsxCell.style.font = {};
                      }
                      xlsxCell.style.font.color = xlsxCell.value.toLowerCase();
                  }
              } else if (p.columns[col].binding === 'active' && p.rows[row] instanceof wjcGrid.GroupRow) {
                  let cell = args.getFormattedCell();
                  xlsxCell.value = cell.textContent.trim();
                  xlsxCell.style.hAlign = wjcXlsx.HAlign.Left;
              }
          }
          // Enviar a excel el valor de las filas
          for (let r = 0; r < p.rows.length; r++) {
              for (let c = 0; c < p.columns.length; c++) {
                  let cellValue = p.getCellData(r, c, true);
                  // Aquí puedes agregar el valor de la celda a xlsxCell
                  // Por ejemplo, podrías concatenar los valores
                  xlsxCell.value += cellValue + ' ';
              }
          }
      },
                  
      toggleCollapse() {
        this.collapsed = !this.collapsed; // Cambiar el estado de colapso
      },

      async fetchProjectsWithCache() {
        try {        
          const cacheKey = 'projectsCache_tdre_pry';
          if (apiCache[cacheKey]) {
            this.projects = apiCache[cacheKey];
            console.log('Se cargo desde cache');
          } else {
            try {
              const response = await fetch('http://157.245.220.201:3002/api/proyectos');
              console.log("2. fetchProjects: pasa por aquí");
              if (response.ok) {
                const data = await response.json();
                this.projects = data.body;
                apiCache[cacheKey] = data.body; // Almacenar en caché
                console.log('Se cargo desde api.');
              } else {
                console.log('No se pudo cargar la lista de proyectos');
              }
            } catch (error) {
              console.error('Error al cargar la lista de proyectos: ', error);
            }
          }
        } catch (error) {
          console.error('Error al obtener el proyecto:', error);
        }
      },

      async loadProjectDataWithCache() {

        try {
          this.selectedProject = this.$store.getters.getProjectId;
          if (!this.selectedProject) {
            return;
          }
          console.log("seguidor msj0");
          const cacheKey = `projectDataCache_tdre_resumen_${this.selectedProject}`;
          console.log("seguidor msj1");
          if (apiCache[cacheKey]) {
            console.log("seguidor msj2");
            this.initializePivotGrid(apiCache[cacheKey]);
            console.log('B Se cargo desde cache');
          } else {
            try {
              //obtener la fecha con formato yyyy-mm-dd
              console.log(this.selectedDate); // 2024-04-01
              console.log("proyecto: ", this.selectedProject);
              const response = await fetch(`http://157.245.220.201:3002/api/estimaciones/getdtresumen?cliente=3&proyecto=${this.selectedProject}`);
              console.log("B3. loadProjectData: pasa por aquí");
              if (response.ok) {
                console.log("B3.0. loadProjectData: pasa por aquí");
                const projectData = await response.json();
                console.log("B3.1. loadProjectData: pasa por aquí");
  
                // Imprimir la respuesta de la API
                console.log('Respuesta de la API:', projectData);

               /* // Obtén una referencia al elemento que alojará el PivotGrid
                const pivotGridElement = this.$refs.pivotGrid;

                // Verifica si pivotGridElement está definido
                if (pivotGridElement) {
                  // Limpiar cualquier contenido anterior en el contenedor
                  pivotGridElement.innerHTML = '';

                  // Verificar si ya existe un control PivotGrid y eliminarlo si es necesario
                  if (this.pivotGrid) {
                    this.pivotGrid.dispose();
                  }

                  // Crear y mostrar el PivotGrid en el contenedor
                  this.pivotGrid = new wjcOlap.PivotGrid(pivotGridElement, {
                    itemsSource: ngPanel.pivotView,
                    autoScroll: true,// Puedes usar 'Vertical' o 'Both' según tus necesidades
                    errorTip: true,
                    showErrors: true,            
                  });    
                } else {
                  console.error('El elemento pivotGrid no está definido');
                }                  
                // Si el elemento ya está alojando un control, solo actualizar la data del pivotGrid
                if (wijmo.Control.getControl(pivotGridElement)) {
                  console.log("B3.2. loadProjectData: pasa por aquí");
                  this.pivotGrid.itemsSource = projectData.body;
                  this.pivotGrid.refresh();
                } else {
                  console.log("B3.3. loadProjectData: pasa por aquí");                  
                  console.log("El tipo de datos es:", typeof projectData.body);
                  console.log("los datos son:", projectData.body);            
*/
                  //this.initializePivotGrid(projectData.body);
                  this.initializePivotGrid(projectData.body);
                  apiCache[cacheKey] = projectData.body; // Almacenar en caché
                  console.log('B Se cargo desde api.');
                //await this.fetchDataEstFolio();
              } else {
                console.log('B No se pudo cargar el proyecto. Estado: ', response.status);
              }
            } catch (error) {
              console.error('B Error al cargar los datos del reporte: ', error);
            }
          }
        } catch (error) {
          console.error('B Error en loadProjectDataWithCache:', error);
        }        
      },

      initializePivotGrid(data) {
        // Crear un PivotEngine con los datos obtenidos de la API
        const ngPanel = new wjcOlap.PivotEngine({
          itemsSource: data,
          autoGenerateFields: true,
          fields: [
            { binding: 'proyecto', header: 'Presupuesto', width: 70 },
            { binding: 'procedencia', header: 'Procedencia', width: 70 },
            { binding: 'actividad', header: 'Actividad', width: 300 },
            { binding: 'ni', header: 'N', width: 60 },
            { binding: 'partida', header: 'Partida', width: 220 },
            { binding: 'dt', header: 'DT', width: 220 },            
            { binding: 'subc', header: 'Subc', format: 'c2', width: 100 },
            { binding: 'mhtae', header: 'MHtaEq', format: 'c2', width: 100 },
            { binding: 'mo', header: 'Mo', format: 'c2', width: 100 },
            { binding: 'total', header: 'Total', width: 100, format: 'c2' },
          ],
          filterFields: ['Presupuesto'],
          columnFields: ['Procedencia'],
          valueFields: ['Subc','MHtaEq', 'Mo', 'Total'],
          rowFields: ['Actividad'],
          showRowTotals: 'Subtotals',
          showColumnTotals: 'None',           
          totalsBeforeData: true,
        });

        console.log("4. initializePivotGrid: pasa por aquí");
        // Configurar la propiedad collapsedHeaders para que las columnas se carguen colapsadas por defecto

        // Obtener el contenedor del PivotGrid por su ref
        console.log("4.1. initializePivotGrid: pasa por aquí");

        // Obtener el contenedor del PivotGrid por su ref
        console.log("4.2. initializePivotGrid: pasa por aquí");

        // Asegurarse de que el contenedor exista antes de inicializar el PivotGrid
        console.log("4.3. initializePivotGrid: pasa por aquí");
        if (this.$refs.pivotGrid) {
          // Limpiar cualquier contenido anterior en el contenedor
          this.$refs.pivotGrid.innerHTML = '';

          // Crear y mostrar el PivotGrid en el contenedor
          console.log("4.3.1. initializePivotGrid: pasa por aquí");

          // Verificar si ya existe un control PivotGrid y eliminarlo si es necesario
          /*if (this.pivotGrid) {

            this.pivotGrid.dispose();
            console.log("4.3.1.1. initializePivotGrid: pasa por aquí");
          }*/
          this.pivotGrid = new wjcOlap.PivotGrid(this.$refs.pivotGrid, {
            itemsSource: ngPanel.pivotView,
            autoScroll: true,// Puedes usar 'Vertical' o 'Both' según tus necesidades
            errorTip: true,
            showErrors: true,            
          });        
        }

        // Crear un PivotPanel y conectarlo al mismo PivotEngine
        console.log("4.4. initializePivotGrid: pasa por aquí");

        // Verificar si ya existe un control PivotPanel y eliminarlo si es necesario
        //console.log("4.4.0. pasa por aquí");
        /*if (this.pivotPanel) {
          console.log("4.4.1. pasa por aquí");
          this.pivotPanel.dispose();
          console.log("4.4.1.1. initializePivotGrid: pasa por aquí");
        }*/

        //this.pivotPanel = 
        new wjcOlap.PivotPanel(this.$refs.pivotPanel, {
          engine: ngPanel,          
        });

        console.log("4.5. initializePivotGrid: pasa por aquí");
        this.ngPanel = ngPanel;

        if (this.pivotGrid) {
          this.pivotPanel.controlTemplate = customTemplate;
        } else {
          console.error('pivotGrid is undefined');
        }

        this.pivotGrid.scrollPositionX = 2; // Ajusta la posición horizontal del scroll hacia la derecha
        // Después de inicializar el PivotGrid
        this.pivotGrid.resizingColumn.addHandler(() => {
          this.pivotGrid.autoSizeColumns();
        });

       // console.log("ngpanel", this.ngPanel.itemsSource);
       // console.log("pivotview", this.ngPanel.pivotView);
        // Espera a que el PivotGrid se haya renderizado completamente
        this.$nextTick(() => {
          if (this.pivotGrid) {
            // Colapsa los renglones al nivel deseado (por ejemplo, nivel 0)
            console.log("4.6. initializePivotGrid: pasa por aquí");
          }
        });
        
      },

      async reloadData() {
        await this.reloadProjectDataWithCache();
        if (this.pivotGrid) {
          this.pivotGrid.refresh();
        }
        if (this.pivotPanel) {
          this.pivotPanel.refresh();
        }
      },

      async reloadProjectDataWithCache() {
        try {
          this.selectedProject = this.$store.getters.getProjectId;
          if (!this.selectedProject) {
            return;
          }

          const cacheKey = `projectDataCache_tdre_resumen_${this.selectedProject}`;
          /*if (apiCache[cacheKey]) {
            this.initializePivotGrid(apiCache[cacheKey]);
            console.log('A. Se cargo desde cache', apiCache[cacheKey]);
          } else {*/
            try {
              const response = await fetch(`http://157.245.220.201:3002/api/estimaciones/getdtresumen?cliente=3&proyecto=${this.selectedProject}`);
              console.log("A3. reloadProjectDataWithCache: pasa por aquí");
              if (response.ok) {
                console.log("A3.0. reloadProjectDataWithCache: pasa por aquí");
                const projectData = await response.json();

                // Imprimir la respuesta de la API
                console.log('Respuesta de la API:', projectData);

                console.log("A3.1. reloadProjectDataWithCache: pasa por aquí");
  
                // Obtén una referencia al elemento que alojará el PivotGrid
                const pivotGridElement = this.$refs.pivotGrid;

                // Check if pivotGridElement is defined
                if (pivotGridElement) {
                  // Si el elemento ya está alojando un control, solo actualizar la data del pivotGrid
                  if (wijmo.Control.getControl(pivotGridElement)) {
                    // Limpiar los datos actuales
                    console.log("A3.2. reloadProjectDataWithCache solo se actualiza");
                    this.pivotGrid.itemsSource = null;
                    this.ngPanel.itemsSource = null;

                    // Cargar nuevos datos
                    this.ngPanel.itemsSource = projectData.body[0];
                    this.pivotGrid.itemsSource = this.ngPanel.pivotView;
                    console.log("B El tipo de datos es:", typeof projectData.body[0]);
                    console.log("B los datos son:", projectData.body[0]);
                    console.log("ngpanel", this.ngPanel.itemsSource);
                    console.log("pivotview", this.ngPanel.pivotView);

                    // Refrescar el pivotGrid
                    this.pivotGrid.refresh();
                  }
                } else {
                  console.error('El elemento pivotGrid no está definido.');
                }
                apiCache[cacheKey] = projectData.body[0]; // Almacenar en caché
                console.log('A. reloadProjectDataWithCache Se cargo desde api.');
              } else {
                console.log('A. reloadProjectDataWithCache No se pudo cargar el proyecto. Estado: ', response.status);
              }
            } catch (error) {
              console.error('A. reloadProjectDataWithCache Error al cargar el proyecto: ', error);
            }
          //}
        } catch (error) {
          console.error('A. Error en loadProjectDataWithCache:', error);
        }        
      },      

      customAggregateHandler({ field, items, data, type }) {
        if (type === 'subtotal') {
          const descripcionGrupo = items[0].data.actividad; // Suponiendo que tienes el campo 'descripcionGrupo' en tus datos
          const subtotalRow = {
            [field]: `Subtotal - ${descripcionGrupo}`,
            // Agrega otros campos según sea necesario
          };
          return subtotalRow;
        }
        // Devuelve el valor predeterminado si no es una fila de subtotal
        return null;
      },

      handleRefreshing: function (sender, args) {
        if (sender.engine) {
            // Collapse rows to level 1.
            sender.collapseRowsToLevel(1);
            console.log('pasa a handleRefreshing');
            // Remove the handler to avoid recursion.
            sender.updatingLayout.removeHandler(this.handleRefreshing);
        }
      },

      //Utiliza el evento cellRendered del panelGrid para acceder a cada celda y personalizar su contenido y estilo.
      cellRendered: function (sender, args) {
        console.log('pasa a cellRendered');
      },

      onCellClick(e) {
        const columnIndex = e.cell.columnIndex;
        console.log('diste un clic');
        this.$nextTick(() => {
          const tableElement = e.component._$element.find('.dx-datagrid-rowsview table').first();
          const columnCells = tableElement.find(`td:nth-child(${columnIndex + 1})`);
          columnCells.css('background-color', 'blue');
          
        });
      },      

      rendered: function (s, e) {
        // Verifica si la celda pertenece a la sección de celdas
        /*if (e.panel == s.cells) {
          console.log("10.2.1. pasa por aqui... valor: ", e.panel);

          // Remueve el color por defecto
          //let color = '';

          // Formatea celdas diferentes si el formato personalizado está activado
          if (e.row % 2 == 1) {
            let value = s.getCellData(e.row, e.col, false);
            console.log("10.2.1. pasa por aqui... valor: ", value);

            if (value != 'Subtotal') {
              e.cell.style.backgroundColor = 'graylight';
            } else {
              e.cell.style.backgroundColor = 'rgb(238, 238, 238)';
            }
          }
        }*/
      },

      toggleTooltip() {
        const element = this.$refs.tooltipElement;

        if (this.tt.isVisible) {
          this.tt.hide();
        } else {
          this.tt.show(element, '¡Este es un elemento importante!');
        }
      },

      handlePivotGridClick(event) {
        console.log("PivotGrid clicked event!", event);
        let cell2 = event.target.innerText;
        console.log("PivotGrid clicked cell!", cell2);
          
        // Aquí puedes manejar el evento onclick dentro del PivotGrid
        console.log('Clic en el PivotGrid:', this.pivotGrid);

        // Ejemplo: Obtener información sobre la celda clicada
        const row = this.pivotGrid.hitTest(event).row;
        const col = this.pivotGrid.hitTest(event).col;
        if (cell2 == 'Subtotal') {
          console.log("PivotGrid clicked subtotal!");
        }

        console.log('Información de la celda clicada:', { row, col, cell2 });
        
        // we are interested in the cells panel
        console.log("clic pasa por aqui... valor: ", event.panel);
        // remove color by default
        let color = '';
        if (event.row % 2 == 1) {
          let value = s.getCellData(event.row, event.col, false);
          console.log("clic pasa por aqui... valor: ", value);
            //event.cell.style.backgroundColor = 'grayligth';
        } else {
          event.cell.style.backgroundColor = 'rgb(238, 238, 238)';
        }

        let ht = this.pivotGrid.hitTest(event);
        if (ht.panel == this.pivotGrid.cells) {
          let cell3 = ht.panel.getCellData(ht.row, ht.col, true);
          if (cell3 == 'Subtotal') {
            const groupCellValue = ht.panel.getCellData(ht.row, ht.col - 1, true);
            ht.cell.innerHTML = 'Subtotal' + ' ' + groupCellValue;
            ht.cell.style.backgroundColor = 'blue'; // Change the background color of the cell
          }
        }   
        
        /*let visibleRows = this.pivotGrid.cells.rows.filter(row => row.isVisible);
        console.log('filas visibles' + visibleRows); // Print the visible rows in the console
        visibleRows.forEach((row, rowIndex) => {
          let rowData = [];
          for (let colIndex = 0; colIndex < this.pivotGrid.cells.columns.length; colIndex++) {
            let cellData = this.pivotGrid.cells.getCellData(rowIndex, colIndex, true);
            rowData.push(cellData);
            if (e.panel == s.cells) {
              if (row % 2 == 0) {
                e.cell.style.backgroundColor = 'graylight'; // Color for even rows
              } else {
                e.cell.style.backgroundColor = 'rgb(238, 238, 238)'; // Color for odd rows
              }
            }
          }
          console.log('Row ' + rowIndex + ': ' + rowData.join(', '));
        });
        */
      },

      async fetchDataEstFolio() {
                try {
                    //this.selectedProject = 17;
                    this.selectedProject = this.$store.getters.getProjectId;
                    // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                    const idcliente = 3; // Establece el ID del cliente
                    const idproyecto = this.selectedProject; // Utiliza el proyecto seleccionado
                    const url = `http://157.245.220.201:3002/api/estimaciones/getlast/?cliente=${idcliente}&proyecto=${idproyecto}`;

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const responseData = await response.json();
                    this.tempgridDataFolio = responseData.body;
                    
                    // Check if the response body is not empty
                    if (this.tempgridDataFolio && this.tempgridDataFolio.length > 0) {
                        // Get the folio of the first record
                        this.countEstimaciones = Number(this.tempgridDataFolio[0].folio_maximo);
                    } else{
                        this.countEstimaciones = Number(0);
                    }
                    console.log("folio: " + this.countEstimaciones);
                } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                }
            },
      onCheckedEstimacionesChanged: function(sender){
                this.checkedEstimaciones = sender.checkedItems;
                console.log("Cambiaron los seleccionados");
                console.log(this.checkedEstimaciones);

                // Obtener los elementos no seleccionados
                this.uncheckedEstimaciones = this.estimaciones.filter(item => !this.checkedEstimaciones.includes(item));
                console.log("Elementos no seleccionados");
                console.log(this.uncheckedEstimaciones);                
            },

                  selectAll() {
                this.checkedEstimaciones = [...this.estimaciones];
                console.log(this.checkedEstimaciones);
            },

            deselectAll() {
                this.checkedEstimaciones = [];
                console.log(this.checkedEstimaciones);
            },
            onCheckedEstimacionesChanged: function(sender){
                this.checkedEstimaciones = sender.checkedItems;
                console.log("Cambiaron los seleccionados");
                console.log(this.checkedEstimaciones);

                // Obtener los elementos no seleccionados
                this.uncheckedEstimaciones = this.estimaciones.filter(item => !this.checkedEstimaciones.includes(item));
                console.log("Elementos no seleccionados");
                console.log(this.uncheckedEstimaciones);                
            },
    },

    mounted() {
      // Accede a la instancia de PivotGrid después de que ha sido montada
      // Verifica que la instancia exista
      //console.log("22. pasa por aqui...");
      // Inicializar el Tooltip al montar el componente
      //this.tt = new Tooltip();
      //this.$nextTick(() => {
      //  console.log("24. pasa por aqui...");
      //});
      //
      //this.loadProjectDataWithCache();
    },
  };
</script>

<style scoped>
#pivotGrid .wj-flexgrid .wj-row:nth-child(odd) {
    background-color: #963c3c !important;
    height: 72vh;
    overflow: auto;
}
.subtotal {
	color: white;
	background: #707070;
	font-weight: 900;
	border-radius: 4px;
	padding: 2px;
	transform: scale(1.5, 1);
	margin-right: 5px;
}
</style>