<template>
    <div class="container-fluid">
        <h4 style="background-color: rgb(155, 155, 155); color: white;">Estimaciones por Actividad de "{{ this.projectDescripcion }}"</h4>
        <div class="row"></div>
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="form-inline well well-lg">
                    <button @click="save({})" >Exportar</button>
                    <button @click="agregaEstimacion">Nueva Estimación</button>
                    <div v-if="showInput" style="border: 1px solid black; padding: 10px; width: 400px;">
                        <div style="margin-bottom: 10px;">
                            <p>Nueva estimación</p>
                        </div>
                        <div style="margin-bottom: 10px;">
                            <p>Folio: {{ countEstimaciones + 1 }} </p>
                        </div>
                        <div style="margin-bottom: 10px; display: flex; align-items: center;">
                            <p  style="margin-right: 10px;">Fecha:  </p>                        
                            <input type="date" v-model="selectedDate">
                        </div>
                        <div>
                            <button @click="addColumns" v-if="showInput" :disabled="!selectedDate">Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
        <div class="form-group">
            <label  for="estimaciones" title="MultiSelect">Selecciona los folios a consultar: </label>
            <!-- Botón Filtrar <wj-multi-select id="estimaciones" :itemsSource="estimaciones" :checkedItems="estimaciones" />-->
            <wj-multi-select ref="estimaciones"
                :placeholder="'Estimaciones'"
                :headerFormat="'{count:n0} estimaciones'"
                
                :showSelectAllCheckbox="true"
                :showFilterInput="true"
                :itemsSource="estimaciones"                
                :checkedItemsChanged='onCheckedEstimacionesChanged'></wj-multi-select>
            <!-- Botón Filtrar -->
            <button @click="filterColumns">Filtrar</button>
        </div>
        <div class="input-group">
            <div class="input-group-addon">
                <span class="glyphicon glyphicon-search"></span>
            </div>
            <input @input="filterEtapas2($event)" class="form-control" placeholder="Filtrar etapas">
        </div>
        <div class="input-group">
            <div class="input-group-addon">
                <span class="glyphicon glyphicon-search"></span>
            </div>
            <input @input="filterDPGens2($event)" class="form-control" placeholder="Filtrar DPGen">
        </div>
        <div class="row">
            <!-- the grid -->
            <wj-flex-grid :itemsSource="gridDataEstBase" :initialized="initializeFlexSheet" :formatItem="formatItem" :beginningEdit="beginningEdit" :cellEditEnded="cellEditEnded" :frozenColumns=22 :selectionMode="'RowRange'" >                
                <wj-flex-grid-filter class="custom-filter" :filterApplied="filterApplied" />
                <wj-flex-grid-column binding="idestimacionbase" header="Id" :width="50" :isReadOnly="true" :visible="false" />
                <wj-flex-grid-column binding="concat2" header="Concat2" :width="50" :isReadOnly="true" :visible="false" />
                <wj-flex-grid-column binding="concat11" header="Concat11" :width="50" :isReadOnly="true" :visible="false" style="text-align: left;"/>
                <wj-flex-grid-column binding="orden" header="Orden" :width="40" :isReadOnly="true" :visible="false" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="etapa" header="E" :width="40" :isReadOnly="true" :visible="false" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="actividad" header="Actividad" :width="90" :isReadOnly="true" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }"  cssClass="left-align" />
                <wj-flex-grid-column binding="ni" header="N" :width="40" :isReadOnly="true" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="dpgen" header="DPGen" :width="90" :visible="false" :isReadOnly="true" :isRequired=true  cssClass="left-align" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="partidai" header="Partida" :width="100" :isReadOnly="true" :isRequired=true  cssClass="left-align" :headerStyles="{ height: '50px' }"/>
                <wj-flex-grid-column binding="ut" header="UT" :width="45" :isReadOnly="true" :isRequired=true  cssClass="left-align" wordWrap="true" :headerStyles="{ height: '50px' }"/>
                <wj-flex-grid-column binding="dt" header="DT" :width="150" :isReadOnly="true" :isRequired=true  cssClass="left-align" wordWrap="true" :headerStyles="{ height: '50px' }"/>
                <wj-flex-grid-column binding="cpt" header="CPT" format="n0" :width="70" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }"/>
                <wj-flex-grid-column binding="put" header="P.U.T." format="c0" :width="80" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="msubc" header="$ Subc" format="c0" :width="80" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px', backgroundColor: 'blue' }" />
                <wj-flex-grid-column binding="mmahtaeq" header="$ MHtaE" format="c0" :width="80" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px', backgroundColor: 'blue' }" />
                <wj-flex-grid-column binding="mmo" header="$ MO" format="c0" :width="80" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px', backgroundColor: 'blue' }" />
                <wj-flex-grid-column binding="importe" header="$ Tot" format="c0" :width="80" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px', backgroundColor: 'blue' }" />
                <wj-flex-grid-column binding="msubce" header="$ Subc E" format="p2" :width="75" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="mmahtaeqe" header="$ MHtaE E" format="p2" wordWrap="true" :width="80" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="mmoe" header="$ MO E" format="p2" :width="75" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />                
                <wj-flex-grid-column binding="estacum" header="Est Acum" format="c0" :width="80" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />                    
                <wj-flex-grid-column binding="estacume" header="% Est Acum" format="p2" :width="75" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />            
            </wj-flex-grid>
        </div>
    </div>
</template>

<script>
 
    import 'bootstrap/dist/css/bootstrap.css';
    import '@grapecity/wijmo.styles/wijmo.css';
    import * as XLSX from 'xlsx';

    import Vue, { isReadonly } from 'vue';
    //import { getActividadesProyecto } from '../../components/presupuesto/data';
    import '@grapecity/wijmo.vue2.grid';
    //import * as wjcCore from '@grapecity/wijmo';
    import * as wjcGrid from '@grapecity/wijmo.grid';
    import * as wjcCore from '@grapecity/wijmo';
    import * as wjcXlsx from '@grapecity/wijmo.xlsx';
    import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
    import "@grapecity/wijmo.vue2.grid.filter";
    import "@grapecity/wijmo.vue2.grid.search";
    import * as gridPdf from '@grapecity/wijmo.grid.pdf';
    import { CollectionView } from '@grapecity/wijmo';
    import { FlexGrid } from '@grapecity/wijmo.grid';
    import '@grapecity/wijmo.vue2.input';

    export default {
    data() {
      return {
            selectedProject: null,
            tempgridDataBase: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            gridDataEstBase: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            gridDataEst: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            gridDataEstDet: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            estimaciones: [],
            checkedEstimaciones: [], // inicialmente no hay items seleccionados
            uncheckedEstimaciones: [], // inicialmente no hay items no seleccionados
            etapas: [],
            checkedEtapas: [], // inicialmente no hay items seleccionados
            uncheckedEtapas: [], // inicialmente no hay items no seleccionados            
            includeColumnHeader: true,
            customContent: false,
            columns: [],
            countEstimaciones: 0,
            customColumnFooter: true,
            showInput: false,
            selectedDate: null,
            total: null,
            subtotal: null,
            showFilterInput: true,
            showSelectAllCheckbox: true,
            filterEtapa: '',
            filterDPGen: '',
            //ch: null,
        };
    },

    computed: {
      projectId() {
        return this.$store.getters.getProjectId;
      },
      projectDescripcion() {
        return this.$store.getters.getProjectDescription;
      },
      allOptions() {
        return ['Seleccionar todas', 'Deseleccionar todas', ...this.estimaciones];
      },
      getUnselectedItems() {        
        return this.estimaciones.filter(item => !this.checkedEstimaciones.includes(item));
      },      
      getUnselectedEtapas() {        
        return this.etapas.filter(item => !this.checkedEtapas.includes(item));
      }      
    },

    watch: {
        selectedProject(newValue, oldValue) {
          // Tu lógica aquí
        },
    },      
        async mounted() {
            try {
                const customStyle = document.createElement('style');
                customStyle.innerHTML = `
                    .wj-cell.wj-alt {
                    height: auto !important;
                    /* Otros estilos si es necesario */
                    }
                `;
                document.head.appendChild(customStyle);
                try {
                    //await this.fetchProjects(); // Llama a la función para obtener la lista de proyectos
                    await this.fetchDataEstBase();
                } catch (error) {
                    console.error('Error al obtener los proyectos: ', error);
                }

                //let theSearch = Control.getControl(this.$refs.theSearch.$el);
                //theSearch.grid = theGrid;
            } catch (error) {
                console.error('Error al obtener los datos de la tabla: ', error);
            }   
            //console.log(this.data);                 
        },

        methods: {

            async created() {
                try {
                    if (this.projectId) {
                        this.selectedProject = this.projectId
                    }
                    /*else {
                        await this.fetchProjectsWithCache();        
                    }*/
                } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                }                    
            },           
            initializeFlexSheet: function(flex) {
                this.flex = flex;

                this.flex.cellEditEnding.addHandler((s, e) => {
                    let col = s.columns[e.col];
                });

                // Add an event listener for the cellClick event
                /*this.flex.addEventListener(this.flex.hostElement, 'click', (e) => {
                    // Get the clicked cell
                    let ht = this.flex.hitTest(e);

                    // Check if a column header was clicked
                    if (ht.panel == this.flex.columnHeaders) {
                        // Get the clicked column
                        let column = this.flex.columns[ht.col];

                        // Check if the column is numeric
                        if (column.dataType === wijmo.DataType.Number) {
                            // Calculate the column total
                            this.calculateColumnTotal(column.binding);
                        }
                    }
                });*/
                this.flex.rowHeaders.columns.splice(0, 1); // no extra columns
            },

            /*async fetchProjectsWithCache() {
                try {        
                const cacheKey = 'projectsCache';
                if (apiCache[cacheKey]) {
                    this.projects = apiCache[cacheKey];
                    console.log('Se cargo desde cache');
                } else {
                    try {
                    const response = await fetch('http://157.245.220.201:3002/api/proyectos');
                    console.log("2. fetchProjects: pasa por aquí");
                    if (response.ok) {
                        const data = await response.json();
                        this.projects = data.body;
                        apiCache[cacheKey] = data.body; // Almacenar en caché
                        console.log('Se cargo desde api.');
                    } else {
                        console.log('No se pudo cargar la lista de proyectos');
                    }
                    } catch (error) {
                    console.error('Error al cargar la lista de proyectos: ', error);
                    }
                }
                } catch (error) {
                console.error('Error al obtener el proyecto:', error);
                }
            },*/
            async fetchDataEstBase() {
              try {
                    this.selectedProject = this.$store.getters.getProjectId;
                    if (!this.selectedProject) {
                        return;
                    }

                    try {
                        //this.selectedProject = 17;
                        // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                        const idcliente = 3; // Establece el ID del cliente
                        console.log("selectedProject: " + this.selectedProject);
                        const idproyecto = this.selectedProject; // Utiliza el proyecto seleccionado
                        const url = `http://157.245.220.201:3002/api/estimacionesbase/getby/?cliente=${idcliente}&proyecto=${idproyecto}`;

                        const response = await fetch(url, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });

                        const responseData = await response.json();
                        this.tempgridDataBase = responseData.body;
                        
                        console.log(this.tempgridDataBase);
                        //console.log(this.gridDataEstBase);

                        this.gridDataEstBase = this.tempgridDataBase;
                        console.log(this.gridDataEstBase);

                        //obtener de gridDataEstBase la lista de etapas y agregarlas al arreglo global etapas
                        this.etapas = this.gridDataEstBase.map(item => item.etapa);
                        //no permitir duplicados en el arreglo global etapas
                        this.etapas = [...new Set(this.etapas)];
                        //refactorizar el arreglo global etapas
                        this.etapas = this.etapas.sort((a, b) => a - b);
                        console.log(this.etapas);

                        this.checkedEtapas = this.etapas;
                        
                        //this.addColumnsEstimaciones();

                        // Insertar fila de encabezado de columna adicional
                        let ch = this.flex.columnHeaders;

                        // Create new rows for each insertion
                        let hr1 = new wjcGrid.Row();
                        let hr2 = new wjcGrid.Row();
                        let hr3 = new wjcGrid.Row();

                        ch.rows.insert(1, hr1);
                        ch.rows.insert(2, hr2);
                        ch.rows.insert(3, hr3);
                        console.log("ch.rows");
                        console.log(ch.rows);

                        // Completar los encabezados en la fila de encabezado adicional
                        for (let i = 10; i < this.flex.columns.length; i++) {
                        //for (let i = 9; i < 12; i++) {
                            //obtener el binding de la columna
                            let columna = this.flex.columns[i];
                            let hdr = ch.getCellData(3, i, false);
                            if (hdr == "CPT") {
                                hdr = "Presupuesto";
                            }
                            if (hdr == "P.U.T.") {
                                hdr = "Presupuesto";
                            }
                            if (hdr == "$ Subc") {
                                hdr = "Presupuesto";
                            }
                            if (hdr == "$ MHtaE") {
                                hdr = "Presupuesto";
                            }
                            if (hdr == "$ MO") {
                                hdr = "Presupuesto";
                            }
                            if (hdr == "$ Tot") {
                                hdr = "Presupuesto";
                            }
                            if (hdr == "$ Subc E") {
                                hdr = "Avance";
                            }
                            if (hdr == "$ MHtaE E") {
                                hdr = "Avance";
                            }
                            if (hdr == "$ MO E") {
                                hdr = "Avance";
                            }
                            if (hdr == "Est Acum") {
                                hdr = "Avance";
                            }
                            if (hdr == "% Est Acum") {
                                hdr = "Avance";
                            }
                            ch.setCellData(2, i, hdr);
                            // centrar el encabezado de la columna
                            console.log("centrar el encabezado de la columna");
                            //columna.headerCellStyle = { 'text-align': 'center' };
                            //imprime en consola valor de celda
                            console.log(hdr);
                        }

                        // Permitir la fusión a través y hacia abajo de la fila de encabezado adicional
                        this.flex.allowMerging = wjcGrid.AllowMerging.ColumnHeaders;
                        //hr1.allowMerging = true;
                        hr2.allowMerging = true;
                        hr3.allowMerging = true;
                        this.flex.columns[0].allowMerging = true;
                        this.flex.columns[1].allowMerging = true;
                        this.flex.columns[2].allowMerging = true;
                        this.flex.columns[3].allowMerging = true;

                        // Add columns from gridDataEstBase starting from column 22
                        for (let i = 0; i < this.gridDataEstBase.length; i++) {
                            let rowData = this.gridDataEstBase[i];
                            let conteo = 0;
                            Object.keys(rowData).forEach((key, index) => {
                                if (index >= 23) {                                    
                                    //agregar 4 columnas por cada estimacion
                                    if (!this.flex.columns[index]) {
                                        
                                        if (conteo == 0) {
                                            this.flex.columns.push(new wjcGrid.Column({ binding: key, header: '%Subc', format: 'p2' }));
                                            this.flex.setCellData(i, index, rowData[key]);
                                            conteo +=1;
                                        } else if (conteo == 1) {
                                            this.flex.columns.push(new wjcGrid.Column({ binding: key, header: '%MHtaE', format: 'p2' }));
                                            this.flex.setCellData(i, index + conteo, rowData[key]);
                                            conteo +=1;
                                        } else if (conteo == 2) {
                                            this.flex.columns.push(new wjcGrid.Column({ binding: key, header: '%MO', format: 'p2' }));
                                            this.flex.setCellData(i, index + conteo, rowData[key]);
                                            conteo +=1;
                                        } else if (conteo == 3) {
                                            this.flex.columns.push(new wjcGrid.Column({ binding: key, header: '$Est', format: 'c0' }));
                                            this.flex.setCellData(i, index + conteo, rowData[key]);
                                            conteo =0;
                                        }
                                        
                                        //this.flex.columns.push(new wjcGrid.Column({ binding: key, header: key, format: 'p2' }));
                                        //this.flex.columns.push(new wjcGrid.Column({ binding: key, header: key, format: 'p2' }));
                                        //this.flex.columns.push(new wjcGrid.Column({ binding: key, header: key, format: 'c0' }));
                                    }
                                    
                                    //this.flex.setCellData(i, index + 1, rowData[key]);
                                    //this.flex.setCellData(i, index + 2, rowData[key]);
                                    //this.flex.setCellData(i, index + 3, rowData[key]);
                                    //index += 3;
                                }
                            });
                        }

                        //, width: 75, isReadOnly: true, isRequired: false, format: p2 
                        //const bindings = ['subc-e', 'mhtae-e', 'mmo-e', 'est-e'];
                        //const headers = ['%Subc', '%MHtaE', '%MO', '$Est'];
                        //const widths = [75, 75, 75, 80]; 
                        //const readonlys = [false, false, false, true]; 
                        //const formatos = ['p2', 'p2', 'p2', 'c0'];
                        //binding: `${bindings[i]}${this.countEstimaciones}`,
                        //header: `${headers[i]}`,
                        //width: widths[i],
                        //isReadOnly: readonlys[i],
                        //isRequired: false,
                        //format: formatos[i],


                        //cambiar formato de columnas
                        //this.flex.forEach
                        // Llamar a fetchDataEstDet después de que fetchDataEstBase haya terminado
                        await this.fetchDataEstFolio(); // Espera a que fetchDataEstDet termine antes de continuar

                        // Llamar a fetchDataEstDet después de que fetchDataEstBase haya terminado
                        await this.fetchDataEstDet(); // Espera a que fetchDataEstDet termine antes de continuar

                        //let fh = this.flex.columnFooters,
                        //fr = new wjcGrid.Row();
                        //fh.rows.insert(0, fr);
                        //fh.rows.insert(1, fr);
                        
                    } catch (error) {
                        console.error('Error al obtener los datos de la tabla: ', error);
                    }
                } catch (error) {
                    console.error('Error en fetchDataEstBase:', error);
                }        
            },

            async fetchDataEstFolio() {
                try {
                    //this.selectedProject = 17;
                    // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                    const idcliente = 3; // Establece el ID del cliente
                    const idproyecto = this.selectedProject; // Utiliza el proyecto seleccionado
                    const url = `http://157.245.220.201:3002/api/estimaciones/getlast/?cliente=${idcliente}&proyecto=${idproyecto}`;

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const responseData = await response.json();
                    this.tempgridDataFolio = responseData.body;
                    
                    // Check if the response body is not empty
                    if (this.tempgridDataFolio && this.tempgridDataFolio.length > 0) {
                        // Get the folio of the first record
                        this.countEstimaciones = Number(this.tempgridDataFolio[0].folio_maximo);
                    } else{
                        this.countEstimaciones = Number(0);
                    }
                    console.log("folio: " + this.countEstimaciones);
                } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                }
            },

            async fetchDataEstDet() {
                try {
                    console.log("Obtener datos de la tabla de estimacionesdet");
                    //this.selectedProject = 17;
                    // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                    const idcliente = 3; // Establece el ID del cliente
                    const idproyecto = this.selectedProject; // Utiliza el proyecto seleccionado
                    const url = `http://157.245.220.201:3002/api/estimacionesdet/getall?cliente=${idcliente}&proyecto=${idproyecto}`;

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const responseData = await response.json();
                    this.tempgridDataDet = responseData.body;
                    this.gridDataEstDet = this.tempgridDataDet;
                    console.log(this.tempgridDataDet);
                    console.log(this.gridDataEstDet);

                    this.addColumnsEstimaciones();
                    
                } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                }
            },


            filter: function(e) {
                let filter = e.target.value.toLowerCase();
                this.flex.collectionView.filter = item => {
                    return (
                        filter.length == 0 ||
                        item.frente.toLowerCase().indexOf(filter) > -1
                    );
                };
            },            
            
            load: function() {
                let fileInput = document.getElementById('importFile');
                console.log(fileInput);
                if (fileInput.files[0]) {
                    this.customContent = false;

                    // Create a FileReader to read the file
                    let reader = new FileReader();

                    reader.onload = (e) => {
                        // Once the file is loaded, this function is executed
                        let data = new Uint8Array(e.target.result);
                        let workbook = XLSX.read(data, {type: 'array'});

                        // Get the first worksheet
                        let worksheet = workbook.Sheets[workbook.SheetNames[0]];

                        // Convert the worksheet to JSON
                        let tempList = XLSX.utils.sheet_to_json(worksheet, {header: 1});

                        console.log("datos cargados");
                        console.log(tempList);

                        // Iterate over the items in tempList
                        for (let i = 0; i < tempList.length; i++) {
                            let item = tempList[i];
                            console.log(item[4]);
                            // Find the corresponding items in this.flex
                            let existingItems = this.flex.itemsSource.filter(x => x.partida === item[4]);
                            console.log(existingItems);
                            existingItems.forEach(existingItem => {                                
                                // If the item exists, update it etapa, secprog, nombreprog
                                if (isNaN(item[6])) {
                                } else {
                                    existingItem.etapa = item[6];
                                }
                                //validar que secprog sea numérico	
                                if (isNaN(item[7])) {
                                } else {
                                    existingItem.secprog = item[7];
                                    existingItem.actividad = item[7] + ' ' +  existingItem.nombreprog;
                                }
                                existingItem.nombreprog = item[8];
                                existingItem.actividad = existingItem.secprog + ' ' + item[8];
                                console.log("actualizar el registro" + existingItem.actividad);

                                //enviar el registro actualizado a la api
                                this.updateActividad(existingItem);
                            });
                        }

                        // Refresh the FlexGrid to reflect the changes
                        this.flex.refresh();

                    };

                    // Start reading the file
                    reader.readAsArrayBuffer(fileInput.files[0]);
                }
            },

            customContentClick: function(e) {
                this.flex.invalidate();
            },

            formatItem: function(grid, e) {
                let panel = e.panel,
                    r = e.row,
                    c = e.col,
                    cell = e.cell;

                    console.log("1.1. formatItem, entra if");
                if (e.panel == this.flex.cells) {
                    let binding = this.flex.columns[c].binding;
                    if (binding === 'estacume' || binding === 'msubce' || binding === 'mmahtaeqe' || binding === 'mmoe') {
                        let value = e.panel.getCellData(e.row, e.col, false);
                        wjcCore.toggleClass(e.cell, 'cero-value', wjcCore.isNumber(value) && value === 0);
                        wjcCore.toggleClass(e.cell, 'entre-value', wjcCore.isNumber(value) && value > 0 && value < 1);
                        wjcCore.toggleClass(e.cell, 'uno-value', wjcCore.isNumber(value) && value === 1);
                        wjcCore.toggleClass(e.cell, 'mayor-value', wjcCore.isNumber(value) && value > 1);
                        //wjcCore.toggleClass(e.cell, 'low-value', wjcCore.isNumber(value) && value < 00);
                    }
                    if (binding.startsWith('subc-e') || binding.startsWith('mhtae-e') || binding.startsWith('mmo-e')) {
                        let value = e.panel.getCellData(e.row, e.col, false);
                        wjcCore.toggleClass(e.cell, 'cero-value', wjcCore.isNumber(value) && value === 0);
                        wjcCore.toggleClass(e.cell, 'entre-value', wjcCore.isNumber(value) && value > 0 && value < 1);
                        wjcCore.toggleClass(e.cell, 'uno-value', wjcCore.isNumber(value) && value === 1);
                        wjcCore.toggleClass(e.cell, 'mayor-value', wjcCore.isNumber(value) && value > 1);
                        //wjcCore.toggleClass(e.cell, 'low-value', wjcCore.isNumber(value) && value < 00);
                    }
                }
                if (e.panel == this.flex.columnHeaders) {
                    console.log("1.2. formatItem, entra if");
                    let binding = this.flex.columns[c].binding;
                    // Obtener el encabezado de la columna
                    let header = this.flex.columns[c].header;
                    console.log(header);
                    if (binding === 'cpt' || binding === 'put' || binding === 'msubc' || binding === 'mmahtaeq' || binding === 'mmo' || binding === 'importe') {
                        //cambiar el color de fondo de la celda
                        e.cell.style.backgroundColor = "#f8de7e";
                    }
                    if (binding === 'estacume' || binding === 'msubce' || binding === 'mmahtaeqe' || binding === 'mmoe' || binding === 'estacum') {
                        //cambiar el color de fondo de la celda
                        e.cell.style.backgroundColor = "#c6da52";
                    }                    
                    if (binding.startsWith('subc-e') || binding.startsWith('mhtae-e') || binding.startsWith('mmo-e') || binding.startsWith('est-e')) {                        
                        console.log("1.3. formatItem, entra if");
                        // Extraer el número del nombre de la columna
                        let parts = binding.split('-e');
                        console.log(parts);
                        let number = parts.length > 1 && !isNaN(parts[1]) ? parseInt(parts[1]) : null;
                        console.log(number);
                        // Verificar si el número es par
                        if (number % 2 === 0) {
                            console.log("1.4. formatItem, entra if");
                            console.log(number);
                            // Verificar si la columna es visible
                            if (this.flex.columns[c].visible) {
                                console.log("1.5. formatItem, entra if");
                                //cambiar el color de fondo de la celda
                                e.cell.style.backgroundColor = "#cee2fb";
                            } else {
                                console.log("1.6. formatItem, entra if");
                                //cambiar el color de fondo de la celda
                                e.cell.style.backgroundColor = "#c4dafa";
                            }
                        }
                    }
                    //si el encabezado tiene la palabra "Estimación", "Presupuesto" o "Avance" centrar el texto
                    if (header.startsWith('Estimación') || header.startsWith('Presupues') || header.startsWith('Avanc')) {
                        console.log("1.7. formatItem, entra if");
                        // centrar el encabezado de la columna
                        e.cell.style.textAlign = 'center';
                    }
                }

                if (panel.cellType === wjcGrid.CellType.Cell) {
                    let binding = this.flex.columns[c].binding,
                        row = this.flex.rows[r];

                    if (row instanceof wjcGrid.GroupRow) {
                        if (row.level <= 2) {
                            if (binding === 'active') {
                                cell.innerHTML = this.customContent
                                    ? 'Amount/Pending: ' + Math.round(grid.getCellData(r, 'amount', false)/ grid.getCellData(r, 'amount2', false) * 100)/ 100
                                    : '';
                            }
                        }
                    } else {
                        if (binding === 'color') {
                            cell.style.color = this.customContent ? grid.getCellData(r, c, true) : '';
                        //} else {
                         //       cell.style.backgroundColor = this.customContent ? 'orange' : '';
                        }
                    }

                    //console.log("1.1. formatItem, entra if");
                    // Verificar si la celda actual es de la columna "importe"
                    /*if (binding === "importe")
                    {
                        // Convertir el valor de la celda a decimal
                        let value = Number(this.flex.getCellData(e.row, "importe", true));
                        console.log(value);

                        // Aplicar formato de moneda
                        let formattedValue = parseFloat(value).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
                        console.log(formattedValue);

                        // Asignar el valor formateado a la celda
                        this.flex.setCellData(e.row, "importe", formattedValue);
                    }*/
                }


            },

            save() {
                wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
                    this.flex,
                    {
                        includeColumnHeaders: this.includeColumnHeader,
                        includeStyles: false,
                        formatItem: this.customContent
                            ? this.exportFormatItem
                            : null
                    },
                    'EstimacionesporActividad.xlsx'
                );
            },

            exportFormatItem: function(args) {
                var p = args.panel,
                    row = args.row,
                    col = args.col,
                    xlsxCell = args.xlsxCell;

                if (p.cellType === wjcGrid.CellType.Cell) {
                    if (p.columns[col].binding === 'color') {
                        if (xlsxCell.value) {
                            if (!xlsxCell.style.font) {
                                xlsxCell.style.font = {};
                            }
                            xlsxCell.style.font.color = xlsxCell.value.toLowerCase();
                        }
                    } else if (p.columns[col].binding === 'active' && p.rows[row] instanceof wjcGrid.GroupRow) {
                        let cell = args.getFormattedCell();
                        xlsxCell.value = cell.textContent.trim();
                        xlsxCell.style.hAlign = wjcXlsx.HAlign.Left;
                    }
                }
            },


            agregaTotalesSubtotalesFijos() {
                try {

                            //obtener el indice de la variable col
                            let indicecol = this.flex.columns.findIndex(column => column.binding === 'put');
                            console.log(indicecol);
                            let hdrSubt = `Subtotal`;
                            console.log(hdrSubt);
                            this.flex.columnHeaders.setCellData(1, indicecol, hdrSubt);
                            let hdrTot = `Total`;
                            console.log(hdrTot);
                            this.flex.columnHeaders.setCellData(0, indicecol, hdrTot);
                        
                            this.calculateColumnTotal('msubc');
                            
                            this.calculateColumnTotal('mmahtaeq');
                        
                            this.calculateColumnTotal('mmo');
                            
                            this.calculateColumnTotal('importe');

                            this.calculateColumnTotal('estacum');

                            this.calculateColumnTotalEstacume('estacume');
                    
                } catch (error) {
                    console.error('Error al generar totales y subtotales iniciales: ', error);
                }
            },


            //gridDataEstBase.slice(9)
            addColumnsEstimaciones() {

                this.agregaTotalesSubtotalesFijos();
                // validar si gridDataEstDet esta vacio
                if (this.gridDataEstDet.length === 0) {
                    return;
                }

                // Verificar los datos antes de ordenar
                console.log("Datos antes de ordenar:", this.gridDataEstDet);

                this.gridDataEstDet.sort((a, b) => Number(a.folio) - Number(b.folio));

                // Verificar los datos después de ordenar
                console.log("Datos después de ordenar:", this.gridDataEstDet);

                //Generamos la primer columna de la primera estimación
                let prevFolio = this.gridDataEstDet[0].folio;
                let descripcion = this.gridDataEstDet[0].descripcion;
                let columnaActual = 22;

                // Asegúrate de que this.flex y this.flex.columnHeaders existen
                if (!this.flex || !this.flex.columnHeaders) {
                    console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                    return;
                }

                let columnCount = this.flex.columnHeaders.columns.length;
                console.log(`FlexGrid tiene ${columnCount} columnas`);

                for (let i = 0; i < 4; i++) {                    

                    //let hdr = `Estimación-${this.gridDataEstDet[0].folio}`;
                    let hdr = this.gridDataEstDet[0].descripcion;
                    this.flex.columnHeaders.setCellData(2, columnaActual, hdr);

                    //agregar el número de estimación al arreglo de global estimaciones
                    this.estimaciones.push(this.gridDataEstDet[0].folio);
                    //no permitir duplicados en el arreglo global estimaciones
                    this.estimaciones = [...new Set(this.estimaciones)];
                    //refactorizar el arreglo global estimaciones
                    this.estimaciones = this.estimaciones.sort((a, b) => a - b);
                    console.log(this.estimaciones);                    

                    columnaActual++; 

                    // Asegúrate de que this.flex y this.flex.columnHeaders existen
                    if (this.flex && this.flex.columnHeaders) {
                        // Obtén el número de columnas
                        let columnCount = this.flex.columnHeaders.columns.length;
                        console.log(`FlexGrid tiene ${columnCount} columnas`);
                    }  else {
                        console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                    }
                }                
                //Generamos las columnas de las siguientes estimaciones
                for (let j = 0; j < this.gridDataEstDet.length; j++) {
                    console.log('idestimacionbase: ' + this.gridDataEstDet[j].idestimacionbase);
                    if (this.gridDataEstDet[j].folio !== prevFolio) {                    
                        for (let i = 0; i < 4; i++) {
                            //let hdr = `Estimación-${this.gridDataEstDet[j].folio}`;
                            let hdr = this.gridDataEstDet[j].descripcion;
                            this.flex.columnHeaders.setCellData(2, columnaActual, hdr);

                            //agregar el número de estimación al arreglo de global estimaciones
                            this.estimaciones.push(this.gridDataEstDet[j].folio);
                            //no permitir duplicados en el arreglo global estimaciones
                            this.estimaciones = [...new Set(this.estimaciones)];
                            console.log("Arreglo de estimaciones:");
                            console.log(this.estimaciones);
                            //refactorizar el arreglo global estimaciones
                            this.estimaciones = this.estimaciones.sort((a, b) => a - b);
                            console.log(this.estimaciones);                    

                            columnaActual++;
                            // Asegúrate de que this.flex y this.flex.columnHeaders existen
                            if (this.flex && this.flex.columnHeaders) {
                                // Obtén el número de columnas
                                let columnCount = this.flex.columnHeaders.columns.length;
                                console.log(`FlexGrid tiene ${columnCount} columnas`);
                            }  else {
                                console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                            }
                        }
                    }
                    prevFolio = this.gridDataEstDet[j].folio;
                }

                this.checkedEstimaciones = this.estimaciones;
                                
                this.flex.columns.forEach(column => {
                    if (column.binding.startsWith('est-e')) {
                        console.log("1.4. celleditended, coincide encabezado");
                        this.calculateColumnTotal(column.binding);
                    }
                });

                //calcular acumulados al terminar la carga inicial de estimaciones
                this.calculateColumnTotalEstacume('estacume');
            },
            
            //gridDataEstBase.slice(9)
            addColumnsEstimaciones_respaldo() {

                this.agregaTotalesSubtotalesFijos();
                // validar si gridDataEstDet esta vacio
                if (this.gridDataEstDet.length === 0) {
                    return;
                }
                const bindings = ['subc-e', 'mhtae-e', 'mmo-e', 'est-e'];
                const headers = ['%Subc', '%MHtaE', '%MO', '$Est'];
                const widths = [75, 75, 75, 80]; 
                const readonlys = [false, false, false, true]; 
                const formatos = ['p2', 'p2', 'p2', 'c0'];

                console.log("agregar columnas de estimaciones");

                this.gridDataEstDet.sort((a, b) => Number(a.folio) - Number(b.folio));

                console.log(this.gridDataEstDet);
                //Generamos la primer columna de la primera estimación
                let prevFolio = this.gridDataEstDet[0].folio;
                let descripcion = this.gridDataEstDet[0].descripcion;
                let columnaActual = 22;

                // Asegúrate de que this.flex y this.flex.columnHeaders existen
                if (!this.flex || !this.flex.columnHeaders) {
                    console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                    return;
                }

                let columnCount = this.flex.columnHeaders.columns.length;
                console.log(`FlexGrid tiene ${columnCount} columnas`);

                for (let i = 0; i < 4; i++) {                    

                    // Insertar columna adicional en flexgrid
                    this.flex.columns.insert(columnaActual, new wjcGrid.Column({
                        binding: `${bindings[i]}${this.gridDataEstDet[0].folio}`,
                        header: `${headers[i]}`,
                        width: widths[i],
                        isReadOnly: readonlys[i],
                        isRequired: false,
                        format: formatos[i],
                        //style: 'text-align: left',
                    }));

                    //let hdr = `Estimación-${this.gridDataEstDet[0].folio}`;
                    let hdr = this.gridDataEstDet[0].descripcion;
                    this.flex.columnHeaders.setCellData(2, columnaActual, hdr);

                    //agregar el número de estimación al arreglo de global estimaciones
                    this.estimaciones.push(this.gridDataEstDet[0].folio);
                    //no permitir duplicados en el arreglo global estimaciones
                    this.estimaciones = [...new Set(this.estimaciones)];
                    //refactorizar el arreglo global estimaciones
                    this.estimaciones = this.estimaciones.sort((a, b) => a - b);
                    console.log(this.estimaciones);                    

                    columnaActual++; 

                    // Asegúrate de que this.flex y this.flex.columnHeaders existen
                    if (this.flex && this.flex.columnHeaders) {
                        // Obtén el número de columnas
                        let columnCount = this.flex.columnHeaders.columns.length;
                        console.log(`FlexGrid tiene ${columnCount} columnas`);
                    }  else {
                        console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                    }
                }

                for (let i = 0; i < 4; i++) {                    

                    // Insertar columna adicional en flexgrid
                    this.flex.columns.insert(columnaActual, new wjcGrid.Column({
                        binding: `${bindings[i]}${this.gridDataEstDet[0].folio}`,
                        header: `${headers[i]}`,
                        width: widths[i],
                        isReadOnly: readonlys[i],
                        isRequired: false,
                        format: formatos[i],
                        //style: 'text-align: left',
                    }));

                    //let hdr = `Estimación-${this.gridDataEstDet[0].folio}`;
                    let hdr = this.gridDataEstDet[0].descripcion;
                    this.flex.columnHeaders.setCellData(2, columnaActual, hdr);

                    //agregar el número de estimación al arreglo de global estimaciones
                    this.estimaciones.push(this.gridDataEstDet[0].folio);
                    //no permitir duplicados en el arreglo global estimaciones
                    this.estimaciones = [...new Set(this.estimaciones)];
                    //refactorizar el arreglo global estimaciones
                    this.estimaciones = this.estimaciones.sort((a, b) => a - b);
                    console.log(this.estimaciones);                    

                    columnaActual++; 

                    // Asegúrate de que this.flex y this.flex.columnHeaders existen
                    if (this.flex && this.flex.columnHeaders) {
                        // Obtén el número de columnas
                        let columnCount = this.flex.columnHeaders.columns.length;
                        console.log(`FlexGrid tiene ${columnCount} columnas`);
                    }  else {
                        console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                    }
                }

                // Ordenar this.gridDataEstDet basado en idestimacionbase
                //this.gridDataEstDet.sort((a, b) => a.idestimacionbase - b.idestimacionbase);
                //esta linea no funciona, se come toda la memoria


                //Generamos las columnas de las siguientes estimaciones
                for (let j = 0; j < this.gridDataEstDet.length; j++) {
                    console.log('idestimacionbase: ' + this.gridDataEstDet[j].idestimacionbase);
                    if (this.gridDataEstDet[j].folio !== prevFolio) {                    
                        for (let i = 0; i < 4; i++) {
                            // Insertar columna adicional en flexgrid
                            this.flex.columns.insert(columnaActual, new wjcGrid.Column({
                                binding: `${bindings[i]}${this.gridDataEstDet[j].folio}`,
                                header: `${headers[i]}`,
                                width: widths[i],
                                isReadOnly: readonlys[i],
                                isRequired: false,
                                format: formatos[i],
                                //style: 'text-align: left',
                            }));

                            //let hdr = `Estimación-${this.gridDataEstDet[j].folio}`;
                            let hdr = this.gridDataEstDet[j].descripcion;
                            this.flex.columnHeaders.setCellData(2, columnaActual, hdr);

                            //agregar el número de estimación al arreglo de global estimaciones
                            this.estimaciones.push(this.gridDataEstDet[j].folio);
                            //no permitir duplicados en el arreglo global estimaciones
                            this.estimaciones = [...new Set(this.estimaciones)];
                            console.log("Arreglo de estimaciones:");
                            console.log(this.estimaciones);
                            //refactorizar el arreglo global estimaciones
                            this.estimaciones = this.estimaciones.sort((a, b) => a - b);
                            console.log(this.estimaciones);                    

                            columnaActual++;
                            // Asegúrate de que this.flex y this.flex.columnHeaders existen
                            if (this.flex && this.flex.columnHeaders) {
                                // Obtén el número de columnas
                                let columnCount = this.flex.columnHeaders.columns.length;
                                console.log(`FlexGrid tiene ${columnCount} columnas`);
                            }  else {
                                console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                            }
                        }
                    }
                    prevFolio = this.gridDataEstDet[j].folio;
                }

                //this.$refs.estimaciones = this.estimaciones;

                //this.$refs.estimaciones.checkedItems = this.estimaciones;
                this.checkedEstimaciones = this.estimaciones;
                                
                this.flex.columns.forEach(column => {
                    if (column.binding.startsWith('est-e')) {
                        console.log("1.4. celleditended, coincide encabezado");
                        this.calculateColumnTotal(column.binding);
                    }
                });

                //calcular acumulados al terminar la carga inicial de estimaciones
                //this.calculaAcumuladosInicial("subc-e" + prevFolio);
                //this.calculaAcumuladosInicial("mhtae-e" + prevFolio);
                //this.calculaAcumuladosInicial("mmo-e" + prevFolio);

                this.calculateColumnTotalEstacume('estacume');

                //marcar todas las opciones del arreglo estimaciones
            },

            addColumns() {
                this.showInput = !this.showInput;
                this.countEstimaciones++;
                const bindings = ['subc-e', 'mhtae-e', 'mmo-e', 'est-e'];//['subc-e', 'mhtae-e', 'mmo-e', 'est-e'];
                const headers = ['%Subc', '%MHtaE', '%MO', '$Est'];
                const widths = [75, 75, 75, 80]; 
                const readonlys = [false, false, false, false]; 
                const formatos = ['p2', 'p2', 'p2', 'c0'];
                let columnCount = this.flex.columnHeaders.columns.length;
                console.log(`FlexGrid tiene ${columnCount} columnas`);
                for (let i = 0; i < 4; i++) {
                    /*this.columns.push({
                        binding: `${bindings[i]}${this.countEstimaciones}`,
                        header: `${headers[i]}${this.countEstimaciones}`,
                        width: widths[i],
                        isReadOnly: readonlys[i],
                        isRequired: false,
                        style: 'text-align: left;',
                        defaultBinding: Number(0)
                    });*/

                    // Insertar columna adicional en flexgrid

                    this.flex.columns.insert(columnCount, new wjcGrid.Column({
                        binding: `${bindings[i]}${this.countEstimaciones}`,
                        header: `${headers[i]}`,
                        width: widths[i],
                        isReadOnly: readonlys[i],
                        isRequired: false,
                        format: formatos[i],
                        //style: 'text-align: left',
                    }));

                    //let hdr = `Estimación-${this.countEstimaciones}`;
                    let hdr = `Estimación-${this.countEstimaciones} | ${this.selectedDate}`;
                    console.log(hdr);
                    //let columnCount = this.flex.columnHeaders.columns.length;
                    this.flex.columnHeaders.setCellData(2, columnCount, hdr);
                    columnCount++;
                    
                }

                //agregar valor cero para todas las filas actuales de flexgrid en las columnas agregadas
                this.flex.rows.forEach(row => {
                    this.flex.columns.forEach(column => {
                        if (column.binding === "subc-e" + this.countEstimaciones || column.binding === "mhtae-e" + this.countEstimaciones || column.binding === "mmo-e" + this.countEstimaciones || column.binding === "est-e" + this.countEstimaciones) {
                            row.dataItem[column.binding] = 0;
                        }
                    });
                });


                //agregar api para guardar la estimación
                this.agregarestimacion({ idcliente: 3, idproyecto: this.selectedProject, fecha: this.selectedDate, folio: this.countEstimaciones, usuario: 3 });

            },
            /*addColumns() {
                this.showInput = !this.showInput;
                this.countEstimaciones++;
                const bindings = ['subc-e', 'mhtae-e', 'mmo-e', 'est-e'];
                const headers = ['%Subc-E', '%MHtaE-E', '%MO-E', '$Est-'];
                const widths = [95, 95, 95, 105]; 
                const readonlys = [false, false, false, true]; 
                for (let i = 0; i < 4; i++) {
                    let column = new wijmo.grid.Column({
                        binding: `${bindings[i]}${this.countEstimaciones}`,
                        header: `${headers[i]}${this.countEstimaciones}`,
                        width: widths[i],
                        isReadOnly: readonlys[i],
                        isRequired: false,
                        style: 'text-align: left;',
                        defaultBinding: Number(0)
                    });

                    // Add the column to the FlexGrid
                    this.flex.columns.push(column);

                    // Add a click event listener to the column
                    column.addEventListener(column.hostElement, 'click', () => {
                        this.calculateColumnTotal(column.binding);
                    });
                }

                // Add the estimation to the API
                agregarestimacion({ idcliente: 3, idproyecto: 15, fecha: this.selectedDate, folio: this.countEstimaciones, usuario: 3 });
            },*/

            async agregarestimacion({ idcliente, idproyecto, fecha, folio, usuario }) {
                try {
                    console.log("crea la estimación y agrega el detalle");                
                    // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                    const url = `http://157.245.220.201:3002/api/estimacionesdet/getbase/?cliente=${idcliente}&proyecto=${idproyecto}&folio=${folio}&fecha=${fecha}&usuario=${usuario}`;

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const responseData = await response.json();
                    this.tempgridData = responseData.body;
                    this.gridDataEst = this.tempgridData;
                    console.log(this.tempgridData);
                    console.log(this.gridDataEst);
                } catch (error) {
                    console.error('Error al obtener los datos de la estimación: ', error);
                }
            },

            agregaEstimacion() {
                this.showInput = !this.showInput;               
            },

            filterApplied(s, e) {
                console.log('Filtro aplicado');
                //this.agregaTotalesSubtotalesFijos();
                //agregar valor cero para todas las filas actuales de flexgrid en las columnas agregadas                
                //this.flex.rows.forEach(row => {
                    //console.log("1.1. filterApplied, entra a for"); estacum
                    this.flex.columns.forEach(column => {
                        if (column.binding === "msubc" || column.binding === "mmahtaeq" || column.binding === "mmo" || column.binding === "importe" || column.binding === "estacum" || column.binding.startsWith('est-e')) {
                            console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                            this.calculateColumnTotal(column.binding);
                        }
                        if (column.binding === "estacume") {
                            console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                            this.calculateColumnTotalEstacume(column.binding);
                        }

                    });
                //});

            },

            beginningEdit(s, e) {
                // Obtiene la columna y fila actual.
                const column = s.columns[e.col].binding;
                const row = s.rows[e.row].dataItem;

                console.log("1. beginningEdit, entra if: " + column);
                
                if (column.startsWith('subc-e') && parseFloat(row['msubc']) <= 0) {
                    // Verifica si la columna comienza con 'subce-e' y si 'msubc' es un decimal mayor a cero.
                    // Cancela la edición si la validación falla.Number(row.dataItem[column.binding])
                    //console.log("entro a if: "+ column + " " + parseFloat(row['msubc']) + " " + parseFloat(row['mmahtaeq'])+ " " + parseFloat(row['mmo']));
                    e.cancel = true;

                    // Opcional: muestra un mensaje de error.
                    alert('No es posible editar esta celda debido, no hay presupuesto para esta actividad en $ Subc' );
                
                } else if (column.startsWith('mhtae-e') && parseFloat(row['mmahtaeq']) <= 0) {
                    // Verifica si la columna comienza con 'subce-e' y si 'msubc' es un decimal mayor a cero.
                    // Cancela la edición si la validación falla.Number(row.dataItem[column.binding])
                    //console.log("entro a if: "+ column + " " + parseFloat(row['msubc']) + " " + parseFloat(row['mmahtaeq'])+ " " + parseFloat(row['mmo']));
                    e.cancel = true;

                    // Opcional: muestra un mensaje de error.
                    alert('No es posible editar esta celda debido, no hay presupuesto para esta actividad en $ MHtaE' );
                
                } else if (column.startsWith('mmo-e') && parseFloat(row['mmo']) <= 0) {
                    // Verifica si la columna comienza con 'subce-e' y si 'msubc' es un decimal mayor a cero.
                    // Cancela la edición si la validación falla.Number(row.dataItem[column.binding])
                    //console.log("entro a if: "+ column + " " + parseFloat(row['msubc']) + " " + parseFloat(row['mmahtaeq'])+ " " + parseFloat(row['mmo']));
                    e.cancel = true;

                    // Opcional: muestra un mensaje de error.
                    alert('No es posible editar esta celda debido, no hay presupuesto para esta actividad en $ MO' );
                
                } 
            },
            cellEditEnded(s, e) {
                //this.showInput = !this.showInput;
                console.log("1. inicia celleditended");
                //if (e.panel.cellType === wijmo.grid.CellType.Cell) {
                    console.log("1.1. celleditended, entra if");
                    let col = s.columns[e.col];
                    
                    console.log(col.binding);
                    //col.binding debe comenzar con % Subc-E
                    if (col.binding.trim().startsWith('subc-e')) {
                        let numfolio = col.binding.slice('subc-e'.length);
                        console.log("1.2. celleditended, entra if");
                        //obtener la fila actual
                        let row = s.rows[e.row];
                        //s.rows.forEach(row => {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            console.log("1.3. celleditended, entra a for");
                            
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('subc-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log("row.dataItem: ");
                            console.log(row);
                            row.dataItem['msubce'] = sum;

                            sumest = Number(((Number(row.dataItem['subc-e' + numfolio]) || Number(0)) * Number(row.dataItem['msubc'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mhtae-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmahtaeq'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mmo-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmo'])/100).toFixed(2));                            
                            row.dataItem['est-e' + numfolio] = sumest * 100;

                            this.calculateColumnTotal('est-e' + numfolio);
                            //obtener el indice de la variable col
                            /*let indicecol = this.flex.columns.findIndex(column => column.binding === ('est-e' + numfolio));
                            console.log(indicecol);
                            let hdr = `${sumest}`;
                            console.log(hdr);
                            this.flex.columnHeaders.setCellData(1, indicecol, hdr);*/

                            console.log("1.5. celleditended, suma estimaciones " +  sumest);

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log("1.6. celleditended, suma estimaciones " +  sumestima);
                            row.dataItem['estacum'] = sumestima;
                            //row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                            this.calculateColumnTotal('estacum');
                            this.calculateColumnTotalEstacume('estacume');
                        //});
                        this.updateEstimacionDet(3, this.selectedProject, numfolio, row.dataItem['idestimacionbase'], 3, row.dataItem['subc-e' + numfolio], row.dataItem['mhtae-e' + numfolio], row.dataItem['mmo-e' + numfolio], row.dataItem['est-e' + numfolio] );
                    }
                    if (col.binding.trim().startsWith('mhtae-e')) {
                        let numfolio = col.binding.slice('mhtae-e'.length);
                        console.log("1.2. celleditended, entra if");
                        let row = s.rows[e.row];
                        //s.rows.forEach(row => {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            console.log("1.3. celleditended, entra a for");
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mhtae-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            row.dataItem['mmahtaeqe'] = sum;

                            sumest = Number(((Number(row.dataItem['subc-e' + numfolio]) || Number(0)) * Number(row.dataItem['msubc'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mhtae-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmahtaeq'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mmo-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmo'])/100).toFixed(2));                            
                            row.dataItem['est-e' + numfolio] = sumest * 100;

                            this.calculateColumnTotal('est-e' + numfolio);

                            console.log("1.5. celleditended, suma estimaciones " +  sumest);

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log("1.6. celleditended, suma estimaciones " +  sumestima);
                            row.dataItem['estacum'] = sumestima;
                            //row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                            this.calculateColumnTotal('estacum');
                            this.calculateColumnTotalEstacume('estacume');
                        //});
                        this.updateEstimacionDet(3, this.selectedProject, numfolio, row.dataItem['idestimacionbase'], 3, row.dataItem['subc-e' + numfolio], row.dataItem['mhtae-e' + numfolio], row.dataItem['mmo-e' + numfolio], row.dataItem['est-e' + numfolio] );
                    }
                    if (col.binding.trim().startsWith('mmo-e')) {
                        let numfolio = col.binding.slice('mmo-e'.length);
                        console.log("1.2. celleditended, entra if");
                        let row = s.rows[e.row];
                        //s.rows.forEach(row => {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);
                            
                            console.log("1.3. celleditended, entra a for");
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mmo-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            row.dataItem['mmoe'] = sum;

                            sumest = Number(((Number(row.dataItem['subc-e' + numfolio]) || Number(0)) * Number(row.dataItem['msubc'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mhtae-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmahtaeq'])/100).toFixed(2));
                            sumest += Number(((Number(row.dataItem['mmo-e' + numfolio]) || Number(0)) * Number(row.dataItem['mmo'])/100).toFixed(2));                            
                            row.dataItem['est-e' + numfolio] = sumest * 100;

                            this.calculateColumnTotal('est-e' + numfolio);

                            console.log("1.5. celleditended, suma estimaciones " +  sumest);

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log("1.6. celleditended, suma estimaciones " +  sumestima);
                            row.dataItem['estacum'] = sumestima;
                            //row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                            this.calculateColumnTotal('estacum');
                            this.calculateColumnTotalEstacume('estacume');
                        //});
                        this.updateEstimacionDet(3, this.selectedProject, numfolio, row.dataItem['idestimacionbase'], 3, row.dataItem['subc-e' + numfolio], row.dataItem['mhtae-e' + numfolio], row.dataItem['mmo-e' + numfolio], row.dataItem['est-e' + numfolio] );
                    }

                // Al final de la función, actualiza los datos en la base de datos
                //this.updateEstimacionDet(s.rows[e.row].dataItem);
               // }
            },

            //this.updateEstimacionDet(3, 15, numfolio, row.dataItem['idestimacionbase'], 3, row.dataItem['subc-e' + numfolio], row.dataItem['mhtae-e' + numfolio], row.dataItem['mmo-e' + numfolio], row.dataItem['importe'] );
            updateEstimacionDet: async function(idcliente, idproyecto, folio, idestimacionbase, usuario, msubce, mmahtaeqe, mmoe, importe) {
                console.log("actualizar el registro via api");
                console.log(idcliente + " " + idproyecto + " " + folio + " " + idestimacionbase + " " + usuario + " " + msubce + " " + mmahtaeqe + " " + mmoe + " " + importe);                
                try {
                    //Validación de datos
                    //validar que idcliente no sea nulo, si no es nulo convertir a número y continuar
                    if (idcliente === null) {
                        console.log("cliente es nulo");
                        return;
                    } else {
                        idcliente = Number(idcliente);
                        if (idproyecto === null) {
                            console.log("proyecto es nulo");
                            return;
                        } else {
                            idproyecto = Number(idproyecto);
                            if (folio === null) {
                                console.log("folio es nulo");
                                return;
                            } else {
                                folio = Number(folio);
                                if (idestimacionbase === null) {
                                    console.log("estimacionbase es nulo");
                                    return;
                                } else {
                                    idestimacionbase = Number(idestimacionbase);
                                    if (usuario === null) {
                                        console.log("usuario es nulo");
                                        return;
                                    } else {
                                        usuario = Number(usuario);
                                        if (msubce === null) {
                                            console.log("msubce es nulo");
                                            return;
                                        } else {
                                            msubce = Number((msubce ).toFixed(4));
                                            if (mmahtaeqe === null) {
                                                console.log("mmahtaeqe es nulo");
                                                return;
                                            } else {
                                                mmahtaeqe = Number((mmahtaeqe ).toFixed(4));
                                                if (mmoe === null) {
                                                    console.log("mmoe es nulo");
                                                    return;
                                                } else {
                                                    mmoe = Number((mmoe ).toFixed(4));
                                                    if (importe === null) {
                                                        console.log("importe es nulo");
                                                        return;
                                                    } else {
                                                        importe = Number(importe.toFixed(4));
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    console.log(idcliente + " " + idproyecto + " " + folio + " " + idestimacionbase + " " + usuario + " " + msubce + " " + mmahtaeqe + " " + mmoe + " " + importe);                
                    /*idcliente = checkAndConvertNumbers(idcliente, 'cliente');
                    idproyecto = checkAndConvertNumbers(idproyecto, 'proyecto');
                    folio = checkAndConvertNumbers(folio, 'folio');
                    idestimacionbase = checkAndConvertNumbers(idestimacionbase, 'estimacionbase');
                    usuario = checkAndConvertNumbers(usuario, 'usuario');
                    msubce = checkAndConvertNumbers(msubce, 'msubce');
                    mmahtaeqe = checkAndConvertNumbers(mmahtaeqe, 'mmahtaeqe');
                    mmoe = checkAndConvertNumbers(mmoe, 'mmoe');
                    importe = checkAndConvertNumbers(importe, 'importe');*/

                    fetch(`http://157.245.220.201:3002/api/estimacionesdet/updatebyid/`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ cliente: idcliente, proyecto: idproyecto, folio: folio, estimacionbase: idestimacionbase, usuario: 3, msubce: msubce, mmahtaeqe: mmahtaeqe, mmoe: mmoe, importe: importe })
                    });

                 } catch (error) {
                    console.error('Error al actualizar el registro: ', error);
                }
            },

            calculateColumnTotal(columnName) {
                let subtotal = 0;
                let total = 0;
                console.log(`Total para ${columnName}`);
                // Verificar que la columna existe en el grid
                if (this.flex.columns.some(column => column.binding === columnName)) {
                    // Iterate over all the rows in the FlexGrid
                    for (let i = 0; i < this.flex.rows.length; i++) {
                        // Get the value of the cell in the specified column
                        let value = this.flex.getCellData(i, columnName, false);

                        // Convert value to number using parseFloat and consider zero if NaN
                        value = parseFloat(value);
                        if (isNaN(value)) {
                            value = 0; // Consider zero if value is NaN
                        }
                        //console.log(`Total for ${columnName}: ${value}`);
                        // Add the cell's value to the total
                        subtotal += Number(value.toFixed(2));
                        //console.log(`Total for ${columnName}: ${total}`);
                    }

                    console.log(`${this.flex.itemsSource.length}`);
                    // Iterate over all the rows in the FlexGrid
                    for (let i = 0; i < this.flex.itemsSource.length; i++) {
                        // Get the value of the cell in the specified column
                        let value = this.flex.itemsSource[i][columnName];

                        // Convert value to number using parseFloat and consider zero if NaN
                        value = parseFloat(value);
                        if (isNaN(value)) {
                            value = 0; // Consider zero if value is NaN
                        }

                        // Add the cell's value to the total
                        total += Number(value.toFixed(2));
                    }                
                    // Round the subtotal to 2 decimal places
                    subtotal = Math.round(subtotal * 100) / 100;
                    // Round the total to 2 decimal places
                    total = Math.round(total * 100) / 100;

                    // Format the total
                    this.subtotal = subtotal.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
                    // Format the total
                    this.total = total.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

                    //obtener el indice de la variable col
                    let indicecol = this.flex.columns.findIndex(column => column.binding === columnName);
                    console.log(indicecol);
                    let hdrSubt = `${this.subtotal}`;
                    console.log(hdrSubt);
                    this.flex.columnHeaders.setCellData(1, indicecol, hdrSubt);
                    let hdrTot = `${this.total}`;
                    console.log(hdrTot);
                    this.flex.columnHeaders.setCellData(0, indicecol, hdrTot);
                }
            },

            calculateColumnTotalEstacume(columnName) {
                let subtotal = 0;
                let total = 0;
                console.log(`Total para ${columnName}`);

                // Format the total
                this.subtotal = subtotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                // Format the total
                this.total = total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                //obtener el indice de la variable col
                let indicecolimporte = this.flex.columns.findIndex(column => column.binding === 'importe');
                let indicecolestacum = this.flex.columns.findIndex(column => column.binding === 'estacum');
                let indicecolestacume = this.flex.columns.findIndex(column => column.binding === columnName);

                console.log(indicecolimporte);
                console.log(indicecolestacum);
                console.log(indicecolestacume);

                let valueImporteSubt = parseFloat(this.flex.columnHeaders.getCellData(1, indicecolimporte, false));                
                let valueImporteTot = parseFloat(this.flex.columnHeaders.getCellData(0, indicecolimporte, false));
                let valueEstacumSubt = parseFloat(this.flex.columnHeaders.getCellData(1, indicecolestacum, false));
                let valueEstacumTot = parseFloat(this.flex.columnHeaders.getCellData(0, indicecolestacum, false));
                
                subtotal = valueEstacumSubt / valueImporteSubt * 100;
                //redondear subtotal a 2 decimales
                subtotal = Math.round(subtotal * 100) / 100;

                total = valueEstacumTot / valueImporteTot * 100;
                //redondear total a 2 decimales
                total = Math.round(total * 100) / 100;

                let indicecol = this.flex.columns.findIndex(column => column.binding === columnName);
                console.log(indicecol);

                let hdrSubt = `${subtotal}%`;
                console.log(hdrSubt);
                this.flex.columnHeaders.setCellData(1, indicecol, hdrSubt);

                let hdrTot = `${total}%`;
                console.log(hdrTot);
                this.flex.columnHeaders.setCellData(0, indicecol, hdrTot);
            },

            updateSubtotals() {
                // Remove any existing footer rows
                this.flex.columnFooters.rows.clear();

                // Create a new footer row
                let footerRow = new wijmo.grid.GroupRow();

                // Calculate the subtotal for each column and add it to the footer row
                this.flex.columns.forEach((column) => {
                    let subtotal = this.calculateColumnTotal(column.binding);
                    footerRow.data[column.binding] = subtotal;
                });

                // Add the footer row to the columnFooters panel
                this.flex.columnFooters.rows.push(footerRow);
            },    
            
            checkAndConvertNumbers(value, name) {
                if (value === null) {
                    console.log(`${name} es nulo`);
                    return null;
                } else {
                    if (name === 'msubce' || name === 'mmahtaeqe' || name === 'mmoe') {
                        return Number((value / 100).toFixed(4));
                    } else if (name === 'importe') {
                        return Number(value.toFixed(4));
                    } else {
                        return Number(value);
                    }
                }
            },
            calculaAcumuladosInicial(columnname) {
                try {
                    console.log("La columna calculada inicial es: " + columnname);
                    //col.binding debe comenzar con % Subc-E
                    if (columnname.trim().startsWith('subc-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (columnname.trim().startsWith('subc-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(row);
                            this.flex.row.dataItem['msubce'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }

                    if (col.binding.trim().startsWith('mhtae-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mhtae-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            this.flex.row.dataItem['mmahtaeqe'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }

                    if (col.binding.trim().startsWith('mmo-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);
                            
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mmo-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            this.flex.row.dataItem['mmoe'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }
                } catch (error) {
                    console.error('Error al calcular los acumulados iniciales: ', error);
                }
            },

            selectAll() {
                this.checkedEstimaciones = [...this.estimaciones];
                console.log(this.checkedEstimaciones);
            },

            deselectAll() {
                this.checkedEstimaciones = [];
                console.log(this.checkedEstimaciones);
            },

            filterColumns() {

                console.log("1. filterColumns, entra a la función");
                //const grid = this.flex.control;
                //this.uncheckedEstimaciones = this.getUnselectedItems();

                //recorrer el arreglo de la lista uncheckedEstimaciones para validar si se encuentra en el arreglo de columnas de flex. Si hay alguna cuyo binding comience con subce-e más el valor de uncheckedEstimaciones, entonces mostrarla. marcar la columna como no visible
                if (this.flex && this.flex.columns) {
                    console.log("1.1. filterColumns, entra a la función");
                    let countColor = 0;
                    this.flex.columns.forEach(column => {
                        console.log(column.binding);
                        // Si la columna comienza con uno de los prefijos y el sufijo está en uncheckedEstimaciones, ocultarla
                        if (this.uncheckedEstimaciones.some(estimacion => column.binding === ("subc-e" + estimacion) || column.binding === ("mhtae-e" + estimacion) || column.binding === ("mmo-e" + estimacion) || column.binding === ("est-e" + estimacion))) {
                            console.log("1.2. filterColumns, coincide encabezado no seleccionado");
                            //console.log(estimacion);
                            column.visible = false;
                        }
                        // Si la columna comienza con uno de los prefijos y el sufijo está en checkedEstimaciones, mostrarla
                        else if (this.checkedEstimaciones.some(estimacion => column.binding === ("subc-e" + estimacion) || column.binding === ("mhtae-e" + estimacion) || column.binding === ("mmo-e" + estimacion) || column.binding === ("est-e" + estimacion))) {
                            console.log("1.2. filterColumns, coincide encabezado seleccionado");
                            //console.log(estimacion);
                            column.visible = true;
                            countColor++;
                            column.backgroundColor = countColor % 2 === 0 ? "#cee2fb" : "#e6f0fd";
                        }

                        // Forzar a la grilla a repintarse
                        this.flex.invalidate();
                        console.log("1.3. filterColumns, termina función");
                    });                    
                }
            },
            filterEtapas: function(e) {
                let filter = e.target.value.toLowerCase();
                this.flex.collectionView.filter = item => {
                    return (
                        filter.length == 0 ||
                        item.etapa.toLowerCase().indexOf(filter) > -1
                    );
                };
                this.flex.columns.forEach(column => {
                    if (column.binding === "msubc" || column.binding === "mmahtaeq" || column.binding === "mmo" || column.binding === "importe" || column.binding === "estacum" || column.binding === "estacume" || column.binding.startsWith('est-e')) {
                        console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                        this.calculateColumnTotal(column.binding);
                    }
                });
            },
            /*filterDPGen: function(e) {
                let filter = e.target.value.toLowerCase();
                this.flex.collectionView.filter = item => {
                    return (
                        filter.length == 0 ||
                        item.dpgen.toLowerCase().indexOf(filter) > -1
                    );
                };
                this.flex.columns.forEach(column => {
                    if (column.binding === "msubc" || column.binding === "mmahtaeq" || column.binding === "mmo" || column.binding === "importe" || column.binding === "estacum" || column.binding === "estacume" || column.binding.startsWith('est-e')) {
                        console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                        this.calculateColumnTotal(column.binding);
                    }
                });
            },*/
            filterEtapas2: function(e) {
                this.filterEtapa = e.target.value.toLowerCase();
                this.applyFilters2();
            },
            filterDPGens2: function(e) {
                this.filterDPGen = e.target.value.toLowerCase();
                this.applyFilters2();
            },
            applyFilters2: function() {
                this.flex.collectionView.filter = item => {
                return (
                    (this.filterEtapa.length == 0 || item.etapa.toLowerCase().indexOf(this.filterEtapa) > -1) &&
                    (this.filterDPGen.length == 0 || item.dpgen.toLowerCase().indexOf(this.filterDPGen) > -1)
                );
                };
                this.flex.columns.forEach(column => {
                    if (column.binding === "msubc" || column.binding === "mmahtaeq" || column.binding === "mmo" || column.binding === "importe" || column.binding === "estacum" || column.binding.startsWith('est-e')) {
                        console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                        this.calculateColumnTotal(column.binding);
                        
                    }
                    if (column.binding === "estacume") {
                        console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                        this.calculateColumnTotalEstacume(column.binding);
                        
                    }
                });
            },
            onCheckedEstimacionesChanged: function(sender){
                this.checkedEstimaciones = sender.checkedItems;
                console.log("Cambiaron los seleccionados");
                console.log(this.checkedEstimaciones);

                // Obtener los elementos no seleccionados
                this.uncheckedEstimaciones = this.estimaciones.filter(item => !this.checkedEstimaciones.includes(item));
                console.log("Elementos no seleccionados");
                console.log(this.uncheckedEstimaciones);                
            },
            onCheckedEtapasChanged: function(sender){
                this.checkedEtapas = sender.checkedItems;
                console.log("Etapas seleccionadas");
                console.log(this.checkedEtapas);

                // Obtener los elementos no seleccionados
                this.uncheckedEtapas = this.etapas.filter(item => !this.checkedEtapas.includes(item));
                console.log("Etapas no seleccionadas");
                console.log(this.uncheckedEtapas);                
            }
        }
    }
</script>

<style>
    .wj-flexgrid {
        /*height: auto;*/
        width: 100;/*width: 96vw; /*calc(100vw - 56px);*/
        height: 72vh; /*calc(100vh - 276px);        */
        margin: 6px 0;
        font-family: Arial, sans-serif; 
        font-size: 12px;
        overflow: auto;
    }

    .checkbox label input[type=checkbox] {
        margin-right: 5px;
    }

    .checkbox label {
        margin-right: 10px;
    }

    .btn {
        margin-right: 5px;
    }

    #flex-grid {
        display: flex;
    }
    .column {
        flex: 1;
        border: 1px solid black;
        height: 50px;
    }
    .left-align {
    text-align: left;
    }

    .custom-filter .wj-elem-filter {
        position: absolute;
        bottom: 0;
    }

    .wj-flexgrid .wj-cells .wj-row .wj-cell:hover {
        /* permite visualizar el valor de la celda completa */
        overflow: visible;
        z-index: 6 !important;
        color: black;
        text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px #eee,
            0 0 2px #eee,
            0 0 3px #eee,
            0 0 4px #eee,
            0 0 5px #eee,
            0 0 6px #eee,
            0 0 7px #eee,
            0 0 8px #eee,
            0 0 9px #eee,
            0 0 10px #eee
    }

    .high-value {
        font-weight: bold;
        color: red;
    }
    .low-value {
        font-style: italic;
        color: green;        
    }
    .cero-value {
        font-weight: bold;
        color: black;
    }
    .entre-value {
        color: blue;
    }
    .uno-value {
        color: green;
    }
    .mayor-value {
        color: red;
    }

    .align-left {
        text-align: left;
    }
</style>