<template>
  <div style="background-color: rgb(180, 200, 230);">
    <h1 style="background-color: rgb(78, 78, 82); color: white;">Selecciona un proyecto</h1>
    <div class="row" style="background-color: rgb(180, 200, 230);">
      <div v-for="project in projects" :key="project.id" class="col-md-4">
        <div @click="selectProject(project.id, project.descripcion)" class="card my-3">
          <img :src="project.imageurl" class="card-img-top img-fluid" alt="Proyecto">
          <div class="card-body">
            <h5 class="card-title">{{ project.descripcion }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@grapecity/wijmo.styles/wijmo.css';
import 'bootstrap/dist/css/bootstrap.css';
import Vue from 'vue';
import '@grapecity/wijmo.touch'; // support drag/drop on touch devices
import '@grapecity/wijmo.vue2.olap';
import * as wjcOlap from '@grapecity/wijmo.olap';

export default {
  data() {
    return {
      projects: [], // Aquí almacenaremos la lista de proyectos obtenida de la API
      selectedProjectId: null, // Aquí almacenaremos el ID del proyecto seleccionado
      selectedProjectDescripcion: null,
    };
  },
  methods: {
    selectProject(projectId, projectDescripcion) {
      this.selectedProjectId = projectId;
      this.selectedProjectDescripcion = projectDescripcion
      // Actualiza las variables globales en el almacén VueX
      this.$store.dispatch('updateProjectInfo', { id: projectId, description: projectDescripcion });
      // Puedes realizar cualquier acción adicional aquí si es necesario
      console.log("selección:", this.selectedProjectId, this.selectedProjectDescripcion);
      
      // Redirige a la ruta de detalles del proyecto si es necesario
      //this.$router.push({ name: 'project-details' });      
    },
    async fetchProjects() {
    try {
      //157.245.220.201:3002
      const response = await fetch('http://157.245.220.201:3002/api/proyectos/getall?cliente=3&estatus=true');
            //const response = await fetch('http://157.245.220.201:3002/api/proyectos');
      console.log("1. fetchProjects: pasa por aquí");
      if (response.ok) {
        const data = await response.json();
        // Asumiendo que la respuesta de la API tiene una propiedad "imageUrl" para la URL de la imagen
        this.projects = data.body.map(project => {
          return {
            id: project.id,
            descripcion: project.descripcion,
            imageurl: project.imageurl // Asigna la URL de la imagen
          };
        });
      } else {
        console.log('No se pudo cargar la lista de proyectos');
      }
    } catch (error) {
      console.error('Error al cargar la lista de proyectos: ', error);
    }
  },
  },
  mounted() {
    // Aquí debes hacer una solicitud a tu API para obtener la lista de proyectos
    // Luego, asigna la respuesta a this.projects
    this.fetchProjects();
  },
};
</script>

<style scoped>
/* Estilos de Bootstrap o personaliza según tu preferencia */
.card {
  cursor: pointer;
}
.card-img-top {
  max-width: 100%; /* Limitar el ancho máximo al 100% del contenedor (tarjeta) */
  height: auto; /* Mantener la proporción de aspecto */
}
</style>
