<template>
    <div class="container-fluid">
        <h4 style="background-color: rgb(155, 155, 155); color: white;">Resumen de Estimaciones por Actividad "{{ this.projectDescripcion }}"</h4>
        <div class="row"></div>
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="form-inline well well-lg">
                    <button @click="save({})" >Exportar</button>
                    <button @click="exportReporteEstimacionPDF">Generar Reporte Estimación</button>
                </div>
            </div>
        </div>        
        <div class="form-group">
            <label  for="estimaciones" title="MultiSelect">Selecciona los folios a consultar: </label>
            <!-- Botón Filtrar <wj-multi-select id="estimaciones" :itemsSource="estimaciones" :checkedItems="estimaciones" />-->
            <wj-multi-select ref="estimaciones"
                :placeholder="'Estimaciones'"
                :headerFormat="'{count:n0} estimaciones'"
                
                :showSelectAllCheckbox="true"
                :showFilterInput="true"
                :itemsSource="estimaciones"                
                :checkedItemsChanged='onCheckedEstimacionesChanged'></wj-multi-select>
            <!-- Botón Filtrar -->
            <button @click="calcula_todo_enbasea_seleccionados">Filtrar</button>
        </div>
        <div class="input-group">
            <div class="input-group-addon">
                <span class="glyphicon glyphicon-search"></span>
            </div>
            <input @input="filterEtapas2($event)" class="form-control" placeholder="Filtrar etapas">
        </div>
        <div class="input-group">
            <div class="input-group-addon">
                <span class="glyphicon glyphicon-search"></span>
            </div>
            <input @input="filterDPGens2($event)" class="form-control" placeholder="Filtrar DPGen">
        </div>
        <wj-group-panel
            style="display: block"
            :initialized="initializedPanel"
            :grid="grid"
            :placeholder="'Arrastra columnas aquí para crear grupos'">
        </wj-group-panel>
        <div class="row">
            <!-- the grid -->
            <wj-flex-grid :itemsSource="gridDataEstBase" :initialized="initializeFlexSheet" :formatItem="formatItem" :frozenColumns=16 :selectionMode="'RowRange'" >                
                <wj-flex-grid-filter class="custom-filter" :filterApplied="filterApplied" />
                <wj-flex-grid-column binding="concat2" header="Concat2" :width="50" :isReadOnly="true" :visible="false" />
                <wj-flex-grid-column binding="concat11" header="Concat11" :width="50" :isReadOnly="true" :visible="false" style="text-align: left;"/>
                <wj-flex-grid-column binding="orden" header="Orden" :width="40" :isReadOnly="true" :visible="false" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="etapa" header="E" :width="40" :isReadOnly="true" :visible="false" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="actividad" header="Actividad" :width="90" :isReadOnly="true" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }"  cssClass="left-align" />
                <wj-flex-grid-column binding="ni" header="N" :width="40" :isReadOnly="true" :isRequired=true wordWrap="true" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="dpgen" header="DPGen" :width="90" :isReadOnly="true" :visible="false" :isRequired=true  cssClass="left-align" :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="partidai" header="Partida" :width="100" :isReadOnly="true" :isRequired=true  cssClass="left-align" :headerStyles="{ height: '50px' }"/>
                <wj-flex-grid-column binding="ut" header="UT" :width="60" :isReadOnly="true" :isRequired=true  cssClass="left-align" wordWrap="true" :headerStyles="{ height: '50px' }"/>
                <wj-flex-grid-column binding="dt" header="DT" :width="150" :isReadOnly="true" :isRequired=true  cssClass="left-align" wordWrap="true" :headerStyles="{ height: '50px' }"/>
                <wj-flex-grid-column binding="cpt" header="CPT" format="n0" :width="80" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }"/>
                <wj-flex-grid-column binding="put" header="P.U.T." format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="msubc" header="$ Subc"  aggregate="Sum" format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="mmahtaeq" header="$ MHtaE" aggregate="Sum" format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="mmo" header="$ MO" aggregate="Sum" format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="importe" header="$ Tot" aggregate="Sum" format="c0" :width="90" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="imsubce" header="$ Subc"  aggregate="Sum" format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="immahtaeqe" header="$ MHtaE" aggregate="Sum" format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="immoe" header="$ MO" aggregate="Sum" format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="iestacum" header="$ Tot" aggregate="Sum" format="c0" :width="90" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="dmsubce" header="$ Subc"  aggregate="Sum" format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="dmmahtaeqe" header="$ MHtaE" aggregate="Sum" format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="dmmoe" header="$ MO" aggregate="Sum" format="c0" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="destacum" header="$ Tot" aggregate="Sum" format="c0" :width="90" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="msubce" header="% Subc" aggregate="Avg" format="p2" :width="85" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="mmahtaeqe" header="% MHtaE" aggregate="Avg" format="p2" wordWrap="true" :width="90" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />
                <wj-flex-grid-column binding="mmoe" header="% MO" aggregate="Avg" format="p2" :width="90" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />                
                <wj-flex-grid-column binding="estacume" header="% Tot" aggregate="Avg" format="p2" :width="80" :isReadOnly="true" :isRequired=true :headerStyles="{ height: '50px' }" />            
            </wj-flex-grid>
            <wj-menu
            style="display: none"
            dropDownCssClass="ctx-menu"
            :initialized="initializedMenu"
            :itemClicked="itemClicked" >
            <wj-menu-item>
                <span class='wj-glyph-down-right'></span> Expandir todo
            </wj-menu-item>
            <wj-menu-item>
                <span class='wj-glyph-right'></span> Colapsar todo
            </wj-menu-item>
                <wj-menu-separator />
            <wj-menu-item>
                <span class='wj-glyph-up'></span> Ordenar ascendente
            </wj-menu-item>
            <wj-menu-item>
                <span class='wj-glyph-down'></span> Ordenar descendente
            </wj-menu-item>
            <wj-menu-item>
                <span class='wj-glyph-circle'></span> Quitar ordenamiento
            </wj-menu-item>
                <wj-menu-separator />
            <wj-menu-item>
                <span>&times;</span> Quitar grupo
            </wj-menu-item>
        </wj-menu>
        </div>
    </div>
</template>

<script>
 
    import 'bootstrap/dist/css/bootstrap.css';
    import '@grapecity/wijmo.styles/wijmo.css';
    import * as XLSX from 'xlsx';

    import Vue from 'vue';
    //import { getActividadesProyecto } from '../../components/presupuesto/data';
    import '@grapecity/wijmo.vue2.grid';
    //import * as wjcCore from '@grapecity/wijmo';
    import * as wjcGrid from '@grapecity/wijmo.grid';
    import * as wjcCore from '@grapecity/wijmo';
    import * as wjcXlsx from '@grapecity/wijmo.xlsx';
    import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
    import "@grapecity/wijmo.vue2.grid.filter";
    import "@grapecity/wijmo.vue2.grid.search";
    import * as gridPdf from '@grapecity/wijmo.grid.pdf';
    //import { CollectionView } from '@grapecity/wijmo';
    import { FlexGrid,  GroupSummaryPosition } from '@grapecity/wijmo.grid';
    import '@grapecity/wijmo.vue2.input';
    import { CollectionView, PropertyGroupDescription, SortDescription } from "@grapecity/wijmo";
    import "@grapecity/wijmo.vue2.grid.grouppanel";
    export default {
    data() {
      return {
            selectedProject: null,
            tempgridDataBase: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            gridDataEstBase: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            gridDataEst: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            gridDataEstDet: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
            estimaciones: [],
            checkedEstimaciones: [], // inicialmente no hay items seleccionados
            uncheckedEstimaciones: [], // inicialmente no hay items no seleccionados
            etapas: [],
            checkedEtapas: [], // inicialmente no hay items seleccionados
            uncheckedEtapas: [], // inicialmente no hay items no seleccionados            
            includeColumnHeader: true,
            customContent: false,
            columns: [],
            countEstimaciones: 0,
            customColumnFooter: true,
            showInput: false,
            selectedDate: null,
            total: null,
            subtotal: null,
            showFilterInput: true,
            showSelectAllCheckbox: true,
            filterEtapa: '',
            filterDPGen: '',
            grid: null,
            groupIndex: 0,
		    //groupSummaryPosition: GroupSummaryPosition.Top,
            //ch: null,
        };
    },

    computed: {
      projectId() {
        return this.$store.getters.getProjectId;
      },
      projectDescripcion() {
        return this.$store.getters.getProjectDescription;
      },
      allOptions() {
        return ['Seleccionar todas', 'Deseleccionar todas', ...this.estimaciones];
      },
      getUnselectedItems() {        
        return this.estimaciones.filter(item => !this.checkedEstimaciones.includes(item));
      },      
      getUnselectedEtapas() {        
        return this.etapas.filter(item => !this.checkedEtapas.includes(item));
      }      
    },

    watch: {
        selectedProject(newValue, oldValue) {
          // Tu lógica aquí
        },
    },      
        async mounted() {
            try {
                const customStyle = document.createElement('style');
                customStyle.innerHTML = `
                    .wj-cell.wj-alt {
                    height: auto !important;
                    /* Otros estilos si es necesario */
                    }
                `;
                document.head.appendChild(customStyle);
                try {
                    //await this.fetchProjects(); // Llama a la función para obtener la lista de proyectos
                    await this.fetchDataEstBase();
                } catch (error) {
                    console.error('Error al obtener los proyectos: ', error);
                }

                //let theSearch = Control.getControl(this.$refs.theSearch.$el);
                //theSearch.grid = theGrid;
            } catch (error) {
                console.error('Error al obtener los datos de la tabla: ', error);
            }   
            //console.log(this.data);       
            
            // Asume que 'grid' es tu instancia de FlexGrid
            let collectionView = this.grid.collectionView;

            collectionView.collectionChanged.addHandler((sender, e) => {
                // Comprueba si el cambio fue un agregado de grupo
                if (e.action === wjcCore.NotifyCollectionChangedAction.Add && e.item instanceof wjcCore.PropertyGroupDescription) {
                    console.log('Se agregó un grupo:', e.item.propertyName);
                }
            });
        },

        methods: {

            async created() {
                try {
                    if (this.projectId) {
                        this.selectedProject = this.projectId
                    }
                } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                }                    
            },           
            initializeFlexSheet: function(flex) {
                this.flex = flex;

                this.flex.cellEditEnding.addHandler((s, e) => {
                    let col = s.columns[e.col];
                });

                // Add an event listener for the cellClick event
                /*this.flex.addEventListener(this.flex.hostElement, 'click', (e) => {
                    // Get the clicked cell
                    let ht = this.flex.hitTest(e);

                    // Check if a column header was clicked
                    if (ht.panel == this.flex.columnHeaders) {
                        // Get the clicked column
                        let column = this.flex.columns[ht.col];

                        // Check if the column is numeric
                        if (column.dataType === wijmo.DataType.Number) {
                            // Calculate the column total
                            this.calculateColumnTotal(column.binding);
                        }
                    }
                });*/
                this.flex.rowHeaders.columns.splice(0, 1); // no extra columns
                this.grid = flex;
            },

            initializedPanel: function(panel) {
                panel.hostElement.addEventListener('contextmenu', e => {
                    let groupDescription = panel.hitTest(e),
                        cv = panel.collectionView;
                    console.log("1. groupIndex: " + groupDescription);
                    console.log("1. cv: " + cv);

                    if (groupDescription) {
                        this.groupIndex = cv.groupDescriptions.indexOf(groupDescription);
                        console.log("1.1. groupIndex: " + this.groupIndex);
                        this.menu.show(e);
                    }
                    e.preventDefault();
                });
            },

            initializedMenu: function(menu) {
                this.menu = menu;
            },

            // handle menu commands
            itemClicked: function(menu) {
                let grid = this.grid,
                    cv = grid.collectionView,
                    groupIndex = this.groupIndex;
                    console.log("2. groupIndex: ");
                    console.log(grid);
                    console.log(cv);
                    console.log(groupIndex);
                    //this.groupSummaryPosition = groupIndex;
                switch (menu.selectedIndex) {
                    case 0: // expand all
                        grid.collapseGroupsToLevel(groupIndex + 1);
                        break;
                    case 1: // collapse all
                        grid.collapseGroupsToLevel(groupIndex);
                        break;
                    
                    case 3: // sort asc
                    case 4: // sort desc
                    case 5: // no sort
                        cv.deferUpdate(() => {
                            cv.sortDescriptions.clear();
                            if (menu.selectedIndex != 5) {
                                let binding = cv.groupDescriptions[groupIndex].propertyName;
                                cv.sortDescriptions.push(new SortDescription(binding, menu.selectedIndex == 3));
                            }
                        });
                        break;
                    
                    case 7: // remove group
                        cv.groupDescriptions.removeAt(groupIndex);
                        break;
                }
            },

            async fetchDataEstBase() {
                try {
                    this.selectedProject = 17;
                    // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                    const idcliente = 3; // Establece el ID del cliente
                    const idproyecto = this.selectedProject; // Utiliza el proyecto seleccionado
                    const url = `http://157.245.220.201:3002/api/estimacionesbase/getbyr/?cliente=${idcliente}&proyecto=${idproyecto}`;

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const responseData = await response.json();
                    this.tempgridDataBase = responseData.body;
                    
                    console.log(this.tempgridDataBase);
                    //console.log(this.gridDataEstBase);

                    this.gridDataEstBase = this.tempgridDataBase;
                    console.log(this.gridDataEstBase);

                    //obtener de gridDataEstBase la lista de etapas y agregarlas al arreglo global etapas
                    this.etapas = this.gridDataEstBase.map(item => item.etapa);
                    //no permitir duplicados en el arreglo global etapas
                    this.etapas = [...new Set(this.etapas)];
                    //refactorizar el arreglo global etapas
                    this.etapas = this.etapas.sort((a, b) => a - b);
                    console.log(this.etapas);

                    this.checkedEtapas = this.etapas;
                    
                    //this.addColumnsEstimaciones();

                    // Insertar fila de encabezado de columna adicional
                    let ch = this.flex.columnHeaders;

                    // Create new rows for each insertion
                    let hr1 = new wjcGrid.Row();
                    let hr2 = new wjcGrid.Row();
                    let hr3 = new wjcGrid.Row();

                    ch.rows.insert(1, hr1);
                    ch.rows.insert(2, hr2);
                    ch.rows.insert(3, hr3);
                    console.log("ch.rows");
                    console.log(ch.rows);

                    // Completar los encabezados en la fila de encabezado adicional
                    for (let i = 10; i < this.flex.columns.length; i++) {
                    //for (let i = 9; i < 12; i++) {
                        //obtener el binding de la columna
                        let columna = this.flex.columns[i];
                        //obtener el binding de la columna
                        let binding = columna.binding;
                        //si binding es dmsubce asígnar a hdr el valor "Diferencia"
                        let hdr = ch.getCellData(3, i, false);

                        if (binding == "msubce") {
                            hdr = "% Avance";
                        }
                        if (binding == "mmahtaeqe") {
                            hdr = "% Avance";
                        }
                        if (binding == "mmoe") {
                            hdr = "% Avance";
                        }
                        if (binding == "estacume") {
                            hdr = "% Avance";
                        }
                        if (binding == "imsubce") {
                            hdr = "$ Avance";
                        }
                        if (binding == "immahtaeqe") {
                            hdr = "$ Avance";
                        }
                        if (binding == "immoe") {
                            hdr = "$ Avance";
                        }
                        if (binding == "iestacum") {
                            hdr = "$ Avance";
                        }
                        if (binding == "dmsubce") {
                            hdr = "$ Por avanzar";
                        }
                        if (binding == "dmmahtaeqe") {
                            hdr = "$ Por avanzar";
                        }
                        if (binding == "dmmoe") {
                            hdr = "$ Por avanzar";
                        }
                        if (binding == "destacum") {
                            hdr = "$ Por avanzar";
                        }
                        if (hdr == "CPT") {
                            hdr = "Presupuesto";
                        }
                        if (hdr == "P.U.T.") {
                            hdr = "Presupuesto";
                        }
                        if (hdr == "$ Subc") {
                            hdr = "Presupuesto";
                        }
                        if (hdr == "$ MHtaE") {
                            hdr = "Presupuesto";
                        }
                        if (hdr == "$ MO") {
                            hdr = "Presupuesto";
                        }
                        if (hdr == "$ Tot") {
                            hdr = "Presupuesto";
                        }
                        ch.setCellData(2, i, hdr);
                        // centrar el encabezado de la columna
                        console.log("centrar el encabezado de la columna");
                        //columna.headerCellStyle = { 'text-align': 'center' };
                        //imprime en consola valor de celda
                        console.log(hdr);
                    }

                    // Permitir la fusión a través y hacia abajo de la fila de encabezado adicional
                    this.flex.allowMerging = wjcGrid.AllowMerging.ColumnHeaders;
                    //hr1.allowMerging = true;
                    hr2.allowMerging = true;
                    hr3.allowMerging = true;
                    this.flex.columns[0].allowMerging = true;
                    this.flex.columns[1].allowMerging = true;
                    this.flex.columns[2].allowMerging = true;
                    this.flex.columns[3].allowMerging = true;

                    //this.hr = hr;
                    // Llamar a fetchDataEstDet después de que fetchDataEstBase haya terminado
                    await this.fetchDataEstFolio(); // Espera a que fetchDataEstDet termine antes de continuar

                    // Llamar a fetchDataEstDet después de que fetchDataEstBase haya terminado
                    await this.fetchDataEstDet(); // Espera a que fetchDataEstDet termine antes de continuar

                    //let fh = this.flex.columnFooters,
                    //fr = new wjcGrid.Row();
                    //fh.rows.insert(0, fr);
                    //fh.rows.insert(1, fr);
                    
                } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                }
            },

            async fetchDataEstFolio() {
                try {
                    this.selectedProject = 17;
                    // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                    const idcliente = 3; // Establece el ID del cliente
                    const idproyecto = this.selectedProject; // Utiliza el proyecto seleccionado
                    const url = `http://157.245.220.201:3002/api/estimaciones/getlast/?cliente=${idcliente}&proyecto=${idproyecto}`;

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const responseData = await response.json();
                    this.tempgridDataFolio = responseData.body;
                    
                    // Check if the response body is not empty
                    if (this.tempgridDataFolio && this.tempgridDataFolio.length > 0) {
                        // Get the folio of the first record
                        this.countEstimaciones = Number(this.tempgridDataFolio[0].folio_maximo);
                    } else{
                        this.countEstimaciones = Number(0);
                    }
                    console.log("folio: " + this.countEstimaciones);
                } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                }
            },

            async fetchDataEstDet() {
                try {
                    console.log("Obtener datos de la tabla de estimacionesdet");
                    this.selectedProject = 17;
                    // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
                    const idcliente = 3; // Establece el ID del cliente
                    const idproyecto = this.selectedProject; // Utiliza el proyecto seleccionado
                    const url = `http://157.245.220.201:3002/api/estimacionesdet/getall?cliente=${idcliente}&proyecto=${idproyecto}`;

                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    const responseData = await response.json();
                    this.tempgridDataDet = responseData.body;
                    this.gridDataEstDet = this.tempgridDataDet;
                    console.log(this.tempgridDataDet);
                    console.log(this.gridDataEstDet);

                    this.addColumnsEstimaciones();
                    
                } catch (error) {
                    console.error('Error al obtener los datos de la tabla: ', error);
                }
            },


            filter: function(e) {
                let filter = e.target.value.toLowerCase();
                this.flex.collectionView.filter = item => {
                    return (
                        filter.length == 0 ||
                        item.frente.toLowerCase().indexOf(filter) > -1
                    );
                };
            },            
            
            customContentClick: function(e) {
                this.flex.invalidate();
            },

            formatItem: function(grid, e) {
                let panel = e.panel,
                    r = e.row,
                    c = e.col,
                    cell = e.cell;

                    console.log("1.1. formatItem, entra if");
                if (e.panel == this.flex.cells) {
                    let binding = this.flex.columns[c].binding;
                    if (binding === 'estacume' || binding === 'msubce' || binding === 'mmahtaeqe' || binding === 'mmoe') {
                        let value = e.panel.getCellData(e.row, e.col, false);
                        wjcCore.toggleClass(e.cell, 'cero-value', wjcCore.isNumber(value) && value === 0);
                        wjcCore.toggleClass(e.cell, 'entre-value', wjcCore.isNumber(value) && value > 0 && value < 1);
                        wjcCore.toggleClass(e.cell, 'uno-value', wjcCore.isNumber(value) && value === 1);
                        wjcCore.toggleClass(e.cell, 'mayor-value', wjcCore.isNumber(value) && value > 1);
                        //wjcCore.toggleClass(e.cell, 'low-value', wjcCore.isNumber(value) && value < 00);
                    }
                    if (binding.startsWith('subc-e') || binding.startsWith('mhtae-e') || binding.startsWith('mmo-e')) {
                        let value = e.panel.getCellData(e.row, e.col, false);
                        wjcCore.toggleClass(e.cell, 'cero-value', wjcCore.isNumber(value) && value === 0);
                        wjcCore.toggleClass(e.cell, 'entre-value', wjcCore.isNumber(value) && value > 0 && value < 1);
                        wjcCore.toggleClass(e.cell, 'uno-value', wjcCore.isNumber(value) && value === 1);
                        wjcCore.toggleClass(e.cell, 'mayor-value', wjcCore.isNumber(value) && value > 1);
                        //wjcCore.toggleClass(e.cell, 'low-value', wjcCore.isNumber(value) && value < 00);
                    }
                }
                if (e.panel == this.flex.columnHeaders) {
                    console.log("1.2. formatItem, entra if");
                    let binding = this.flex.columns[c].binding;
                    // Obtener el encabezado de la columna
                    let header = this.flex.columns[c].header;
                    console.log(header);
                    if (binding === 'cpt' || binding === 'put' || binding === 'msubc' || binding === 'mmahtaeq' || binding === 'mmo' || binding === 'importe') {
                        //cambiar el color de fondo de la celda
                        e.cell.style.backgroundColor = "#f8de7e";
                    }
                    if (binding === 'estacume' || binding === 'msubce' || binding === 'mmahtaeqe' || binding === 'mmoe') {
                        //cambiar el color de fondo de la celda
                        e.cell.style.backgroundColor = "#c6da52";
                    }                    
                    if (binding === 'iestacum' || binding === 'imsubce' || binding === 'immahtaeqe' || binding === 'immoe') {
                        //cambiar el color de fondo de la celda
                        e.cell.style.backgroundColor = "#c6da56";
                    }                    
                    if (binding === 'destacum' || binding === 'dmsubce' || binding === 'dmmahtaeqe' || binding === 'dmmoe') {
                        //cambiar el color de fondo de la celda
                        e.cell.style.backgroundColor = "#fe840e";
                    }                    
                    //si el encabezado tiene la palabra "Estimación", "Presupuesto" o "Avance" centrar el texto
                    if (header.startsWith('Estimación') || header.startsWith('Presupues') || header.startsWith('Avanc')) {
                        console.log("1.7. formatItem, entra if");
                        // centrar el encabezado de la columna
                        e.cell.style.textAlign = 'center';
                    }
                }

            },

            save() {
                wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
                    this.flex,
                    {
                        includeColumnHeaders: this.includeColumnHeader,
                        includeStyles: false,
                        formatItem: this.customContent
                            ? this.exportFormatItem
                            : null
                    },
                    'ResumenEstimacionesporActividad.xlsx'
                );
            },

            exportFormatItem: function(args) {
                var p = args.panel,
                    row = args.row,
                    col = args.col,
                    xlsxCell = args.xlsxCell;

                if (p.cellType === wjcGrid.CellType.Cell) {
                    if (p.columns[col].binding === 'color') {
                        if (xlsxCell.value) {
                            if (!xlsxCell.style.font) {
                                xlsxCell.style.font = {};
                            }
                            xlsxCell.style.font.color = xlsxCell.value.toLowerCase();
                        }
                    } else if (p.columns[col].binding === 'active' && p.rows[row] instanceof wjcGrid.GroupRow) {
                        let cell = args.getFormattedCell();
                        xlsxCell.value = cell.textContent.trim();
                        xlsxCell.style.hAlign = wjcXlsx.HAlign.Left;
                    }
                }
            },
 
            agregaTotalesSubtotalesFijos() {
                try {

                            //obtener el indice de la variable col
                            let indicecol = this.flex.columns.findIndex(column => column.binding === 'put');
                            console.log(indicecol);
                            let hdrSubt = `Subtotal`;
                            console.log(hdrSubt);
                            this.flex.columnHeaders.setCellData(1, indicecol, hdrSubt);
                            let hdrTot = `Total`;
                            console.log(hdrTot);
                            this.flex.columnHeaders.setCellData(0, indicecol, hdrTot);
                        
                            this.calculateColumnTotal('msubc');                           
                            this.calculateColumnTotal('mmahtaeq');                        
                            this.calculateColumnTotal('mmo');                            
                            this.calculateColumnTotal('importe');

                            this.calculateColumnTotal('imsubce');
                            this.calculateColumnTotal('immahtaeqe');
                            this.calculateColumnTotal('immoe');
                            this.calculateColumnTotal('iestacum');

                            this.calculateColumnTotal('dmsubce');
                            this.calculateColumnTotal('dmmahtaeqe');
                            this.calculateColumnTotal('dmmoe');
                            this.calculateColumnTotal('destacum');

                            //this.calculateColumnTotal('estacum');
                            this.calculateColumnTotalEstacume('estacume');
                    
                } catch (error) {
                    console.error('Error al generar totales y subtotales iniciales: ', error);
                }
            },

            //gridDataEstBase.slice(9)
            addColumnsEstimaciones() {

                this.agregaTotalesSubtotalesFijos();
                // validar si gridDataEstDet esta vacio
                if (this.gridDataEstDet.length === 0) {
                    return;
                }
                const bindings = ['subc-e', 'mhtae-e', 'mmo-e', 'est-e'];
                const headers = ['%Subc', '%MHtaE', '%MO', '$Est'];
                const widths = [100, 100, 100, 107]; 
                const readonlys = [true, true, true, true]; 
                const formatos = ['p2', 'p2', 'p2', 'c0'];
                const visibles = [false, false, false, false];

                console.log("agregar columnas de estimaciones");

                this.gridDataEstDet.sort((a, b) => Number(a.folio) - Number(b.folio));

                console.log(this.gridDataEstDet);
                //Generamos la primer columna de la primera estimación
                let prevFolio = this.gridDataEstDet[0].folio;
                let columnaActual = 28;

                // Asegúrate de que this.flex y this.flex.columnHeaders existen
                if (!this.flex || !this.flex.columnHeaders) {
                    console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                    return;
                }

                let columnCount = this.flex.columnHeaders.columns.length;
                console.log(`FlexGrid tiene ${columnCount} columnas`);

                for (let i = 0; i < 4; i++) {                    

                    // Insertar columna adicional en flexgrid
                    this.flex.columns.insert(columnaActual, new wjcGrid.Column({
                        binding: `${bindings[i]}${this.gridDataEstDet[0].folio}`,
                        header: `${headers[i]}`,
                        width: widths[i],
                        isReadOnly: readonlys[i],
                        isRequired: false,
                        format: formatos[i],
                        visible: visibles[i],
                        //style: 'text-align: left',
                    }));

                    let hdr = `Estimación-${this.gridDataEstDet[0].folio}`;
                    this.flex.columnHeaders.setCellData(2, columnaActual, hdr);

                    //agregar el número de estimación al arreglo de global estimaciones
                    this.estimaciones.push(this.gridDataEstDet[0].folio);
                    //no permitir duplicados en el arreglo global estimaciones
                    this.estimaciones = [...new Set(this.estimaciones)];
                    //refactorizar el arreglo global estimaciones
                    this.estimaciones = this.estimaciones.sort((a, b) => a - b);
                    console.log(this.estimaciones);                    

                    columnaActual++; 

                    // Asegúrate de que this.flex y this.flex.columnHeaders existen
                    if (this.flex && this.flex.columnHeaders) {
                        // Obtén el número de columnas
                        let columnCount = this.flex.columnHeaders.columns.length;
                        console.log(`FlexGrid tiene ${columnCount} columnas`);
                    }  else {
                        console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                    }
                }

                //Generamos las columnas de las siguientes estimaciones
                for (let j = 0; j < this.gridDataEstDet.length; j++) {
                    if (this.gridDataEstDet[j].folio !== prevFolio) {                    
                        for (let i = 0; i < 4; i++) {
                            // Insertar columna adicional en flexgrid
                            this.flex.columns.insert(columnaActual, new wjcGrid.Column({
                                binding: `${bindings[i]}${this.gridDataEstDet[j].folio}`,
                                header: `${headers[i]}`,
                                width: widths[i],
                                isReadOnly: readonlys[i],
                                isRequired: false,
                                format: formatos[i],
                                visible: visibles[i],
                                //style: 'text-align: left',
                            }));

                            let hdr = `Estimación-${this.gridDataEstDet[j].folio}`;
                            this.flex.columnHeaders.setCellData(2, columnaActual, hdr);

                            //agregar el número de estimación al arreglo de global estimaciones
                            this.estimaciones.push(this.gridDataEstDet[j].folio);
                            //no permitir duplicados en el arreglo global estimaciones
                            this.estimaciones = [...new Set(this.estimaciones)];
                            //refactorizar el arreglo global estimaciones
                            this.estimaciones = this.estimaciones.sort((a, b) => a - b);
                            console.log(this.estimaciones);                    

                            columnaActual++;
                            // Asegúrate de que this.flex y this.flex.columnHeaders existen
                            if (this.flex && this.flex.columnHeaders) {
                                // Obtén el número de columnas
                                let columnCount = this.flex.columnHeaders.columns.length;
                                console.log(`FlexGrid tiene ${columnCount} columnas`);
                            }  else {
                                console.error('this.flex o this.flex.columnHeaders no esta definido o es nulo.');
                            }
                        }
                    }
                    prevFolio = this.gridDataEstDet[j].folio;
                }

                //this.$refs.estimaciones = this.estimaciones;

                //this.$refs.estimaciones.checkedItems = this.estimaciones;
                this.checkedEstimaciones = this.estimaciones;
                                
                this.flex.columns.forEach(column => {
                    if (column.binding.startsWith('est-e')) {
                        console.log("1.4. celleditended, coincide encabezado");
                        this.calculateColumnTotal(column.binding);
                    }
                });

                //calcular acumulados al terminar la carga inicial de estimaciones
                //this.calculaAcumuladosInicial("subc-e" + prevFolio);
                //this.calculaAcumuladosInicial("mhtae-e" + prevFolio);
                //this.calculaAcumuladosInicial("mmo-e" + prevFolio);

                //marcar todas las opciones del arreglo estimaciones
            },

            addColumns() {
                this.showInput = !this.showInput;
                this.countEstimaciones++;
                const bindings = ['subc-e', 'mhtae-e', 'mmo-e', 'est-e'];//['subc-e', 'mhtae-e', 'mmo-e', 'est-e'];
                const headers = ['%Subc', '%MHtaE', '%MO', '$Est'];
                const widths = [100, 100, 100, 107]; 
                const readonlys = [false, false, false, true]; 
                const formatos = ['p2', 'p2', 'p2', 'c0'];
                let columnCount = this.flex.columnHeaders.columns.length;
                console.log(`FlexGrid tiene ${columnCount} columnas`);
                for (let i = 0; i < 4; i++) {
                    /*this.columns.push({
                        binding: `${bindings[i]}${this.countEstimaciones}`,
                        header: `${headers[i]}${this.countEstimaciones}`,
                        width: widths[i],
                        isReadOnly: readonlys[i],
                        isRequired: false,
                        style: 'text-align: left;',
                        defaultBinding: Number(0)
                    });*/

                    // Insertar columna adicional en flexgrid

                    this.flex.columns.insert(columnCount, new wjcGrid.Column({
                        binding: `${bindings[i]}${this.countEstimaciones}`,
                        header: `${headers[i]}`,
                        width: widths[i],
                        isReadOnly: readonlys[i],
                        isRequired: false,
                        format: formatos[i],
                        //style: 'text-align: left',
                    }));

                    let hdr = `Estimación-${this.countEstimaciones}`;
                    console.log(hdr);
                    //let columnCount = this.flex.columnHeaders.columns.length;
                    this.flex.columnHeaders.setCellData(2, columnCount, hdr);
                    columnCount++;
                    
                }

                //agregar valor cero para todas las filas actuales de flexgrid en las columnas agregadas
                this.flex.rows.forEach(row => {
                    this.flex.columns.forEach(column => {
                        if (column.binding === "subc-e" + this.countEstimaciones || column.binding === "mhtae-e" + this.countEstimaciones || column.binding === "mmo-e" + this.countEstimaciones || column.binding === "est-e" + this.countEstimaciones) {
                            row.dataItem[column.binding] = 0;
                        }
                    });
                });


                //agregar api para guardar la estimación
                this.agregarestimacion({ idcliente: 3, idproyecto: 17, fecha: this.selectedDate, folio: this.countEstimaciones, usuario: 3 });

            },

            filterApplied(s, e) {
                try {

                    console.log('Filtro aplicado');
                    this.agregaTotalesSubtotalesFijos();
                //this.agregaTotalesSubtotalesFijos();
                //agregar valor cero para todas las filas actuales de flexgrid en las columnas agregadas                
                //this.flex.rows.forEach(row => {
                    //console.log("1.1. filterApplied, entra a for"); estacum
                    /*this.flex.columns.forEach(column => {
                        if (column.binding === "msubc" || column.binding === "mmahtaeq" || column.binding === "mmo" || column.binding === "importe" || column.binding === "iestacum" || column.binding.startsWith('est-e')) {
                            console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                            this.calculateColumnTotal(column.binding);
                        }
                        if (column.binding === "estacume") {
                            console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                            this.calculateColumnTotalEstacume(column.binding);
                        }
                    });*/
                //});
                } catch (error) {
                        console.error('Error en filterApplied: ', error);
                }
            },

            calculateColumnTotal(columnName) {
                let subtotal = 0;
                let total = 0;
                console.log(`Total para ${columnName}`);
                // Iterate over all the rows in the FlexGrid
                for (let i = 0; i < this.flex.rows.length; i++) {
                    // Get the value of the cell in the specified column
                    let value = this.flex.getCellData(i, columnName, false);

                    // Convert value to number using parseFloat and consider zero if NaN
                    value = parseFloat(value);
                    if (isNaN(value)) {
                        value = 0; // Consider zero if value is NaN
                    }
                    //console.log(`Total for ${columnName}: ${value}`);
                    // Add the cell's value to the total
                    subtotal += Number(value.toFixed(2));
                    //console.log(`Total for ${columnName}: ${total}`);
                }

                console.log(`${this.flex.itemsSource.length}`);
                // Iterate over all the rows in the FlexGrid
                for (let i = 0; i < this.flex.itemsSource.length; i++) {
                    // Get the value of the cell in the specified column
                    let value = this.flex.itemsSource[i][columnName];

                    // Convert value to number using parseFloat and consider zero if NaN
                    value = parseFloat(value);
                    if (isNaN(value)) {
                        value = 0; // Consider zero if value is NaN
                    }

                    // Add the cell's value to the total
                    total += Number(value.toFixed(2));
                }                
                // Round the subtotal to 2 decimal places
                subtotal = Math.round(subtotal * 100) / 100;
                // Round the total to 2 decimal places
                total = Math.round(total * 100) / 100;

                // El número de grupos es la longitud del array 'groupDescriptions'
                console.log(`index agrupadas`);
                let grid = this.grid,
                cv = grid.collectionView;
                let numberOfGroups = cv.groupDescriptions.length;
                console.log('Número de grupos:', numberOfGroups);
                //si no hay grupos dejar el valor en cero
                if (numberOfGroups === 0) {
                    console.log('No hay grupos');
                } else {
                    // Format the subtotal
                    subtotal = subtotal/(1+Number(numberOfGroups));
                    // Format the total
                    //total = total/(1+Number(numberOfGroups));
                }

                // Format the total
                this.subtotal = subtotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                // Format the total
                this.total = total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });


                //obtener el indice de la variable col
                let indicecol = this.flex.columns.findIndex(column => column.binding === columnName);
                console.log(indicecol);
                let hdrSubt = `${this.subtotal}`;
                console.log(hdrSubt);
                this.flex.columnHeaders.setCellData(1, indicecol, hdrSubt);
                let hdrTot = `${this.total}`;
                console.log(hdrTot);
                this.flex.columnHeaders.setCellData(0, indicecol, hdrTot);
                //this.total = total;
                // Display the total
                //console.log(`Total for ${columnName}: ${total}`);
            },

            updateSubtotals() {
                // Remove any existing footer rows
                this.flex.columnFooters.rows.clear();

                // Create a new footer row
                let footerRow = new wijmo.grid.GroupRow();

                // Calculate the subtotal for each column and add it to the footer row
                this.flex.columns.forEach((column) => {
                    let subtotal = this.calculateColumnTotal(column.binding);
                    footerRow.data[column.binding] = subtotal;
                });

                // Add the footer row to the columnFooters panel
                this.flex.columnFooters.rows.push(footerRow);
            },    
            
            calculateColumnTotalEstacume(columnName) {
                let subtotal = 0;
                let total = 0;
                console.log(`Total para ${columnName}`);

                // Format the total
                this.subtotal = subtotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                // Format the total
                this.total = total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                //obtener el indice de la variable col
                let indicecolimporte = this.flex.columns.findIndex(column => column.binding === 'importe');
                let indicecolestacum = this.flex.columns.findIndex(column => column.binding === 'iestacum');
                let indicecolestacume = this.flex.columns.findIndex(column => column.binding === columnName);

                console.log(indicecolimporte);
                console.log(indicecolestacum);
                console.log(indicecolestacume);

                let valueImporteSubt = parseFloat(this.flex.columnHeaders.getCellData(1, indicecolimporte, false));                
                let valueImporteTot = parseFloat(this.flex.columnHeaders.getCellData(0, indicecolimporte, false));
                let valueEstacumSubt = parseFloat(this.flex.columnHeaders.getCellData(1, indicecolestacum, false));
                let valueEstacumTot = parseFloat(this.flex.columnHeaders.getCellData(0, indicecolestacum, false));
                
                subtotal = valueEstacumSubt / valueImporteSubt * 100;
                //redondear subtotal a 2 decimales
                subtotal = Math.round(subtotal * 100) / 100;

                total = valueEstacumTot / valueImporteTot * 100;
                //redondear total a 2 decimales
                total = Math.round(total * 100) / 100;

                let indicecol = this.flex.columns.findIndex(column => column.binding === columnName);
                console.log(indicecol);

                let hdrSubt = `${subtotal}%`;
                console.log(hdrSubt);
                this.flex.columnHeaders.setCellData(1, indicecol, hdrSubt);

                let hdrTot = `${total}%`;
                console.log(hdrTot);
                this.flex.columnHeaders.setCellData(0, indicecol, hdrTot);
            },

            calculaAcumuladosInicial(columnname) {
                try {
                    console.log("La columna calculada inicial es: " + columnname);
                    //col.binding debe comenzar con % Subc-E
                    if (columnname.trim().startsWith('subc-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (columnname.trim().startsWith('subc-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(row);
                            this.flex.row.dataItem['msubce'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }

                    if (col.binding.trim().startsWith('mhtae-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mhtae-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            this.flex.row.dataItem['mmahtaeqe'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }

                    if (col.binding.trim().startsWith('mmo-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);
                            
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mmo-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            this.flex.row.dataItem['mmoe'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e')) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }
                } catch (error) {
                    console.error('Error al calcular los acumulados iniciales: ', error);
                }
            },


            calculaAcumuladosInicialVisibles(columnname) {
                try {
                    console.log("La columna calculada inicial es: " + columnname);
                    //col.binding debe comenzar con % Subc-E
                    if (columnname.trim().startsWith('subc-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (columnname.trim().startsWith('subc-e') && column.visible) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(row);
                            this.flex.row.dataItem['msubce'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e') && column.visible) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }

                    if (col.binding.trim().startsWith('mhtae-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);

                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mhtae-e') && column.visible) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            this.flex.row.dataItem['mmahtaeqe'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e') && column.visible) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }

                    if (col.binding.trim().startsWith('mmo-e')) {
                            let sum = Number(0);
                            let sumest = Number(0);
                            let sumestima = Number(0);
                            
                            this.flex.columns.forEach(column => {
                                console.log(column.binding);
                                if (column.binding && column.binding.trim().startsWith('mmo-e') && column.visible) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    console.log(row.dataItem[column.binding]);
                                    sum += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            console.log(sum);
                            this.flex.row.dataItem['mmoe'] = sum * 100;

                            this.flex.columns.forEach(column => {
                                if (column.binding.startsWith('est-e') && column.visible) {
                                    console.log("1.4. celleditended, coincide encabezado");
                                    sumestima += Number(row.dataItem[column.binding]) || Number(0);
                                }
                            });
                            this.flex.row.dataItem['estacum'] = sumestima;
                            this.flex.row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                    }
                } catch (error) {
                    console.error('Error al calcular los acumulados iniciales: ', error);
                }
            },            

            calcula_todo_enbasea_seleccionados() {
                try {
                    console.log("1. calcula_enbasea_seleccionados, entra a la función");
                    //recorrer el arreglo de la lista uncheckedEstimaciones para validar si se encuentra en el arreglo de columnas de flex. Si hay alguna cuyo binding comience con subce-e más el valor de uncheckedEstimaciones, entonces mostrarla. marcar la columna como no visible
                    if (this.flex && this.flex.columns) {
                        console.log("1.1. calcula_enbasea_seleccionados, flex existe y no esta vacio");                    
                        //recorrer todas las filas de flexgrid
                        this.flex.rows.forEach(row => {
                            let sumsubce = Number(0);
                            let summhtae = Number(0);
                            let summoe = Number(0);
                            let sumestima = Number(0);
                            
                            console.log("1.1. calcula_enbasea_seleccionados, recorre cada renglon de flexgrid");
                            console.log(row);
                            //recorrer todas las columnas de flexgrid
                            this.flex.columns.forEach(column => {                                
                                console.log(column.binding);                        
                                if (this.checkedEstimaciones.some(estimacion => column.binding === ("subc-e" + estimacion))) {

                                    console.log("1.2. calcula_enbasea_seleccionados, coincide encabezado seleccionado");
                                    sumsubce += Number(row.dataItem[column.binding]) || Number(0);
                                    //isumsubce += (sumsubce * Number(row.dataItem['msubc'])) || Number(0);
                                    //dsumsubce += (Number(row.dataItem['msubc']) - isumsubce) || Number(row.dataItem['msubc']);                                    
                                }
                                if (this.checkedEstimaciones.some(estimacion => column.binding === ("mhtae-e" + estimacion))) {

                                    console.log("1.2. calcula_enbasea_seleccionados, coincide encabezado seleccionado");
                                    summhtae += Number(row.dataItem[column.binding]) || Number(0);
                                    //isummhtae += (summhtae * Number(row.dataItem['mmahtaeq'])) || Number(0);
                                    //dsummhtae += (Number(row.dataItem['mmahtaeq']) - isumsubce) || Number(row.dataItem['mmahtaeq']);

                                }
                                if (this.checkedEstimaciones.some(estimacion => column.binding === ("mmo-e" + estimacion))) {

                                    console.log("1.2. calcula_enbasea_seleccionados, coincide encabezado seleccionado");
                                    summoe += Number(row.dataItem[column.binding]) || Number(0);
                                    //isummoe += (summoe * Number(row.dataItem['mmo'])) || Number(0);
                                    //dsummoe += (Number(row.dataItem['mmo']) - isummoe) || Number(row.dataItem['mmo']);

                                }                                    
                            });

                            //columnas de porcentaje
                            row.dataItem['msubce'] = sumsubce;
                            row.dataItem['mmahtaeqe'] = summhtae;
                            row.dataItem['mmoe'] = summoe;
                            //columnas de avance
                            row.dataItem['imsubce'] = sumsubce * Number(row.dataItem['msubc']) || Number(0);
                            row.dataItem['immahtaeqe'] = summhtae * Number(row.dataItem['mmahtaeq']) || Number(0);
                            row.dataItem['immoe'] = summoe * Number(row.dataItem['mmo']) || Number(0);
                            row.dataItem['iestacum'] = Number(row.dataItem['imsubce']) + Number(row.dataItem['immahtaeqe']) + Number(row.dataItem['immoe']) || Number(0);
                            //columnas de diferencia
                            row.dataItem['dmsubce'] = Number(row.dataItem['msubc']) - (sumsubce * Number(row.dataItem['msubc'])) || Number(0);
                            row.dataItem['dmmahtaeqe'] = Number(row.dataItem['mmahtaeq']) - (summhtae * Number(row.dataItem['mmahtaeq'])) || Number(0);
                            row.dataItem['dmmoe'] = Number(row.dataItem['mmo']) - (summoe * Number(row.dataItem['mmo'])) || Number(0);
                            row.dataItem['destacum'] = Number(row.dataItem['dmsubce']) + Number(row.dataItem['dmmahtaeqe']) + Number(row.dataItem['dmmoe']) || Number(0);
                            //columna de porcentaje acumulado
                            row.dataItem['estacume'] = Number(row.dataItem['iestacum'])/Number(row.dataItem['importe']) || Number(0);

                            console.log("1.3. filterColumns, suma msubce " + sumsubce);
                            console.log("1.3. filterColumns, suma mmahtaeqe " + summhtae);
                            console.log("1.3. filterColumns, suma mmoe " + summoe);
                            
                            //row.dataItem['estacum'] = sumestima;
                            //this.calculateColumnTotalEstacume('estacume');
                            //row.dataItem['estacume'] = ((Number(sumestima / (Number(row.dataItem['importe'])))/100) || Number(0)).toFixed(2);
                        });
                            
                        /*this.calculateColumnTotal('msubc');                           
                        this.calculateColumnTotal('mmahtaeq');                        
                        this.calculateColumnTotal('mmo');                            
                        this.calculateColumnTotal('importe');

                        this.calculateColumnTotal('imsubce');
                        this.calculateColumnTotal('immahtaeqe');
                        this.calculateColumnTotal('immoe');
                        this.calculateColumnTotal('iestacum');

                        this.calculateColumnTotal('dmsubce');
                        this.calculateColumnTotal('dmmahtaeqe');
                        this.calculateColumnTotal('dmmoe');
                        this.calculateColumnTotal('destacum');

                        this.calculateColumnTotalEstacume('estacume');*/
                        this.agregaTotalesSubtotalesFijos();

                        this.flex.refresh();
                    }

                } catch (error) {
                    console.error('Error al calcular los acumulados iniciales: ', error);
                }
            },  

            selectAll() {
                this.checkedEstimaciones = [...this.estimaciones];
                console.log(this.checkedEstimaciones);
            },

            deselectAll() {
                this.checkedEstimaciones = [];
                console.log(this.checkedEstimaciones);
            },
 
            filterEtapas2: function(e) {
                this.filterEtapa = e.target.value.toLowerCase();
                this.applyFilters2();
            },

            filterDPGens2: function(e) {
                this.filterDPGen = e.target.value.toLowerCase();
                this.applyFilters2();
            },

            applyFilters2: function() {
                this.flex.collectionView.filter = item => {
                return (
                    (this.filterEtapa.length == 0 || item.etapa.toLowerCase().indexOf(this.filterEtapa) > -1) &&
                    (this.filterDPGen.length == 0 || item.dpgen.toLowerCase().indexOf(this.filterDPGen) > -1)
                );
                };
                this.flex.columns.forEach(column => {
                if (column.binding === "msubc" || column.binding === "mmahtaeq" || column.binding === "mmo" || column.binding === "importe" || column.binding === "iestacum") {
                    console.log("1.4. filterApplied, coincide encabezado" + column.binding);
                    this.calculateColumnTotal(column.binding);
                }
                });
            },

            onCheckedEstimacionesChanged: function(sender){
                this.checkedEstimaciones = sender.checkedItems;
                console.log("Cambiaron los seleccionados");
                console.log(this.checkedEstimaciones);

                // Obtener los elementos no seleccionados
                this.uncheckedEstimaciones = this.estimaciones.filter(item => !this.checkedEstimaciones.includes(item));
                console.log("Elementos no seleccionados");
                console.log(this.uncheckedEstimaciones);                
            },
            onCheckedEtapasChanged: function(sender){
                this.checkedEtapas = sender.checkedItems;
                console.log("Etapas seleccionadas");
                console.log(this.checkedEtapas);

                // Obtener los elementos no seleccionados
                this.uncheckedEtapas = this.etapas.filter(item => !this.checkedEtapas.includes(item));
                console.log("Etapas no seleccionadas");
                console.log(this.uncheckedEtapas);                
            }
        },

        //** Métodos para generar reporte de estimaciones */
        exportReporteEstimacionPDF() {
            let doc = new pdf.PdfDocument({
                header: {
                    declarative: {
                        text: 'Reporte de Estimación\t&[Page]\\&[Pages]',
                        font: new pdf.PdfFont('times', 12),
                        brush: '#bfc1c2'
                    }
                },
                lineGap: 2,
                pageSettings: {
                    margins: {
                        left: 36,
                        right: 36,
                        top: 36,
                        bottom: 36
                    }
                },
                ended: (sender, args) => pdf.saveBlob(args.blob, 'Estimacion.pdf')
            });
            //
            getEstimaciones().forEach((estimacion, i, arr) => {
                this.$_drawEstimacion(doc, estimacion);
                //
                if (i < arr.length - 1) {
                    doc.addPage();
                }
            });
            //
            doc.end();
        },
        //
        $_drawEstimacion(doc, estimacion) {
            let tot = estimacion.actividades.totales;
            let actividades = estimacion.actividades.items.sort((a, b) => a.date.getTime() - b.date.getTime());
            //
            let minDate = actividades[0].date,
                maxDate = actividades[actividades.length - 1].date,
                columns = [
                    { header: 'Date', binding: 'date', format: 'd' },
                    { header: 'Description', binding: 'description', format: 'c' },
                    { header: 'Hotel', binding: 'hotel', format: 'c' },
                    { header: 'Transport', binding: 'transport', format: 'c' },
                    { header: 'Meal', binding: 'meal', format: 'c' },
                    { header: 'Fuel', binding: 'fuel', format: 'c' },
                    { header: 'Misc', binding: 'misc', format: 'c' },
                    { header: 'Total', binding: 'total', format: 'c' }
                ],
                bold = new pdf.PdfFont('times', 10, 'normal', 'bold'),
                colWidth = this.$options.colWidth,
                rowHeight = this.$options.rowHeight;
            //
            // * draw captions *
            doc.drawText('Proyecto: ', null, null, { font: bold, continued: true });
            doc.drawText(employee.purpose);
            //
            doc.drawText('De: ', 380, 0, { font: bold, continued: true });
            doc.drawText(wijmo.changeType(minDate, wijmo.DataType.String, 'd'));
            //
            doc.drawText('a: ', 470, 0, { font: bold, continued: true });
            doc.drawText(wijmo.changeType(maxDate, wijmo.DataType.String, 'd'));
            //
            doc.moveDown(2);
            //
            let y = doc.y;
            doc.drawText('Nombre: ', 20, y, { font: bold, continued: true });
            doc.drawText(employee.name);
            //
            //
            doc.drawText('Posición: ', 190, y, { font: bold, continued: true });
            doc.drawText(employee.position);
            //
            doc.drawText('SSN: ', 360, y, { font: bold, continued: true });
            doc.drawText(employee.ssn);
            //
            y = doc.y;
            doc.drawText('Departamento: ', 20, y, { font: bold, continued: true });
            doc.drawText(employee.department);
            //
            doc.drawText('Administrador: ', 190, y, { font: bold, continued: true });
            doc.drawText(employee.manager);
            //
            doc.drawText('Estimación ID: ', 360, y, { font: bold, continued: true });
            doc.drawText(employee.id);
            //
            doc.moveDown(2);
            //
            // * draw table *
            doc.saveState();
            //
            y = 0;
            let scale = doc.width / (columns.length * colWidth),
                docY = doc.y;
            //
            if (scale > 1) {
                scale = 1;
            }
            //
            doc.scale(scale, scale, new wijmo.Point(0, docY));
            doc.translate(0, docY);
            //
            // header
            this.$_renderRow(doc, y, columns, column => column.header, null, bold, '#fad9cd');
            //
            y += rowHeight;
            //
            // body
            expenses.forEach(item => {
                this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format);
                y += rowHeight;
            });
            //
            // footer
            let totRow = ['Total', '', tot.hotel, tot.transport, tot.meal, tot.fuel, tot.misc, tot.total];
            this.$_renderRow(doc, y, totRow, null, () => 'c', bold, '#fad9cd');
            //
            y += rowHeight;
            //
            doc.y = docY + y * scale;
            //
            doc.restoreState();
            //
            doc.moveDown(2);
            //
            // * draw captions *
            doc.drawText('Subtotal: ', 400, doc.y, { font: bold, continued: true });
            doc.drawText(wijmo.changeType(tot.total - estimacion.iva, wijmo.DataType.String, 'c'));
            //
            doc.drawText('IVA: ', 400, doc.y, { font: bold, continued: true });
            doc.drawText(wijmo.changeType(estimacion.iva, wijmo.DataType.String, 'c'));
            //
            doc.drawText('Total: ', 400, doc.y, { font: bold, continued: true });
            doc.drawText(wijmo.changeType(tot.total, wijmo.DataType.String, 'c'));
            doc.moveDown(2);
            //
            this.$_checkLineAvailable(doc);
            //
            let thinPen = new pdf.PdfPen('#000000', 0.5)
            //
            y = doc.y;
            let sz = doc.drawText('Firma del Gerente:', 0, y);
            doc.paths.moveTo(sz.size.width, doc.y).lineTo(sz.size.width + 150, doc.y).stroke(thinPen);
            sz = doc.drawText('Fecha:', 300, y);
            doc.paths.moveTo(300 + sz.size.width, doc.y).lineTo(300 + sz.size.width + 75, doc.y).stroke(thinPen);
            //
            doc.moveDown();
            //
            this.$_checkLineAvailable(doc);
            //
            y = doc.y;
            sz = doc.drawText('Aprobado por:', 0, y);
            doc.paths.moveTo(sz.size.width, doc.y).lineTo(sz.size.width + 150, doc.y).stroke(thinPen);
            sz = doc.drawText('Fecha:', 300, y);
            doc.paths.moveTo(300 + sz.size.width, doc.y).lineTo(300 + sz.size.width + 75, doc.y).stroke(thinPen);
        },
        //
        $_checkLineAvailable(doc) {
            if (doc.height - doc.y < doc.lineHeight() + doc.lineGap) {
                doc.addPage();
            }
        },
        //
        $_renderRow(doc, y, values, valueGetter, formatGetter, font, brush) {
            let colWidth = this.$options.colWidth,
                rowHeight = this.$options.rowHeight;
            //
            values.forEach((v, idx) => {
                let x = idx * colWidth;
                //
                doc.paths
                    .rect(x, y, colWidth, rowHeight)
                    .fill(brush || '#f4b19b');
                //
                let value = valueGetter != null ? valueGetter(v) : v || '';
                let format = formatGetter != null ? formatGetter(v) : '';
                //
                if (value !== 'Total') {
                    value = wijmo.changeType(value, wijmo.DataType.String, format);
                }
                //
                doc.drawText(value, x + 3, y + 5, {
                    font: font,
                    height: rowHeight,
                    width: colWidth
                });
            });
        }            
    }
</script>

<style>
    .wj-flexgrid {
        /*height: auto;*/
        width: 100;/*width: 96vw; /*calc(100vw - 56px);*/
        height: 72vh; /*calc(100vh - 276px);        */
        margin: 6px 0;
        font-family: Arial, sans-serif; 
        font-size: 12px;
        overflow: auto;
    }

    .checkbox label input[type=checkbox] {
        margin-right: 5px;
    }

    .checkbox label {
        margin-right: 10px;
    }

    .btn {
        margin-right: 5px;
    }

    #flex-grid {
        display: flex;
    }
    .column {
        flex: 1;
        border: 1px solid black;
        height: 50px;
    }
    .left-align {
    text-align: left;
    }

    .custom-filter .wj-elem-filter {
        position: absolute;
        bottom: 0;
    }

    .wj-flexgrid .wj-cells .wj-row .wj-cell:hover {
        /* permite visualizar el valor de la celda completa */
        overflow: visible;
        z-index: 6 !important;
        color: black;
        text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px #eee,
            0 0 2px #eee,
            0 0 3px #eee,
            0 0 4px #eee,
            0 0 5px #eee,
            0 0 6px #eee,
            0 0 7px #eee,
            0 0 8px #eee,
            0 0 9px #eee,
            0 0 10px #eee
    }

    .high-value {
        font-weight: bold;
        color: red;
    }
    .low-value {
        font-style: italic;
        color: green;        
    }
    .cero-value {
        font-weight: bold;
        color: black;
    }
    .entre-value {
        color: blue;
    }
    .uno-value {
        color: green;
    }
    .mayor-value {
        color: red;
    }    
    .align-left {
        text-align: left;
    }

    .wj-dropdown-panel.ctx-menu {
        background: rgb(221, 250, 255);
        padding: 1em;
    }

    .wj-dropdown-panel.ctx-menu span {
        margin-right: 0.5em;
        opacity: 0.5;
    }    
</style>