<template>
  <div style="background-color: rgb(180, 200, 230);">
    <h4 style="background-color: rgb(155, 155, 155); color: white;">Etapas y Actividades de Presupuesto {{ this.projectDescripcion }}</h4>
    <div class="container-fluid">
      <div class="edit-msg" v-html="editMsg" style="width: 100%; height: 100%;"></div>
      <wj-flex-grid ref="flex" :itemsSource="gridData" :initialized="initializeGrid" style="width: 100%; height: 420px; font-family: Arial, sans-serif; font-size: 12px;" :formatItem="formatItem">
        <wj-flex-grid-column binding="fr" header="Fr" :width="50" :isReadOnly="true" />
        <wj-flex-grid-column binding="frente" header="Frente" :width="150" :isReadOnly="true" :isRequired=true :dataMap="data.frente" style="text-align: left;"/>
        <wj-flex-grid-column binding="ni" header="N" :width="50" :isReadOnly="true" :isRequired=true :dataMap="data.ni" />
        <wj-flex-grid-column binding="dpgen" header="DPGen" :width="90" :isReadOnly="true" :isRequired=true :dataMap="data.dpgen" style="text-align: left;"/>
        <wj-flex-grid-column binding="partida" header="Partida" :width="150" :isReadOnly="true" :isRequired=true :dataMap="data.partida" style="text-align: left;"/>
        <wj-flex-grid-column binding="importe" header="Importe" :width="100" :isReadOnly="true" :isRequired=true :dataMap="data.importe" />
        <wj-flex-grid-column binding="etapa" header="Etapa" :width="70" :isReadOnly="false" :dataMap="data.etapa" />
        <wj-flex-grid-column binding="secprog" header="SecProg" :width="70" :isReadOnly="false" :dataMap="data.secprog" />
        <wj-flex-grid-column binding="nombreprog" header="NombreProg" :width="240" :isReadOnly="false"  :dataMap="data.nombreprog" style="text-align: left;"/>
        <wj-flex-grid-column binding="actividad" header="Actividad" :width="240" :isReadOnly="true" :dataMap="data.actividad" style="text-align: left;"/>
      </wj-flex-grid>
    </div>
    <div class="row">
      <div class="col-md-6 col-xs-12">
        <div class="form-inline well well-lg">
          <input
            type="file"
            class="form-control"
            style="width: 250px;"
            id="importFile"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12"
          >
          <button @click="load()" class="btn btn-default">Importar</button>
          <div class="checkbox">
            <label>
              <input v-model="includeColumnHeader" type="checkbox"> Incluir encabezado de columna
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-12">
        <div class="form-inline well well-lg">
          <button @click="save()" class="btn btn-default">Exportar</button>
          <div class="checkbox">
            <label>
              <input v-model="includeColumnHeader" type="checkbox"> Incluir encabezado de columna
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input v-model="customContent" @click="customContentClick($event)" type="checkbox"> Personaliza contenido de celda
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
  import "@grapecity/wijmo.styles/wijmo.css";
  import 'bootstrap/dist/css/bootstrap.css';
  import Vue from "vue";
  import "@grapecity/wijmo.vue2.grid";
  import * as wjcCore from '@grapecity/wijmo';
  import * as wjcGrid from '@grapecity/wijmo.grid';
  import * as wjcInput from '@grapecity/wijmo.input';
  import * as wjcXlsx from '@grapecity/wijmo.xlsx';
  import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';

  export default {
    //name: 'EditableGridEtapasyActividadesPresupuesto',
    data() {
      return {
        selectedProject: null,
        tempgridData: null,
        gridData: null, //[], // Aquí se almacenarán los datos de tu API para mostrar en el grid
        editMsg: '&nbsp;',
        //countries: ['US', 'Germany', 'UK', 'Japan', 'Italy', 'Greece'],
        data: [],
        includeColumnHeader: true,
        customContent: true
      };
    },
    async created() {
      if (this.projectId) {
        this.selectedProject = this.projectId
        //await this.loadProjectDataWithCache();
      }
      else {
        //await this.fetchProjectsWithCache();
      }
    },  
    async mounted() {
      const customStyle = document.createElement('style');
      customStyle.innerHTML = `
        .wj-cell.wj-alt {
          height: auto !important;
          /* Otros estilos si es necesario */
        }
      `;
      document.head.appendChild(customStyle);
      try {
        //await this.fetchProjects(); // Llama a la función para obtener la lista de proyectos
        await this.fetchData();
      } catch (error) {
        console.error('Error al obtener los proyectos: ', error);
      }
    },

    computed: {
      projectId() {
        return this.$store.getters.getProjectId;
      },
      projectDescripcion() {
        return this.$store.getters.getProjectDescription;
      }
    },

    watch: {
        selectedProject(newValue, oldValue) {
          // Tu lógica aquí
        },
    },  

    methods: {

      initializeGrid(flex) {
        //this.data = this.fetchData();
        this.flex = flex;

        //cv = flex.collectionView;


        flex.beginningEdit.addHandler((s, e) => {
          this.editMsg = '&nbsp;';
          let col = s.columns[e.col];
          if (col.binding != 'actividad' && col.binding != 'nombreprog' && col.binding != 'secprog' && col.binding != 'etapa') {
            let item = s.rows[e.row].dataItem;
            if (item.overdue) { // prevent editing overdue items
              e.cancel = true;
              this.editMsg = 'No puede ser editada esta columna';
            }
          }
          if (e.panel == s.columnHeaders) {
            const columnName = s.columns[e.col].binding;
            console.log(s.columns[e.col].binding);

            if (columnName === 'etapa' && columnName === 'nombreprog' && columnName === 'secprog') {
              e.cell.style.backgroundColor = 'orange';
              console.log("9.2.2.4. pasa por aqui... valor: ", e.col);
            }
          }
        }),
        flex.cellEditEnding.addHandler((s, e) => {
          this.editMsg = '&nbsp;';
          let col = s.columns[e.col];
          if (col.binding == 'secprog' || col.binding == 'etapa') {
            let value = wjcCore.changeType(s.activeEditor.value, wjcCore.DataType.Number, col.format);
            if (!wjcCore.isNumber(value) || value < 0) { // prevent negative sales/expenses
              e.cancel = true;
              this.editMsg = 'Por favor ingresa un número valido';
            } else {
              if (col.binding == 'secprog') {
                s.rows[e.row].dataItem.actividad = value + ' ' +  s.rows[e.row].dataItem.nombreprog;              
              }
            }
          } else if (col.binding == 'actividad' || col.binding == 'nombreprog') {
            let value = wjcCore.changeType(s.activeEditor.value, wjcCore.DataType.String, col.format);
            if (!wjcCore.isString(value) || value < 0) { // prevent negative sales/expenses
              e.cancel = true;
              this.editMsg = 'Por favor ingresa una descripción valida';
            } else {
              if (col.binding == 'nombreprog') {
                s.rows[e.row].dataItem.actividad = s.rows[e.row].dataItem.secprog + ' ' + value;              
              }
            }
          }

        })
      },

      async fetchData() {
        try {
          //this.selectedProject = 15;
          // Realiza una solicitud a tu API para obtener los datos de la tabla basados en el proyecto seleccionado
          const response = await fetch(`http://157.245.220.201:3002/api/actividades/?proyecto=${this.selectedProject}`);

          this.tempgridData = await response.json();
          this.gridData = this.tempgridData.body;
          console.log(this.tempgridData);
          console.log(this.gridData);
        } catch (error) {
          console.error('Error al obtener los datos de la tabla: ', error);
        }
      },
        // Función para obtener el texto del tooltip si es necesario
        getTooltipText(text) {
        const maxTooltipLength = 50; // Ajusta la longitud máxima para mostrar el tooltip
        if (text && text.length > maxTooltipLength) {
          return text;
        }
        return '';
      },

      async saveChanges() {
        try {
          // Realiza una solicitud a tu API para guardar los cambios en los datos
          const response = await fetch('http://157.245.220.201:3002/api/guardar-lista-cambios', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.gridData),
          });

          if (response.ok) {
            console.log('Cambios guardados exitosamente.');
          } else {
            console.error('Error al guardar los cambios.');
          }
        } catch (error) {
          console.error('Error al realizar la solicitud: ', error);
        }
      },   

      /*load: function() {
        let workbook = new wijmo.xlsx.Workbook();
        let fileInput = document.getElementById('importFile');
        let file = fileInput.files[0];
        let flexGrid = new wijmo.grid.FlexGrid('#flexGrid');

        wijmo.xlsx.Workbook.load(file, (wb) => {
            let sheet = wb.sheets[0];
            wijmo.grid.xlsx.FlexGridXlsxConverter.load(flexGrid, sheet, { includeColumnHeaders: true });
        });
      },

      load: function() {
        let fileInput = document.getElementById('importFile');
        if (fileInput.files[0]) {
          this.customContent = false;
          const flexGridInstance = this.$refs.flex;
          wjcGridXlsx.FlexGridXlsxConverter.loadAsync(
            flexGridInstance,
            fileInput.files[0],
            { includeColumnHeaders: this.includeColumnHeader }
          );
        }
      },*/

      load: function() {
        let fileInput = document.getElementById('importFile');
        if (fileInput.files[0]) {
            this.customContent = false;

            // Create a temporary FlexGrid to hold the data
            let tempGrid = new FlexGrid('#importFile');

            // Load the file into the FlexGrid
            wjcGridXlsx.FlexGridXlsxConverter.loadAsync(
                tempGrid,
                fileInput.files[0],
                { includeColumnHeaders: this.includeColumnHeader }
            ).then(() => {
                // After the data has loaded, iterate over the items in tempGrid
                for (let i = 0; i < tempGrid.itemsSource.length; i++) {
                    let item = tempGrid.itemsSource[i];

                    // Find the corresponding item in this.flex
                    let existingItem = this.flex.itemsSource.find(x => x.id === item.id);

                    if (existingItem) {
                        // If the item exists, update it
                        Object.assign(existingItem, item);
                    } else {
                        // If the item does not exist, add it
                        this.flex.itemsSource.push(item);
                    }
                }

                // Refresh the FlexGrid to reflect the changes
                this.flex.refresh();
            });
        }
    },
          
      save() {
        wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
          this.flex,
          {
            includeColumnHeaders: this.includeColumnHeader,
            includeStyles: false,
            formatItem: this.customContent
              ? this.exportFormatItem
              : null
          },
          'EtapasYActividades.xlsx'
        );
      },
      customContentClick: function(e) {
        this.flex.invalidate();
      },      

      formatItem: function(grid, e) {
        let panel = e.panel,
        r = e.row,
        c = e.col,
        cell = e.cell;

        if (panel.cellType === wjcGrid.CellType.Cell) {
          let binding = grid.columns[c].binding,
          row = grid.rows[r];

          if (row instanceof wjcGrid.GroupRow) {
            if (row.level <= 2) {
              if (binding === 'active') {
                cell.innerHTML = this.customContent
                ? 'Amount/Pending: ' + Math.round(grid.getCellData(r, 'amount', false)/ grid.getCellData(r, 'amount2', false) * 100)/ 100
                : '';
              }
            }
          } else {
            if (binding === 'color') {
              cell.style.color = this.customContent ? grid.getCellData(r, c, true) : '';
            }
          }
        }
      },      

      exportFormatItem: function(args) {
        var p = args.panel,
          row = args.row,
          col = args.col,
          xlsxCell = args.xlsxCell;

        if (p.cellType === wjcGrid.CellType.Cell) {
          if (p.columns[col].binding === 'color') {
            if (xlsxCell.value) {
              if (!xlsxCell.style.font) {
                  xlsxCell.style.font = {};
              }
              xlsxCell.style.font.color = xlsxCell.value.toLowerCase();
            }
          } else if (p.columns[col].binding === 'active' && p.rows[row] instanceof wjcGrid.GroupRow) {
              let cell = args.getFormattedCell();
              xlsxCell.value = cell.textContent.trim();
              xlsxCell.style.hAlign = wjcXlsx.HAlign.Left;
          }
        }
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.load();
      });
    }
  };
</script>

<style>
/* Importa el archivo de estilos personalizados */
@import '@/assets/styles/custom-styles.css';

.wj-flexgrid {
  /* Agrega estilos específicos si es necesario */
  max-height: 100%;
  margin: 6px 0;
  /*margin-bottom: 12px;*/
}

.edit-msg {
  font-style: italic;
  text-align: center;
  /*margin-bottom: 10px;*/
  opacity: .5;
}

body {
  margin-bottom: 24px;
}

/* Estilos para el tooltip */
.cell-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox label input[type=checkbox] {
        margin-right: 5px;
    }

    .checkbox label {
        margin-right: 10px;
    }

    .btn {
        margin-right: 5px;
    }
</style>